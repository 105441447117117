import * as React from "react";
// import { Modal } from "react-bootstrap";

class Cookie extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
    }
    componentDidMount(){
        let cokie=localStorage.getItem('cokie');
        //console.log(cokie)
        if(!cokie)
        this.setState({ show: true });
    }
    handleClose = () => {
        localStorage.setItem("cokie", true);
        this.setState({ show: false });
    };
    render() {
        return (
            <>
           {/* <Modal show={this.state.show} className="paymentSuccess">
                                <Modal.Body>
                                    <div class="">
                             hi
                                    </div>
                                </Modal.Body>
                            </Modal> */}
                            <div class={`${this.state.show?'cookie-consent':'cookie-consent-display'} fixed-bottom `}>

<span>This site uses cookies to enhance user experience.</span>
<div class=" d-flex align-items-center justify-content-center g-2">
  <button class="allow-button ml-2 mr-2" onClick={this.handleClose}>Allow cookies</button>
  <button class="allow-button" onClick={this.handleClose}>Cancel</button>
</div>


</div>
                
            </>
        );
    }
}

// Cookie.propTypes = {
//     homePage: PropTypes.string.isRequired,
// };

export default Cookie;
