import { TOKEN, CURRENT_USER, TOAST_DURATION } from './constants';
import { notify } from 'react-notify-toast';


class Helper {
    static IsValidated() {
        // let validUser = localStorage.getItem("code");

        // if (validUser) {
            return true;
        // } else {
        //     return false;
        // }
    }

    static IsAuthenticated() {
        let loggedInuser = this.getObject(CURRENT_USER);

        if (loggedInuser) {
            return true;
        } else {
            return false;
        }
    }
    static toast(message = "Success", type = "success") {
        let options = {}
        if (type === 'info') {
            type = 'custom'
            options = { background: '#0071BB', text: "#FFFFFF" };
        }
        notify.show(message, type, TOAST_DURATION, options)
    }
    static GetCurrentUser() {
        let token = this.getObject(TOKEN);

        //console.log(token);
        if (token) {
            return this.getObject(CURRENT_USER);
        } else {
            return null;
        }
    }

    static GetCurrentBasket() {
        let token = this.getObject(TOKEN);

        if (token) {
            return this.getObject("basket");
        } else {
            return null;
        }
    }

    static saveObject(name, obj) {
        const serializedObj = JSON.stringify(obj);
        localStorage.setItem(name, serializedObj);
    }

    static getObject(key) {
        const value = localStorage.getItem(key);
        return value && JSON.parse(value);
    }

    static authHeader() {
        // return authorization header with access token
        let token = this.getObject(TOKEN);

        if (token) {
            return {
                'Content-Type': 'application/json',
                'AccessToken': token
            };
        } else {
            return {
                'Content-Type': 'application/json'
            };
        }
    }

    static authHeaderForFile() {
        // return authorization header with access token
        let token = this.getObject(TOKEN);

        if (token) {
            return {
                'AccessToken': token
            };
        } else {
            return {};
        }
    }

    static arrayToString(arr) {
        let str = '';
        arr.forEach(function (i, index) {
            str += i;
            if (index !== (arr.length - 1)) {
                str += '';
            };
        });
        return str;
    }

    static getDayFromDate(dt) {
        var days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
        const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' });

        const date = new Date(dt);

        return days[date.getDay()];
    }

    static formatDate(dt) {
        const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' });

        const date = new Date(dt);
        const [{ value: month }, , { value: day }] = dateTimeFormat.formatToParts(date);
        var formattedDate = day + " " + month;

        return formattedDate;
    }

    static getYearFromDate(dt) {
        const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' });

        const date = new Date(dt);
        const [, , , , { value: year }] = dateTimeFormat.formatToParts(date);

        return year;
    }

    static formatTime(dt) {
        const date = new Date(dt);

        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ampm;

        return strTime;
    }

    static Logout() {
        let cokie = localStorage.getItem('cokie');
        localStorage.clear();
        localStorage.setItem("cokie", cokie);
    }


}

export default Helper;