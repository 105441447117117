import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import appStore from "../../images/app-store.svg";
import playStore from "../../images/google-play-store.svg";
import closeIcon from "../../images/close.svg";
import login from "../../images/login.jpg";
import loading from "../../images/loading.gif";
import deleteac from "../../images/deleteac.svg";
import Helper from '../../services/helper';
import { getUserProfile, getUserTickets, updatePwdRequest, deleteAccountRequest } from "../../redux/actions/actions";

class User extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: (Helper.IsAuthenticated()) ? Helper.GetCurrentUser().UserId : null,
            activeTab: (this.props.match.params["t"] === undefined) ? 'profile' : 'tickets',
            showChangePwd: false,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            errorMessage: '',
            appStoreURL: "https://apps.apple.com/gb/app/scank/id1574492173",
            playStoreURL: "https://play.google.com/store/apps/details?id=com.scank.smartapps"
        }
    }

    componentDidMount() {
        if (!Helper.IsValidated()) {
            this.props.history.push("/");
        }

        this.loadConfig();
        this.getUserDetails();
    }

    componentDidUpdate(prevProps, prevState) {
        const { isUpdatePwdError: prevIsUpdatePwdError, isDeleteAccountSuccess: prevIsDeleteAccountSuccess, isDeleteAccountError: prevIsDeleteAccountError } = prevProps;
        const { isUpdatePwdError, updatePwdMessage, isDeleteAccountSuccess, isDeleteAccountError } = this.props;
        let _this = this;

        if (prevIsUpdatePwdError === false && isUpdatePwdError) {
            this.setState({ errorMessage: updatePwdMessage }, function () {
                setTimeout(function () {
                    _this.setState({ errorMessage: "" });
                }, 5000);
            });
        }

        if (prevIsDeleteAccountSuccess === false && isDeleteAccountSuccess) {
            alert("Your account deleted successfully");
            Helper.Logout();
            this.props.history.push("/");
        }

        if (prevIsDeleteAccountError === false && isDeleteAccountError) {
            alert("Something went wrong. Please try again later.");
        }
    }

    loadConfig = () => {
        fetch(window.location.origin + `/config.json`)
            .then((r) => r.json())
            .then((data) => {
                this.setState({
                    appStoreURL: data.appStoreURL,
                    playStoreURL: data.playStoreURL
                });
            });
    }

    getUserDetails = async () => {
        const { userId } = this.state;
        const { getUserProfile } = this.props;

        await getUserProfile(userId);
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        });
    }

    handleChangeTab = (tab) => {
        this.setState({ activeTab: tab });
    }

    handleChangePwd = () => {
        this.setState({ showChangePwd: true });
    }

    handleClose = () => {
        this.setState({ showChangePwd: false });
    }

    handleUpdatePwd = async (e) => {
        e.preventDefault();
        const { userId, oldPassword, newPassword, confirmPassword, errorMessage } = this.state;
        const { updatePwdRequest } = this.props;

        let isValid = true;

        const messages = [];

        if (oldPassword === "") {
            isValid = false;
            messages.push("- Please enter current password\n");
        }

        if (newPassword === "") {
            isValid = false;
            messages.push("- Please enter new password\n");
        }

        if (newPassword !== confirmPassword) {
            isValid = false;
            messages.push("- New password & confirm password must match\n");
        }

        if (!isValid) {
            alert(Helper.arrayToString(messages));
            return;
        }

        var objUser = new Object();

        objUser.UserId = userId;
        objUser.OldPassword = oldPassword;
        objUser.NewPassword = newPassword;

        await updatePwdRequest(objUser);
    }

    handleDeleteAccount = async () => {
        const { userId } = this.state;
        const { deleteAccountRequest } = this.props;

        var objUser = new Object();

        objUser.UserId = userId;

        await deleteAccountRequest(objUser);
    }

    render() {
        const { activeTab, showChangePwd, oldPassword, newPassword, confirmPassword, errorMessage, appStoreURL, playStoreURL } = this.state;
        const { showProgress, userProfile, isUpdatePwdSuccess, isUpdatePwdError } = this.props;

        return (
            <>
                <div class="tickets">
                    <div class="container-fluid">
                        <div class="tickets-container">
                            <div class="tickets__wrapper">
                                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <a className={activeTab === "profile" ? "nav-link active" : "nav-link"} id="pills-profile-tab" data-toggle="pill" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={() => this.handleChangeTab('profile')}>
                                            <i class="fas fa-edit"></i>
                                            Profile
                                        </a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a className={activeTab === "tickets" ? "nav-link active" : "nav-link"} id="pills-tickets-tab" data-toggle="pill" role="tab" aria-controls="pills-tickets" aria-selected="true" onClick={() => this.handleChangeTab('tickets')}>
                                            <i class="fas fa-ticket-alt icon-rotate"></i>
                                            My Tickets
                                        </a>
                                    </li>
                                </ul>
                                <div class="tab-content" id="pills-tabContent">
                                    <div className={activeTab === "profile" ? "tab-pane fade show active" : "tab-pane fade"} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <div class="tickets__table-box">
                                            <div class="tickets__table-wrapper table-responsive">
                                                <table class="tickets__table">
                                                    <tr class="tickets__table-row">
                                                        <td class="tickets__table-profile" colspan="2">Profile</td>
                                                    </tr>
                                                    <tr class="tickets__table-row">
                                                        <td class="tickets__table-name">Name</td>
                                                        <td class="tickets__table-user">{userProfile.Name} {userProfile.Surname}</td>
                                                    </tr>
                                                    <tr class="tickets__table-row">
                                                        <td class="tickets__table-email">Email</td>
                                                        <td class="tickets__table-user">{userProfile.Email}</td>
                                                    </tr>
                                                    <tr class="tickets__table-row">
                                                        <td class="tickets__table-password tickets__table-border_none">Password</td>
                                                        <td class="tickets__table-user tickets__table-border_none">
                                                            <div class="tickets__tabl_flex">
                                                                <span class="tickets__table-space">Change password</span>
                                                                <a href="#" onClick={this.handleChangePwd} class="tikets__table-icon">
                                                                    <i class="fas fa-edit"></i>
                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={activeTab === "tickets" ? "tab-pane fade show active" : "tab-pane fade"} id="pills-tickets" role="tabpanel" aria-labelledby="pills-tickets-tab">
                                        <div class="tickets__content">
                                            <h3 class="tickets__content-title"> Your Tickets? </h3>
                                            <p class="tickets__content-instruction">
                                                1. Check your email account for confirmation and a copy of your eTickets
                                            </p>
                                            <p class="tickets__content-instruction">
                                                2. You can also log into the Scank App for a copy of your eTickets
                                            </p>
                                            <p class="tickets__content-app">
                                                Download the Scank App today for The Ultimate Experience!
                                            </p>
                                            <div class="tickets__social-wrapper">
                                                <a href={appStoreURL} class="tickets__social-link">
                                                    <img src={appStore} width="120" height="31" alt="App Store" class="tickets__social-icon" />
                                                </a>
                                                <a href={playStoreURL} class="tickets__social-link">
                                                    <img src={playStore} width="110" height="31" alt="Google Play Store" class="tickets__social-icon" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "30px" }}>
                                        <a onClick={this.handleDeleteAccount} class="login__list-link" style={{ textAlign: "center", display: "inherit" }}>
                                            <img src={deleteac} width="14" height="15" alt="icon" class="login__list-icon" style={{ marginRight: "5px", marginBottom: "3px" }} />Delete My Account
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={showChangePwd} className="login" dialogClassName="modal-dialog-centered">
                    <Modal.Body>
                        <div class="modal-content-body">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <img src={closeIcon} width="22" height="22" alt="Close icon" onClick={this.handleClose} />
                            </button>

                            <div class="login__wrapper">
                                <img src={login} class="login__img" alt="img" />
                                <div class="login__form modal-form">
                                    <div class="modal-form__box">
                                        <>
                                            <h2 class="modal-form__title">Change Password</h2>
                                            <p class="modal-form__text">&nbsp;</p>
                                            <form onSubmit={this.handleUpdatePwd} >
                                                <div class="modal-form__wrapper">
                                                    <input type="text" class="modal-form__field" placeholder="Current Password" name="oldPassword" id="oldPassword" value={oldPassword} onChange={this.handleChange} />
                                                    <input type="text" class="modal-form__field" placeholder="New Password" name="newPassword" id="newPassword" value={newPassword} onChange={this.handleChange} />
                                                    <input type="text" class="modal-form__field" placeholder="Confirm Password" name="confirmPassword" id="confirmPassword" value={confirmPassword} onChange={this.handleChange} />
                                                </div>
                                                {showProgress ? <div style={{ textAlign: "center" }}><img src={loading} style={{ width: "32px", height: "32px" }} /></div> :
                                                    <button type="submit" class="modal-form__submit">Submit</button>}
                                            </form>
                                            <div className="errorMessage">{!isUpdatePwdSuccess ? <div>{isUpdatePwdError ? errorMessage : ""}</div> : <div>Password changed successfully</div>}</div>
                                        </>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        showProgress: state.userReducer.showProgress,
        isGetProfileSuccess: state.userReducer.isGetProfileSuccess,
        isGetProfileError: state.userReducer.isGetProfileError,
        userProfile: state.userReducer.userProfile,
        isGetTicketsSuccess: state.userReducer.isGetTicketsSuccess,
        isGetTicketsError: state.userReducer.isGetTicketsError,
        userTickets: state.userReducer.userTickets,
        isUpdatePwdSuccess: state.authReducer.isUpdatePwdSuccess,
        isUpdatePwdError: state.authReducer.isUpdatePwdError,
        updatePwdMessage: state.authReducer.updatePwdMessage,
        isDeleteAccountSuccess: state.userReducer.isDeleteAccountSuccess,
        isDeleteAccountError: state.userReducer.isDeleteAccountError,
        accountDeleted: state.userReducer.accountDeleted
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getUserProfile: (id) => dispatch(getUserProfile(id)),
        getUserTickets: (id) => dispatch(getUserTickets(id)),
        updatePwdRequest: (data) => dispatch(updatePwdRequest(data)),
        deleteAccountRequest: (data) => dispatch(deleteAccountRequest(data))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(User);