import React, { Component } from "react";
import { getSiteContent } from "../../redux/actions/actions"
import { connect } from "react-redux";
import loading from "../../images/loading.gif";
import Helper from '../../services/helper';

class Terms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showProgress: true,
            activeTab: 'terms',
        };
    }

    componentDidMount() {
        if (!Helper.IsValidated()) {
            this.props.history.push("/");
        }

        this.getTerms();

    }
    hanleClick = () => {
        this.props.history.push("/faq");
    }

    handleChangeTab = (tab) => {
        this.setState({ activeTab: tab });
    }

    getTerms = async () => {
        const { getSiteContent } = this.props;
        var terms = new Object();
        terms.title = 'Terms And Conditions'
        await getSiteContent(terms);
    }

    componentDidUpdate(prevProps) {
        const { isGetSiteContentSuccess: prevIsGetSiteContentSuccess } = prevProps;
        const { isGetSiteContentSuccess } = this.props;


        if (prevIsGetSiteContentSuccess === false && isGetSiteContentSuccess) {

            this.setState({ showProgress: false });
        }

    }

    render() {
        const { siteContent } = this.props
        const { showProgress, activeTab } = this.state
        return (
            <>
                {
                    showProgress ?
                        <div class="tickets" >
                            <div class="container-fluid">
                                <div class="tickets__wrapper faq__wrapper"
                                    style={{ background: "none", minHeight: "370px", height: "auto" }}>
                                    <div class="tickets-container">
                                        <div class="tab-content" style={{ textAlign: "center" }}>
                                            <img src={loading} style={{ width: "32px", height: "32px", alignitems: "center" }} />
                                        </div>
                                    </div></div>
                                <div>
                                </div>
                            </div>
                        </div>
                        :
                        <div class="tickets" >
                            <div class="container-fluid">
                                <div class="tickets-container">
                                    <div class="tickets__wrapper faq__wrapper">
                                        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <a className={activeTab === "FAQ" ? "nav-link active" : "nav-link"} id="pills-profile-tab" data-toggle="pill" href="/faq" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={this.hanleClick} onClick={() => this.handleChangeTab('FAQ')}>
                                                    <i class="fas fa-edit"></i>
                                                    FAQ
                                                </a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a className={activeTab === "terms" ? "nav-link active" : "nav-link"} id="pills-tickets-tab" data-toggle="pill" href="/terms" role="tab" aria-controls="pills-tickets" aria-selected="true" onClick={() => this.handleChangeTab('terms')}>
                                                    <i class="fas fa-ticket-alt icon-rotate" ></i>
                                                    TERMS
                                                </a>
                                            </li>
                                        </ul>

                                        <div class="tab-content" id="pills-tabContent">
                                            <div
                                                class="tab-pane fade show active"
                                                id="pills-terms"
                                                role="tabpanel"
                                                aria-labelledby="pills-terms-tab"
                                            >
                                                <div >
                                                    <div dangerouslySetInnerHTML={{ __html: siteContent }} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </>);
    }
}


function mapStateToProps(state) {
    return {
        isGetSiteContentSuccess: state.siteContentReducer.isGetSiteContentSuccess,
        isGetSiteContentError: state.siteContentReducer.isGetSiteContentError,
        siteContent: state.siteContentReducer.siteContent,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        getSiteContent: (data) => dispatch(getSiteContent(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Terms);