import { USER_REGISTER_REQUEST, USER_REGISTER_SUCCESS, USER_REGISTER_ERROR, GET_IS_MODAL_OPEN } from './actionTypes';
import { USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGIN_ERROR } from './actionTypes';
import { FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR } from './actionTypes';
import { UPDATE_PASSWORD_REQUEST, UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_ERROR } from './actionTypes';
import { USER_PROFILE_REQUEST, USER_PROFILE_SUCCESS, USER_PROFILE_ERROR } from './actionTypes';
import { USER_TICKET_REQUEST, USER_TICKET_SUCCESS, USER_TICKET_ERROR } from './actionTypes';
import { GET_UPCOMING_EVENTS, GET_UPCOMING_EVENTS_SUCCESS, GET_UPCOMING_EVENTS_ERROR } from "./actionTypes";
import { GET_FILTERED_EVENTS, GET_FILTERED_EVENTS_SUCCESS, GET_FILTERED_EVENTS_ERROR } from "./actionTypes";
import { GET_FAVOURITE_EVENTS, GET_FAVOURITE_EVENTS_SUCCESS, GET_FAVOURITE_EVENTS_ERROR } from "./actionTypes";
import { GET_TRENDING_EVENTS, GET_TRENDING_EVENTS_SUCCESS, GET_TRENDING_EVENTS_ERROR } from "./actionTypes";
import { GET_EVENT_DETAILS, GET_EVENT_DETAILS_SUCCESS, GET_EVENT_DETAILS_ERROR } from "./actionTypes";
import { GET_CATEGORIES, GET_CATEGORIES_SUCCESS, GET_CATEGORIES_ERROR } from "./actionTypes";
import { GET_CITIES, GET_CITIES_SUCCESS, GET_CITIES_ERROR } from "./actionTypes";
import { ADD_TO_FAVOURITE, ADD_TO_FAVOURITE_SUCCESS, ADD_TO_FAVOURITE_ERROR } from "./actionTypes";
import { REMOVE_FROM_FAVOURITE, REMOVE_FROM_FAVOURITE_SUCCESS, REMOVE_FROM_FAVOURITE_ERROR } from "./actionTypes";
import { GET_EVENT_TICKETS, GET_EVENT_TICKETS_SUCCESS, GET_EVENT_TICKETS_ERROR } from "./actionTypes";
import { ADD_ITEM_TO_BASKET, ADD_ITEM_TO_BASKET_SUCCESS, ADD_ITEM_TO_BASKET_ERROR,ITEM_TO_BASKET } from "./actionTypes";
import { GET_BASKET, GET_BASKET_SUCCESS, GET_BASKET_ERROR } from "./actionTypes";
import { CLEAR_BASKET, CLEAR_BASKET_SUCCESS, CLEAR_BASKET_ERROR } from "./actionTypes";
import { DO_PAYMENT, DO_PAYMENT_SUCCESS, DO_PAYMENT_ERROR } from "./actionTypes";
import { GET_SITE_CONTENT, GET_SITE_CONTENT_SUCCESS, GET_SITE_CONTENT_ERROR } from "./actionTypes";
import { GET_PRESS_FILE, GET_PRESS_FILE_SUCCESS, GET_PRESS_FILE_ERROR } from "./actionTypes";
import { GET_HOME_PAGE_IMG, GET_HOME_PAGE_IMG_SUCCESS, GET_HOME_PAGE_IMG_ERROR } from "./actionTypes";
import { GOOGLE_LOGIN_REQUEST, GOOGLE_LOGIN_SUCCESS, GOOGLE_LOGIN_ERROR } from './actionTypes';
import { FACEBOOK_LOGIN_REQUEST, FACEBOOK_LOGIN_SUCCESS, FACEBOOK_LOGIN_ERROR } from './actionTypes';
import { SEND_SMS, SEND_SMS_SUCCESS, SEND_SMS_ERROR } from './actionTypes';
import { RESEND_VERIFICATION_REQUEST, RESEND_VERIFICATION_SUCCESS, RESEND_VERIFICATION_ERROR } from './actionTypes';
import { DELETE_ACCOUNT_REQUEST, DELETE_ACCOUNT_SUCCESS, DELETE_ACCOUNT_ERROR } from './actionTypes';
import { GET_EVENT_DETAILS_BY_URL, GET_EVENT_DETAILS_BY_URL_SUCCESS, GET_EVENT_DETAILS_BY_URL_ERROR } from "./actionTypes";

//register
export function registerRequest(params) {
    return {
        type: USER_REGISTER_REQUEST,
        params
    }
}
export function registerSuccess(response) {
    return {
        type: USER_REGISTER_SUCCESS,
        response: response
    }
}
export function registerError(errorMessage) {
    return {
        type: USER_REGISTER_ERROR,
        response: errorMessage
    }
}

//login
export function loginRequest(params) {
    return {
        type: USER_LOGIN_REQUEST,
        params
    }
}
export function loginSuccess(response) {
    return {
        type: USER_LOGIN_SUCCESS,
        response: response
    }
}
export function loginError(errorMessage) {
    return {
        type: USER_LOGIN_ERROR,
        response: errorMessage
    }
}

//forgot password
export function forgotPwdRequest(params) {
    return {
        type: FORGOT_PASSWORD_REQUEST,
        params
    }
}
export function forgotPwdSuccess(response) {
    return {
        type: FORGOT_PASSWORD_SUCCESS,
        response: response
    }
}
export function forgotPwdError(errorMessage) {
    return {
        type: FORGOT_PASSWORD_ERROR,
        response: errorMessage
    }
}

//update password
export function updatePwdRequest(params) {
    return {
        type: UPDATE_PASSWORD_REQUEST,
        params
    }
}
export function updatePwdSuccess(response) {
    return {
        type: UPDATE_PASSWORD_SUCCESS,
        response: response
    }
}
export function updatePwdError(errorMessage) {
    return {
        type: UPDATE_PASSWORD_ERROR,
        response: errorMessage
    }
}

//user profile
export function getUserProfile(id) {
    return {
        type: USER_PROFILE_REQUEST,
        id
    }
}
export function getUserProfileSuccess(response) {
    return {
        type: USER_PROFILE_SUCCESS,
        response: response
    }
}
export function getUserProfileError(errorMessage) {
    return {
        type: USER_PROFILE_ERROR,
        response: errorMessage
    }
}

//user tickets
export function getUserTickets(id) {
    return {
        type: USER_TICKET_REQUEST,
        id
    }
}
export function getUserTicketsSuccess(response) {
    return {
        type: USER_TICKET_SUCCESS,
        response: response
    }
}
export function getUserTicketsError(errorMessage) {
    return {
        type: USER_TICKET_ERROR,
        response: errorMessage
    }
}

//upcoming events
export const getUpcomingEvents = (id) => ({
    type: GET_UPCOMING_EVENTS,
    id
});

export const getUpcomingEventsSuccess = (response) => ({
    type: GET_UPCOMING_EVENTS_SUCCESS,
    response: response,
});

export const getUpcomingEventsError = (errorMessage) => ({
    type: GET_UPCOMING_EVENTS_ERROR,
    response: errorMessage,
});

//filter events
export const getFilteredEvents = (params) => ({
    type: GET_FILTERED_EVENTS,
    params
});

export const getFilteredSuccess = (response) => ({
    type: GET_FILTERED_EVENTS_SUCCESS,
    response: response,
});

export const getFilteredError = (errorMessage) => ({
    type: GET_FILTERED_EVENTS_ERROR,
    response: errorMessage,
});

//favourite events
export const getFavouriteEvents = (params) => ({
    type: GET_FAVOURITE_EVENTS,
    params
});

export const getFavouriteSuccess = (response) => ({
    type: GET_FAVOURITE_EVENTS_SUCCESS,
    response: response,
});

export const getFavouriteError = (errorMessage) => ({
    type: GET_FAVOURITE_EVENTS_ERROR,
    response: errorMessage,
});

//trending events
export const getTrendingEvents = (params) => ({
    type: GET_TRENDING_EVENTS,
    params
});

export const getTrendingSuccess = (response) => ({
    type: GET_TRENDING_EVENTS_SUCCESS,
    response: response,
});

export const getTrendingError = (errorMessage) => ({
    type: GET_TRENDING_EVENTS_ERROR,
    response: errorMessage,
});

//event details
export const getEventDetails = (params) => ({
    type: GET_EVENT_DETAILS,
    params
});

export const getEventDetailsSuccess = (response) => ({
    type: GET_EVENT_DETAILS_SUCCESS,
    response: response,
});

export const getEventDetailsError = (errorMessage) => ({
    type: GET_EVENT_DETAILS_ERROR,
    response: errorMessage,
});

//cities
export const getCities = () => ({
    type: GET_CITIES
});

export const getCitiesSuccess = (response) => ({
    type: GET_CITIES_SUCCESS,
    response: response,
});

export const getCitiesError = (errorMessage) => ({
    type: GET_CITIES_ERROR,
    response: errorMessage,
});

//categories
export const getCategories = (params) => ({
    type: GET_CATEGORIES,
    params
});

export const getCategoriesSuccess = (response) => ({
    type: GET_CATEGORIES_SUCCESS,
    response: response,
});

export const getCategoriesError = (errorMessage) => ({
    type: GET_CATEGORIES_ERROR,
    response: errorMessage,
});

//add event as favourite
export const addToFavourite = (params) => ({
    type: ADD_TO_FAVOURITE,
    params
});

export const addToFavouriteSuccess = (response) => ({
    type: ADD_TO_FAVOURITE_SUCCESS,
    response: response,
});

export const addToFavouriteError = (errorMessage) => ({
    type: ADD_TO_FAVOURITE_ERROR,
    response: errorMessage,
});

//remove event from favourite
export const removeFromFavourite = (params) => ({
    type: REMOVE_FROM_FAVOURITE,
    params
});

export const removeFromFavouriteSuccess = (response) => ({
    type: REMOVE_FROM_FAVOURITE_SUCCESS,
    response: response,
});

export const removeFromFavouriteError = (errorMessage) => ({
    type: REMOVE_FROM_FAVOURITE_ERROR,
    response: errorMessage,
});

//event tickets
export const getEventTickets = (id) => ({
    type: GET_EVENT_TICKETS,
    id
});

export const getEventTicketsSuccess = (response) => ({
    type: GET_EVENT_TICKETS_SUCCESS,
    response: response,
});

export const getEventTicketsError = (errorMessage) => ({
    type: GET_EVENT_TICKETS_ERROR,
    response: errorMessage,
});

//add item to basket
export function addToBasket(params) {
    return {
        type: ADD_ITEM_TO_BASKET,
        params
    }
}
export function addToSelected(response) {
    return {
        type: ITEM_TO_BASKET,
        response: response
    }
}
export function addToBasketSuccess(response) {
    return {
        type: ADD_ITEM_TO_BASKET_SUCCESS,
        response: response
    }
}
export function addToBasketError(errorMessage) {
    return {
        type: ADD_ITEM_TO_BASKET_ERROR,
        response: errorMessage
    }
}

//get basket
export function getBasket(params) {
    return {
        type: GET_BASKET,
        params
    }
}
export function getBasketSuccess(response) {
    return {
        type: GET_BASKET_SUCCESS,
        response: response
    }
}
export function getBasketError(errorMessage) {
    return {
        type: GET_BASKET_ERROR,
        response: errorMessage
    }
}

//clear basket
export function clearBasket(params) {
    return {
        type: CLEAR_BASKET,
        params
    }
}
export function clearBasketSuccess(response) {
    return {
        type: CLEAR_BASKET_SUCCESS,
        response: response
    }
}
export function clearBasketError(errorMessage) {
    return {
        type: CLEAR_BASKET_ERROR,
        response: errorMessage
    }
}

//do payment
export function doPayment(params) {
    return {
        type: DO_PAYMENT,
        params
    }
}
export function doPaymentSuccess(response) {
    return {
        type: DO_PAYMENT_SUCCESS,
        response: response
    }
}
export function doPaymentError(errorMessage) {
    return {
        type: DO_PAYMENT_ERROR,
        response: errorMessage
    }
}

//site content
export function getSiteContent(params) {
    return {
        type: GET_SITE_CONTENT,
        params
    }
}
export function getSiteContentSuccess(response) {
    return {
        type: GET_SITE_CONTENT_SUCCESS,
        response: response
    }
}
export function getSiteContentError(errorMessage) {
    return {
        type: GET_SITE_CONTENT_ERROR,
        response: errorMessage
    }
}

//press link
export function getPressFile(params) {
    return {
        type: GET_PRESS_FILE,
        params
    }
}
export function getPressFileSuccess(response) {
    return {
        type: GET_PRESS_FILE_SUCCESS,
        response: response
    }
}
export function getPressFileError(errorMessage) {
    return {
        type: GET_PRESS_FILE_ERROR,
        response: errorMessage
    }
}

//home page image
export function getHomePageImage(params) {
    return {
        type: GET_HOME_PAGE_IMG,
        params
    }
}
export function getHomePageImageSuccess(response) {
    return {
        type: GET_HOME_PAGE_IMG_SUCCESS,
        response: response
    }
}
export function getHomePageImageError(errorMessage) {
    return {
        type: GET_HOME_PAGE_IMG_ERROR,
        response: errorMessage
    }
}

//Login With Google
export function googleLoginRequest(params) {
    return {
        type: GOOGLE_LOGIN_REQUEST,
        params
    }
}
export function googleLoginSuccess(response) {
    return {
        type: GOOGLE_LOGIN_SUCCESS,
        response: response
    }
}
export function googleLoginError(errorMessage) {
    return {
        type: GOOGLE_LOGIN_ERROR,
        response: errorMessage
    }
}

//Login With Facebook
export function facebookLoginRequest(params) {
    return {
        type: FACEBOOK_LOGIN_REQUEST,
        params
    }
}
export function facebookLoginSuccess(response) {
    return {
        type: FACEBOOK_LOGIN_SUCCESS,
        response: response
    }
}
export function facebookLoginError(errorMessage) {
    return {
        type: FACEBOOK_LOGIN_ERROR,
        response: errorMessage
    }
}

//Send SMS
export function sendSMSRequest(params) {
    return {
        type: SEND_SMS,
        params
    }
}
export function sendSMSSuccess(response) {
    return {
        type: SEND_SMS_SUCCESS,
        response: response
    }
}
export function sendSMSError(errorMessage) {
    return {
        type: SEND_SMS_ERROR,
        response: errorMessage
    }
}

//resend verification mail
export function resendVerificationMailRequest(params) {
    return {
        type: RESEND_VERIFICATION_REQUEST,
        params
    }
}
export function resendVerificationSuccess(response) {
    return {
        type: RESEND_VERIFICATION_SUCCESS,
        response: response
    }
}
export function resendVerificationError(errorMessage) {
    return {
        type: RESEND_VERIFICATION_ERROR,
        response: errorMessage
    }
}

//delete account
export function deleteAccountRequest(params) {
    return {
        type: DELETE_ACCOUNT_REQUEST,
        params
    }
}
export function deleteAccountSuccess(response) {
    return {
        type: DELETE_ACCOUNT_SUCCESS,
        response: response
    }
}
export function deleteAccountError(errorMessage) {
    return {
        type: DELETE_ACCOUNT_ERROR,
        response: errorMessage
    }
}

//event details by url
export const getEventDetailsByUrl = (params) => ({
    type: GET_EVENT_DETAILS_BY_URL,
    params
});

export const getEventDetailsByUrlSuccess = (response) => ({
    type: GET_EVENT_DETAILS_BY_URL_SUCCESS,
    response: response,
});

export const getEventDetailsByUrlError = (errorMessage) => ({
    type: GET_EVENT_DETAILS_BY_URL_ERROR,
    response: errorMessage,
});

export const getIsModalOpen = (isOpen) => ({
    type: GET_IS_MODAL_OPEN,
    response:isOpen
});