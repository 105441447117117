import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import calendarIcon from "../../images/calendar_ic.svg";
import locationIcon from "../../images/location-pin.svg";
import clockIcon from "../../images/clock.svg";
import loading from "../../images/loading.gif";
import Helper from '../../services/helper';
import { getCities, getCategories, getFilteredEvents, addToFavourite, removeFromFavourite } from "../../redux/actions/actions";

const customStyles = {
    option: (provided) => ({
        ...provided,
        fontWeight: 500,
        fontSize: '14px/19px',
        color: '#4c4c4c !important',
        zIndex: '999',
        textTransform: 'capitalize'
    }),
    placeholder: (defaultStyles) => ({
        ...defaultStyles,
        color: '#575757',
        fontSize: '18x'
    }),
    control: (provided) => ({
        ...provided,
        minWidth: '160px',
        font: '600 18px/25px "Open Sans", sans-serif',
        backgroundColor: '#fff !important',
        color: '#4c4c4c !important',
        borderRadius: '30px !important',
        border: '1px solid #4c4c4c'
    }),
    indicatorSeparator: (provided) => ({
        color: "white"
    }),
    menu: (base) => ({ ...base, zIndex: '999', textTransform: 'capitalize' }),
    container: (provided) => ({
        ...provided,
        width: '225px'
    })
};


const horizontalLabels = {
    0: '£0',
    20: '£0-20',
    40: '£0-40',
    60: '£0-60',
    80: '£0-80',
    100: 'All'
}

class Events extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: (Helper.IsAuthenticated()) ? Helper.GetCurrentUser().UserId : null,
            eName: this.props.location.state ? this.props.location.state.eName : "",
            minPrice: 0,
            maxPrice: 100,
            priceLimit: 100,
            eventName: '',
            categorySelect: [],
            category: [],
            citySelect: [],
            city: [],
            selectedCategory: 0,
            categoryPlaceholder: 'Category',
            cityPlaceholder: 'City',
            allCityIds: [],
            allCategoryIds: [],
            step: 20,
            onlyFree: false,
            feId: 0,
            events: []
        };
    }

    componentDidMount() {
        if (!Helper.IsValidated()) {
            this.props.history.push("/");
        }

        let _this = this;

        if (this.state.eName !== "") {
            let eName = this.state.eName;

            this.setState({
                eventName: eName
            }, function () {
                _this.filterEvents();
            });
        }

        this.loadCities();
        this.loadCategories();
        this.getEventList();
    }

    componentDidUpdate(prevProps, prevState) {
        const { city, category } = this.state;
        const { isGetCategoriesSuccess: prevIsGetCategoriesSuccess, isGetCitiesSuccess: prevIsGetCitiesSuccess, isAddToFavouriteSuccess: prevIsAddToFavouriteSuccess, isGeFilteredEventsSuccess: prevIsGeFilteredEventsSuccess } = prevProps;
        const { isRemoveFromFavouriteSuccess: prevIsRemoveFromFavouriteSuccess } = prevProps;
        const { isGetCategoriesSuccess, categories, isGetCitiesSuccess, cities, isAddToFavouriteSuccess, isRemoveFromFavouriteSuccess, isGeFilteredEventsSuccess, filteredEvents } = this.props;

        if (prevIsGetCitiesSuccess === false && isGetCitiesSuccess) {
            let data = [];
            let cityIds = [];

            data.push({ value: 0, label: 'All' });

            cities.map((itm) => {
                data.push({ value: itm.Id, label: itm.City });
                cityIds.push(itm.Id);
            });

            this.setState({ citySelect: data, allCityIds: cityIds });
        }

        if (prevIsGetCategoriesSuccess === false && isGetCategoriesSuccess) {
            let data = [];
            let catIds = [];

            data.push({ value: 0, label: 'All' });

            categories.map((itm) => {
                data.push({ value: itm.Id, label: itm.Title });
                catIds.push(itm.Id);
            });

            this.setState({ categorySelect: data, allCategoryIds: catIds });
        }

        if (prevIsGeFilteredEventsSuccess === false && isGeFilteredEventsSuccess) {
            let _this = this;

            this.setState({ events: filteredEvents }, function () {
                _this.state.events.forEach((element, index) => {
                    element["showAddOrRemoveFavProgress"] = false
                });
            });
        }

        if (prevIsAddToFavouriteSuccess === false && isAddToFavouriteSuccess) {
            let events = [...this.state.events];
            let index = events.findIndex(d => d.Id === this.state.feId);
            this.setState(prevState => {
                const events = [...prevState.events];
                events[index].IsFavourite = true;
                events[index].showAddOrRemoveFavProgress = false;
                return { events: events };
            });
            //window.location.reload();
        }

        if (prevIsRemoveFromFavouriteSuccess === false && isRemoveFromFavouriteSuccess) {
            let feId = localStorage.getItem("feId") ? parseInt(localStorage.getItem("feId")) : this.state.feId;
            let events = [...this.state.events];
            let index = events.findIndex(d => d.Id === feId);

            if (index !== -1) {
                this.setState(prevState => {
                    const events = [...prevState.events];
                    events[index].IsFavourite = false;
                    events[index].showAddOrRemoveFavProgress = false;
                    return { events: events };
                }, function () {
                    localStorage.removeItem("feId");
                });
            }
        }
        if(prevState.onlyFree!==this.state.onlyFree){
            if(this.state.onlyFree){
                this.setState({maxPrice:0})
            }
        }
    }

    loadCities = async () => {
        const { getCities } = this.props;

        await getCities();
    }

    loadCategories = async () => {
        const { userId } = this.state;
        const { getCategories } = this.props;

        await getCategories(userId);
    }

    getEventList = async () => {
        const { userId, eventName, minPrice, maxPrice } = this.state;
        const { getFilteredEvents } = this.props;

        var objEvent = new Object();

        objEvent.When = 1;
        objEvent.TagIdList = [];
        objEvent.UserId = userId;
        objEvent.CityId = [];
        objEvent.OnlyFreeEvent = false;
        objEvent.MinPrice = minPrice;
        objEvent.MaxPrice = maxPrice;
        objEvent.MapLat = null;
        objEvent.MapLng = null;
        objEvent.Distance = 0;
        objEvent.EventName = eventName;

        //console.log(JSON.stringify(objEvent));

        await getFilteredEvents(objEvent);
    }

    filterEvents = async () => {
        const { userId, eventName, city, category, minPrice, maxPrice, allCityIds, allCategoryIds, onlyFree } = this.state;
        const { getFilteredEvents } = this.props;
        let filterByCity = []; let filterByCategory = [];
//console.log(onlyFree)
        if (city.length > 0) {
            city.forEach(function (c) {
                if (c.value === 0)
                    filterByCity = allCityIds;
                else
                    filterByCity.push(c.value);
            });
        }

        if (category.length > 0) {
            category.forEach(function (c) {
                if (c.value === 0)
                    filterByCategory = allCategoryIds;
                else
                    filterByCategory.push(c.value);
            });
        }

        //localStorage.setItem("filterMinPrice", minPrice);
        //localStorage.setItem("filterMaxPrice", (onlyFree) ? 0 : maxPrice);

        var objEvent = new Object();

        objEvent.When = 1;
        objEvent.TagIdList = filterByCategory;
        objEvent.UserId = userId;
        objEvent.CityId = filterByCity;
        objEvent.OnlyFreeEvent = onlyFree;
        objEvent.MinPrice = minPrice;
        objEvent.MaxPrice = (onlyFree) ? 0 : maxPrice;
        objEvent.MapLat = null;
        objEvent.MapLng = null;
        objEvent.Distance = 0;
        objEvent.EventName = eventName;

        //console.log(JSON.stringify(objEvent));

        await getFilteredEvents(objEvent);
    }

    handlePriceChange = (value) => {
        this.setState({
            maxPrice: value
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });

        if (e.target.name === "onlyFree") {
            document.getElementById("onlyFree").checked = e.target.checked;
            this.setState({
                onlyFree: e.target.checked,
            });
        }
    }

    handleCategoryChange = (e) => {
        this.setState({
            category: e
        }, function () {
            this.setState({ categoryPlaceholder: this.state.category.length > 1 ? 'MULTIPLE' : ((this.state.category.length > 0) ? this.state.category[0].label : 'Category') });
        });
    }

    handleCityChange = (e) => {
        this.setState({
            city: e
        }, function () {
            this.setState({ cityPlaceholder: this.state.city.length > 1 ? 'MULTIPLE' : ((this.state.city.length > 0) ? this.state.city[0].label : 'City') });
        });
    }

    onPressEnter = (e) => {
        if (e.key === 'Enter') {
            this.getEventList();
        }
    }

    handleAddToFavourite = async (eventId) => {
        const { userId } = this.state;
        const { addToFavourite } = this.props;

        let events = [...this.state.events];
        let index = events.findIndex(d => d.Id === eventId);
        this.setState(prevState => {
            const events = [...prevState.events];
            events[index].showAddOrRemoveFavProgress = true;
            return { events: events };
        });

        this.setState({ feId: eventId });

        let isValid = true;

        const messages = [];

        if (!Helper.IsAuthenticated()) {
            isValid = false;
            messages.push("- Please login to add event as favourite\n");
        }

        if (!isValid) {
            alert(Helper.arrayToString(messages));

            document.getElementById("navSignIn").click();

            setTimeout(() => {
                document.getElementById("divSignIn").click();
            }, 200);

            this.setState(prevState => {
                const events = [...prevState.events];
                events[index].showAddOrRemoveFavProgress = false;
                return { events: events };
            });

            return;
        }

        var objEvent = new Object();

        objEvent.EventId = eventId;
        objEvent.UserId = userId;

        await addToFavourite(objEvent);
    }

    handleRemoveFavourite = async (eventId) => {
        const { userId } = this.state;
        const { removeFromFavourite } = this.props;

        let events = [...this.state.events];
        let index = events.findIndex(d => d.Id === eventId);
        this.setState(prevState => {
            const events = [...prevState.events];
            events[index].showAddOrRemoveFavProgress = true;
            return { events: events };
        });

        this.setState({ feId: eventId });

        var objEvent = new Object();

        objEvent.EventId = eventId;
        objEvent.UserId = userId;

        await removeFromFavourite(objEvent);
    }

    handleGrabTicket = async (ticketURL) => {
        this.props.history.push(ticketURL);
    }

    render() {
        const { minPrice, priceLimit, maxPrice, step, onlyFree, showAddOrRemoveFavProgress } = this.state;
        const { categorySelect, category, citySelect, city, categoryPlaceholder, cityPlaceholder, events } = this.state;
        const { showProgress, filteredEvents, eventName } = this.props;

        return (
            <div class="events">
                <div class="events__title-bg">
                    <div class="container-fluid">
                        <h1 class="events__title title">Events</h1>
                    </div>
                </div>

                <div class="container-fluid">
                    <div class="events__filters-wrapper">
                        <div class="events__range events-range-alignres">
                            <div class="alignrespons">
                                <div style={{ display: "flex" }}>
                                    <div style={{ width: "90%" }}>Price</div><div>&nbsp;</div>
                                </div>
                                <Slider minPrice={minPrice} priceLimit={priceLimit} value={maxPrice} className={onlyFree ? "disable_slider" : ''} id="maxPrice" name="maxPrice" labels={horizontalLabels} onChange={this.handlePriceChange} step={step} />
                                <div style={{ textAlign: "center", marginTop: "40px" }}>{maxPrice === 0 ? "Free" : ((maxPrice === 100 ? "" : <span>£{parseFloat(minPrice).toFixed(2)} - {parseFloat(maxPrice).toFixed(2)}</span>))}</div>
                            </div>
                            <div>
                                <input type="checkbox" id="onlyFree" name="onlyFree" checked={onlyFree} onChange={this.handleChange} />&nbsp;Only free events
                            </div>
                        </div>

                        <div class="events__select-wrapper">
                            <input type="text" className="featured__select-name res" placeholder="Search Event" aria-label="Event name" id="eventName" name="eventName" value={eventName} style={{ textAlign: "left", paddingLeft: "10px", }} onChange={this.handleChange} onKeyDown={(e) => { this.onPressEnter(e) }} />

                            <Select
                                id="category"
                                name="category"
                                style={{ aria_hidden: "true" }}
                                options={categorySelect}
                                styles={customStyles}
                                value={category}
                                onChange={this.handleCategoryChange}
                                // placeholder="Category"
                                className="cutsom-select-hash res"
                                isMulti
                                placeholder={categoryPlaceholder}
                                controlShouldRenderValue={false}
                                hideSelectedOptions={false}
                                isSearchable={false} />

                            <Select
                                id="city"
                                name="city"
                                style={{ aria_hidden: "true" }}
                                options={citySelect}
                                styles={customStyles}
                                value={city}
                                onChange={this.handleCityChange}
                                // placeholder="City"
                                className="cutsom-select-hash res"
                                isMulti
                                placeholder={cityPlaceholder}
                                controlShouldRenderValue={false}
                                hideSelectedOptions={false}
                                isSearchable={false} />
                            <button class="events__filters-btn res" type="button" aria-label="Click the button and Lets go" onClick={this.filterEvents}>
                                Lets go
                            </button>
                        </div>
                    </div>
                </div>

                <div class="container-fluid">
                    {showProgress ?
                        (
                            <ul class="upcoming__cart-list list events__cart-list">
                                <li>&nbsp;</li>
                                <li style={{ textAlign: "center" }}>
                                    <img src={loading} style={{ width: "32px", height: "32px" }} />{" "}
                                </li>
                                <li>&nbsp;</li>
                            </ul>
                        )
                        :
                        (events.length > 0 ? (
                            <ul class="upcoming__cart-list list events__cart-list">
                                {events.map((evt) => (
                                    <li class="upcoming__cart-item" style={{ cursor: "pointer" }} >
                                        <div class="upcoming__cart-wrap" onClick={() => this.handleGrabTicket('/tickets/' + evt.Id)}>
                                            <img src={evt.CoverPictureUrl} alt="Photo" class="upcoming__cart-image" />
                                        </div>
                                        <div class="upcoming__cart-content">
                                            <div class="upcoming__cart-box" onClick={() => this.handleGrabTicket('/tickets/' + evt.Id)}>
                                                <h3 class="upcoming__cart-title">{(evt.Title.length > 12) ? (evt.Title.substring(0, 11) + '...') : evt.Title}</h3>
                                                <div class="upcoming__cart-price">
                                                    <span class="upcoming__price-from">from</span>

                                                    <span class="upcoming__price">{evt.Currency}{parseFloat(evt.Price).toFixed(2)}</span>
                                                </div>
                                            </div>

                                            <ul class="upcoming__date list">
                                                <li class="upcoming__date-item">
                                                    <img src={calendarIcon} width="19" height="19" alt="Calendar" class="upcoming__calendar-icon" />
                                                    <p class="upcoming__date-text upcoming__date-text_big">
                                                        <span class="upcoming__date-sun">{Helper.getDayFromDate(evt.StartDate)}</span>{" "}
                                                        <span class="upcoming__calendar-number">
                                                            {Helper.formatDate(evt.StartDate)}
                                                        </span>{" "}
                                                        {Helper.getYearFromDate(evt.StartDate)}
                                                    </p>
                                                </li>

                                                <li class="upcoming__date-item">
                                                    <img src={locationIcon} width="13" height="16" alt="Location" class="upcoming__location-icon" />
                                                    <p class="upcoming__date-text">
                                                        {(evt.Venue) ? evt.Venue + "," : "-"}{" "}
                                                        <span class="upcoming__location-text">{(evt.City) ? evt.City : ""}</span>
                                                    </p>
                                                </li>

                                                <li class="upcoming__date-item upcoming__date-item_space-between">
                                                    <div class="upcoming__time-wrap">
                                                        <img src={clockIcon} width="13" height="13" alt="Clock" class="upcoming__clock-icon" />
                                                        <p class="upcoming__date-text">{Helper.formatTime(evt.StartDate)}</p>
                                                    </div>
                                                    {evt.showAddOrRemoveFavProgress ? <img src={loading} style={{ width: "32px", height: "32px" }} /> :
                                                        <button type="button" className={evt.IsFavourite ? "upcoming__date-link favouriteButt active" : "upcoming__date-link favouriteButt"} onClick={() => { evt.IsFavourite ? this.handleRemoveFavourite(evt.Id) : this.handleAddToFavourite(evt.Id) }}>
                                                            <svg width="32" height="28" viewBox="0 0 597 512" version="1.1">
                                                                <g id="#4c4c4cff">
                                                                    <path fill="#4c4c4c" opacity="1.00" d=" M 159.50 0.00 L 188.36 0.00 C 228.88 3.72 267.58 21.08 298.55 47.24 C 330.49 20.38 370.65 2.38 412.56 0.00 L 439.41 0.00 C 485.66 3.98 531.23 24.72 561.93 60.01 C 583.33 84.42 595.19 116.37 597.00 148.63 L 597.00 170.43 C 594.74 207.83 581.74 243.93 563.24 276.28 C 553.98 292.76 542.19 307.63 530.18 322.17 C 484.90 376.08 428.99 419.48 374.30 463.28 C 365.73 470.08 357.38 477.18 348.58 483.67 C 336.29 493.15 324.69 504.27 309.86 509.65 C 299.54 513.49 287.40 512.15 278.47 505.62 C 208.81 454.94 141.69 400.35 81.35 338.74 C 57.66 314.17 37.53 286.02 22.84 255.18 C 9.28 226.58 0.66 195.36 0.00 163.61 L 0.00 155.43 C 0.55 138.55 2.88 121.58 8.52 105.61 C 18.87 76.11 39.69 50.98 65.17 33.19 C 92.82 13.88 125.82 2.11 159.50 0.00 M 118.00 88.08 C 103.95 96.08 91.76 107.59 83.73 121.69 C 78.32 133.31 75.92 146.17 75.45 158.93 C 75.12 185.43 84.18 211.13 96.83 234.10 C 104.95 248.54 114.14 262.47 125.17 274.87 C 176.25 332.98 238.20 379.91 298.74 427.54 C 357.86 380.55 418.60 334.67 469.16 278.17 C 479.54 266.95 488.30 254.35 496.24 241.32 C 507.90 221.90 517.06 200.64 520.44 178.12 C 523.14 160.83 521.67 142.79 515.44 126.39 C 510.46 115.81 503.12 106.39 494.32 98.72 C 460.24 69.48 407.33 65.98 368.97 88.82 C 363.54 91.99 358.74 96.07 353.60 99.66 C 341.38 109.23 334.42 123.78 322.54 133.71 C 318.22 137.39 313.02 140.07 307.45 141.23 C 298.36 142.74 288.41 142.80 280.36 137.68 C 264.11 127.76 256.58 108.74 241.19 97.81 C 236.42 94.55 231.86 90.95 226.79 88.14 C 193.81 69.34 151.03 69.46 118.00 88.08 Z" />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    }
                                                </li>
                                            </ul>
                                            {evt.SoldOut ? <button class="upcoming__card-btn" style={{ cursor: "default"}} disabled>Sold Out</button> :
                                                <button class="upcoming__card-btn" onClick={() => this.handleGrabTicket('/tickets/' + evt.Id)}>Get Tickets</button>
                                            }
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <ul class="upcoming__cart-list list events__cart-list">
                                <li>&nbsp;</li>
                                <li style={{ textAlign: "center" }}>
                                    <span style={{ color: "red" }}>No records found</span>
                                </li>
                                <li>&nbsp;</li>
                            </ul>
                        ))
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        showProgress: state.eventReducer.showProgress,
        isGetCitiesSuccess: state.cityReducer.isGetCitiesSuccess,
        isGetCitiesError: state.cityReducer.isGetCitiesError,
        cities: state.cityReducer.cities,
        isGetCategoriesSuccess: state.categoryReducer.isGetCategoriesSuccess,
        isGetCategoriesError: state.categoryReducer.isGetCategoriesError,
        categories: state.categoryReducer.categories,
        isGeFilteredEventsSuccess: state.eventReducer.isGeFilteredEventsSuccess,
        isGeFilteredEventsError: state.eventReducer.isGeFilteredEventsError,
        filteredEvents: state.eventReducer.filteredEvents,
        isAddToFavouriteSuccess: state.eventReducer.isAddToFavouriteSuccess,
        isAddToFavouriteError: state.eventReducer.isAddToFavouriteError,
        addedToFavourite: state.eventReducer.addedToFavourite,
        isRemoveFromFavouriteSuccess: state.eventReducer.isRemoveFromFavouriteSuccess,
        isRemoveFromFavouriteError: state.eventReducer.isRemoveFromFavouriteError,
        removedFromFavourite: state.eventReducer.removedFromFavourite
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCities: () => dispatch(getCities()),
        getCategories: (data) => dispatch(getCategories(data)),
        getFilteredEvents: (data) => dispatch(getFilteredEvents(data)),
        addToFavourite: (data) => dispatch(addToFavourite(data)),
        removeFromFavourite: (data) => dispatch(removeFromFavourite(data))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Events);
