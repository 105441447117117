import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import Helper from '../services/helper';
import Tickets from '../views/tickets';
import CardSection from "./cardSection";
import loading from "../images/loading.gif";

const axios = require("axios");

class CheckoutForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: (Helper.IsAuthenticated()) ? Helper.GetCurrentUser().UserId : null,
            currentBasket: (Helper.IsAuthenticated()) ? Helper.GetCurrentBasket() : null,
            loading: false,
            isCardComplete:false
        };
    }

    componentDidMount = () => {
        const script = document.createElement("script");

        script.src = "https://seal.starfieldtech.com/getSeal?sealID=1T8NHRnzZYG4y7B7eGpyIk3SGaRcU4VVe1kCXWHBoKnijr26ZPAXx5duy37P";
        script.async = true;

        document.body.appendChild(script);
    }

    handleSubmit = async event => {
        event.preventDefault();
        this.setState({ loading: true });

        const { stripe, elements } = this.props;

        if (!stripe || !elements) {
            this.setState({ loading: false });
            return;
        }

        //const card = elements.getElement(CardElement);
        //const result = await stripe.createToken(card);

        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                // Include any additional collected billing details.
                name: 'Test User',
            },
        });

        if (result.error) {
            this.setState({ loading: false });
            alert(result.error.message);
        } else {
            //console.log(JSON.stringify(result));
            stripePaymentMethodHandler(stripe, result, this.state.userId, JSON.parse(Helper.GetCurrentBasket()));
        }
    };
    handleCardElementChange = (isComplete) => {
        this.setState({ isCardComplete: isComplete });
    };
    render() {
        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    <CardSection  onChange={this.handleCardElementChange} />
                    {this.state.loading ? <div><div style={{ background: "none", height: "auto", textAlign: "center", marginBottom: "15px" }}><div className="container-fluid"><img src={loading} style={{ width: "32px", height: "32px" }} /></div></div><div style={{ textAlign: "center", fontSize: "12px", marginTop: "-10px", marginBottom: "10px" }}>Payment processing. Please wait!</div></div> : <div class="payments__form-wrap">
                        <button disabled={!this.props.stripe ||!this.state.isCardComplete} class={`payments__form-submit ${!this.state.isCardComplete?"disabled":""}`}>Complete Order</button>
                    </div>
                    }
                </form>
            </div>
        );
    }
}

async function stripePaymentMethodHandler(stripe, result, userId, currentBasket) {
    if (result.error) {
        // Show error in payment form
        this.setState({ loading: false });
    } else {
        var objPayment = new Object();

        objPayment.UserId = userId;
        objPayment.Amount = currentBasket.totalAmount;
        objPayment.PaymentMethodNonce = "card";
        objPayment.BasketId = currentBasket.basketId;
        objPayment.Token = result.paymentMethod.id;
        objPayment.IntentId = null;
        objPayment.TransactionId = "";
        objPayment.Region = (result.paymentMethod.card.country) ? (result.paymentMethod.card.country === "GB" ? "domestic" : "international") : "domestic";

        await axios.post(
            `https://scankwebapi.azurewebsites.net/api/Payment/SetPaidBasket`,
            objPayment,
            {
                headers: Helper.authHeader(),
            }
        ).then(async function (response) {
            //console.log("done: " + JSON.stringify(response));
            //localStorage.removeItem("basket");
            //if (response.data.State) {
            //    window.location.href = window.location.origin + "/tickets/" + currentBasket.eventId + "/s";
            //}
            
            const paymentResponse = await response;

            // Handle server response (see Step 4)
            handleServerResponse(stripe, paymentResponse, userId, currentBasket);
        });
    }
}

async function handleServerResponse(stripe, response, userId, currentBasket) {
    if (response.error) {
        // Show error from server on payment form
        this.setState({ loading: false });
    } else if (response.data.State) {


        if (response.data.Message[0] !== "success\n") {
            // Use Stripe.js to handle the required card action
            const { error: errorAction, paymentIntent } = await stripe.handleCardAction(response.data.Message[0]);

            if (errorAction) {
                // Show error from Stripe.js in payment form
                this.setState({ loading: false });
            } else {
                // The card action has been handled
                // The PaymentIntent can be confirmed again on the server

                var objPayment = new Object();

                objPayment.UserId = userId;
                objPayment.Amount = currentBasket.totalAmount;
                objPayment.PaymentMethodNonce = "card";
                objPayment.BasketId = currentBasket.basketId;
                objPayment.Token = null;
                objPayment.IntentId = paymentIntent.id;
                objPayment.TransactionId = "";
                objPayment.Region = "";

                await axios.post(
                    `https://scankwebapi.azurewebsites.net/api/Payment/SetPaidBasket`,
                    objPayment,
                    {
                        headers: Helper.authHeader(),
                    }
                ).then(async function (response) {
                    handleServerResponse(stripe, await response, userId, currentBasket);
                });
            }
        }
        else {
            localStorage.removeItem("basket");
            if (response.data.State) {
                window.location.href = window.location.origin + "/tickets/" + currentBasket.eventId + "/s";
            }
        }
    } else {
        // Show success message
    }
}

export default function InjectedCheckoutForm() {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <CheckoutForm stripe={stripe} elements={elements} />
            )}
        </ElementsConsumer>
    );
}