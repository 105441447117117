import Landing from "../views/landing";
import Home from "../views/home";
import FAQ from "../views/faq";
import Events from "../views/events";
import User from "../views/user";
import Tickets from "../views/tickets";
import Terms from "../views/terms"
import Privacy from "../views/privacyPolicy"
import About from "../views/about"
import AboutUs from "../views/about-us";
import EventDetails from "../views/eventDetails";
import PaymentReponse from "../views/tickets/paymentResponse";
import RedirectToApp from "../views/redirecting";

const routes = [
    // {
    //     id: 11,
    //     path: "/",
    //     component: Landing,
    //     isPublic: true,
    // },
    // {
    //     id: 12,
    //     path: "/getIn/890185",
    //     component: Landing,
    //     isPublic: true,
    // },
    {
        id: 1,
        path: "/",
        component: Home,
        isPublic: true,
    },
    {
        id: 111,
        path: "/faq",
        component: FAQ,
        isPublic: true,
    },
    {
        id: 2,
        path: "/events",
        component: Events,
        isPublic: true,
    },
    {
        id: 3,
        path: "/profile/:t?",
        component: User,
        isPublic: true,
    },
    {
        id: 4,
        path: "/tickets/:eid/:p?",
        component: Tickets,
        isPublic: true,
    },
    {
        id: 6,
        path: "/terms",
        component: Terms,
        isPublic: true,
    },
    {
        id: 7,
        path: "/privacy",
        component: Privacy,
        isPublic: true,
    },
    {
        id: 8,
        path: "/about",
        component: AboutUs,
        isPublic: true,
    },
    {
        id: 9,
        path: "/e/:w",
        component: EventDetails,
        isPublic: true,
    },
    {
        id: 4,
        path: "/payment-response/:eid/:tAmt/:bid",
        component: PaymentReponse,
        isPublic: true,
    },
    {
        id: 10,
        path: "/redirect/main", // Main app redirect
        component: () => <RedirectToApp appType="main" />,
        isPublic: true,
    },
    {
        id: 11,
        path: "/redirect/organizer", // Organizer app redirect
        component: () => <RedirectToApp appType="organizer" />,
        isPublic: true,
    },
];

export default routes;
