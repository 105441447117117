import Helper from "../services/helper";
const axios = require("axios");

class AuthAPI {
    static Register = async (request) => {
        return await axios.post(
            `https://scankwebapi.azurewebsites.net/api/user/register`,
            request.params,
            {
                headers: Helper.authHeader(),
            }
        );
    }

    static Login = async (request) => {
        return await axios.post(
            `https://scankwebapi.azurewebsites.net/api/user/login`,
            request.params,
            {
                headers: Helper.authHeader(),
            }
        );
    }

    static ForgotPassword = async (request) => {
        return await axios.post(
            `https://scankwebapi.azurewebsites.net/api/user/forgetpassword?email=${request.params}`,
            {
                headers: Helper.authHeader(),
            }
        );
    }

    static UpdatePassword = async (request) => {
        return await axios.post(
            `https://scankwebapi.azurewebsites.net/api/User/UpdatePassword`,
            request.params,
            {
                headers: Helper.authHeader(),
            }
        );
    }

    static LoginWithGoogle = async (request) => {
        return await axios.post(
            `https://scankwebapi.azurewebsites.net/api/User/SocailMediaLogin`,
            request.params,
            {
                headers: Helper.authHeader(),
            }
        );
    }

    static LoginWithFacebook = async (request) => {
        return await axios.post(
            `https://scankwebapi.azurewebsites.net/api/User/SocailMediaLogin`,
            request.params,
            {
                headers: Helper.authHeader(),
            }
        );
    }

    static ResendVerificationMail = async (request) => {
        return await axios.post(
            `https://scankwebapi.azurewebsites.net/api/user/ResendVerificationMail`,
            request.params,
            {
                headers: Helper.authHeader(),
            }
        );
    }
}

export default AuthAPI;