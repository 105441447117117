import { takeLatest, put, call } from "redux-saga/effects";
import { DELETE_ACCOUNT_REQUEST, USER_PROFILE_REQUEST, USER_TICKET_REQUEST  } from "../actions/actionTypes";
import { getUserProfileSuccess, getUserProfileError, getUserTicketsSuccess, getUserTicketsError, deleteAccountSuccess, deleteAccountError } from "../actions/actions";
import UserAPI from '../../apis/userApi';

function* getProfile(params) {
    try {
        const response = yield call(UserAPI.GetUserProfile, params);

        const data = yield response.data;

        //console.log("data: " + JSON.stringify(data));

        if (data.State)
            yield put(getUserProfileSuccess(data));
        else
            yield put(getUserProfileError(data));

    } catch (error) {
        yield put(getUserProfileError(error.message));
    }
}

function* getUserTickets(params) {
    try {
        const response = yield call(UserAPI.GetUserTickets, params);

        const data = yield response.data;

        //console.log("data: " + JSON.stringify(data));

        if (data.State)
            yield put(getUserTicketsSuccess(data));
        else
            yield put(getUserTicketsError(data));

    } catch (error) {
        yield put(getUserTicketsError(error.message));
    }
}

function* deleteAccount(params) {
    try {
        const response = yield call(UserAPI.DeleteAccount, params);

        const data = yield response.data;

        //console.log("data: " + JSON.stringify(data));

        if (data.State)
            yield put(deleteAccountSuccess(data));
        else
            yield put(deleteAccountError(data));

    } catch (error) {
        yield put(deleteAccountError(error.message));
    }
}

export default function* () {
    yield takeLatest(USER_PROFILE_REQUEST, getProfile);
    yield takeLatest(USER_TICKET_REQUEST, getUserTickets);
    yield takeLatest(DELETE_ACCOUNT_REQUEST, deleteAccount);
}

