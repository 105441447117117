import { takeLatest, put, call } from "redux-saga/effects";
import { CURRENT_USER, TOKEN } from "../../services/constants";
import { USER_REGISTER_REQUEST, USER_LOGIN_REQUEST, FORGOT_PASSWORD_REQUEST, UPDATE_PASSWORD_REQUEST, GOOGLE_LOGIN_REQUEST, FACEBOOK_LOGIN_REQUEST } from "../actions/actionTypes";
import { RESEND_VERIFICATION_REQUEST } from "../actions/actionTypes";
import { registerSuccess, registerError, loginSuccess, loginError, forgotPwdSuccess, forgotPwdError, updatePwdSuccess, updatePwdError, googleLoginSuccess, googleLoginError, facebookLoginSuccess, facebookLoginError } from "../actions/actions";
import { resendVerificationSuccess, resendVerificationError } from "../actions/actions";
import Helper from "../../services/helper";
import AuthAPI from '../../apis/authApi';

function* register(params) {
    try {
        const response = yield call(AuthAPI.Register, params);

        const data = yield response.data;

        //console.log("data: " + JSON.stringify(data));

        if (data.State) 
            yield put(registerSuccess(data));
        else
            yield put(registerError(data));

    } catch (error) {
        yield put(registerError(error.message));
    }
}

function* login(params) {
    try {
        const response = yield call(AuthAPI.Login, params);

        const data = yield response.data;

        //console.log("data: ", data);

        if (data.State) {
            Helper.saveObject(CURRENT_USER, data);
            Helper.saveObject(TOKEN, data.Token);
            yield put(loginSuccess(data));
        }
        else
            yield put(loginError(data));
        
    } catch (error) {
        yield put(loginError(error.message));
    }
}

function* forgotPassword(params) {
    try {
        const response = yield call(AuthAPI.ForgotPassword, params);

        const data = yield response.data;

        //console.log("data: " + JSON.stringify(data));

        if (data.State) {
            yield put(forgotPwdSuccess(data));
        }
        else
            yield put(forgotPwdError(data));

    } catch (error) {
        yield put(forgotPwdError(error.message));
    }
}

function* changePassword(params) {
    try {
        const response = yield call(AuthAPI.UpdatePassword, params);

        const data = yield response.data;

        //console.log("data: " + JSON.stringify(data));

        if (data.State) {
            yield put(updatePwdSuccess(data));
        }
        else
            yield put(updatePwdError(data));

    } catch (error) {
        yield put(updatePwdError(error.message));
    }
}

function* googleLogin(params) {
    try {
        const response = yield call(AuthAPI.LoginWithGoogle, params);

        const data = yield response.data;

        //console.log("data: " + JSON.stringify(data));


        if (data.Token) {
            Helper.saveObject(CURRENT_USER, data);
            Helper.saveObject(TOKEN, data.Token);
            yield put(googleLoginSuccess(data));
        }
        else
            yield put(googleLoginError(data));

    } catch (error) {
        yield put(googleLoginError(error.message));
    }
}

function* facebookLogin(params) {
    try {
        const response = yield call(AuthAPI.LoginWithFacebook, params);

        const data = yield response.data;

        //console.log("data: " + JSON.stringify(data));


        if (data.Token) {
            Helper.saveObject(CURRENT_USER, data);
            Helper.saveObject(TOKEN, data.Token);
            yield put(facebookLoginSuccess(data));
        }
        else
            yield put(facebookLoginError(data));

    } catch (error) {
        yield put(facebookLoginError(error.message));
    }
}

function* resendVerification(params) {
    try {
        const response = yield call(AuthAPI.ResendVerificationMail, params);

        const data = yield response.data;

        //console.log("data: " + JSON.stringify(data));

        if (data.State) {
            yield put(resendVerificationSuccess(data));
        }
        else
            yield put(resendVerificationError(data));

    } catch (error) {
        yield put(resendVerificationError(error.message));
    }
}

export default function* () {
    yield takeLatest(USER_REGISTER_REQUEST, register);
    yield takeLatest(USER_LOGIN_REQUEST, login);
    yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPassword);
    yield takeLatest(UPDATE_PASSWORD_REQUEST, changePassword);
    yield takeLatest(GOOGLE_LOGIN_REQUEST, googleLogin);
    yield takeLatest(FACEBOOK_LOGIN_REQUEST, facebookLogin);
    yield takeLatest(RESEND_VERIFICATION_REQUEST, resendVerification);
}