import { Component } from "react";
import { connect } from "react-redux";
import facebook from "../../images/facebook.svg";
import twitter from "../../images/twitter.svg";
import instagram from "../../images/instagram.svg";
import tiktok from "../../images/TICKTOK.png";
import "bootstrap/dist/js/bootstrap.bundle";
import { getPressFile } from "../../redux/actions/actions";

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        setTimeout(this.getPressLink(), 5000);
    }

    componentDidUpdate(prevProps) {
        const { isGetPressFileSuccess: prevIsGetSiteContentSuccess } = prevProps;
        const { isGetPressFileSuccess, pressFile } = this.props;

        if (prevIsGetSiteContentSuccess === false && isGetPressFileSuccess) {
            //console.log("footer: ", pressFile);
        }
    }

    getPressLink = async () => {
        const { getPressFile } = this.props;

        var pFile = new Object();
        pFile.title = 'PressFile';

        await getPressFile(pFile);
    }

    getCurrentYear = () => {
        return new Date().getFullYear();
    };

    render() {
        const { pressFile } = this.props;

        return (
            <div class="footer">
                <div class="container-fluid">
                    <div class="footer__wrapper">
                        <ul class="footer__nav-list list">
                            <li class="footer__nav-item">
                                <a href="/terms" class="footer__nav-link">
                                    Terms & Conditions
                                </a>
                            </li>

                            <li class="footer__nav-item">
                                <a href="/privacy" class="footer__nav-link">
                                    Privacy policy
                                </a>
                            </li>

                            <li class="footer__nav-item">
                                <a href={pressFile} class="footer__nav-link">
                                    Press
                                </a>
                            </li>

                            <li class="footer__nav-item">
                                <a href="/faq" class="footer__nav-link">
                                    FAQs
                                </a>
                            </li>
                        </ul>

                        <ul class="footer__social list">
                            <li class="footer__social-item_title">Follows Us</li>

                            <li class="footer__social-item">
                                <a
                                    href="https://www.facebook.com/Scank-102863231703024"
                                    class="footer__social-link"
                                    aria-label="Go to Facebook" target="_blank"
                                >
                                    <img
                                        src={facebook}
                                        width="14"
                                        height="14"
                                        alt="Facebook"
                                        class="footer__social-icon"
                                    />
                                </a>
                            </li>

                            <li class="footer__social-item">
                                <a
                                    href="https://twitter.com/scankworld"
                                    class="footer__social-link"
                                    aria-label="Go to Twitter" target="_blank"
                                >
                                    <img
                                        src={twitter}
                                        width="15"
                                        height="13"
                                        alt="Twitter"
                                        class="footer__social-icon"
                                    />
                                </a>
                            </li>

                            <li class="footer__social-item">
                                <a
                                    href="https://www.instagram.com/scankworld/"
                                    class="footer__social-link"
                                    aria-label="Go to Instagram" target="_blank"
                                >
                                    <img
                                        src={instagram}
                                        width="13"
                                        height="13"
                                        alt="instagram"
                                        class="footer__social-icon"
                                    />
                                </a>
                            </li>

                            <li class="footer__social-item">
                                <a
                                    href="https://www.tiktok.com/@scankworld"
                                    class="footer__social-link"
                                    aria-label="Go to twitter" target="_blank"
                                >
                                    <img
                                        src={tiktok}
                                        width="13"
                                        height="13"
                                        alt="tiktok"
                                        class="footer__social-icon"
                                    />
                                </a>
                            </li>
                        </ul>

                        <p class="footer__copyright">
                            &copy; {this.getCurrentYear()} Scank. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isGetPressFileSuccess: state.siteContentReducer.isGetPressFileSuccess,
        isGetPressFileError: state.siteContentReducer.isGetPressFileError,
        pressFile: state.siteContentReducer.pressFile,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        getPressFile: (data) => dispatch(getPressFile(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
