import * as React from "react";
import PropTypes from "prop-types";
import HomeHeader from '../homeHeader';
import LandingHeader from '../landingHeader';
import Header from "../header";
import Footer from "../footer";
import ScrollUpButton from "react-scroll-up-button";
import "../../scripts/bootstrap/css/bootstrap.min.css";
import "../../scripts/fontawesome/css/all.min.css";
import "../../scripts/jrange/jquery.range.css";
import "../../scripts/select2/css/select2.min.css";
import "../../styles/style.css";
import "../../scripts/slick/slick.css";
import "../../scripts/slick/slick-theme.css";
import "../../scripts/js";
import Cookie from "../cookie-modal";
import { connect } from "react-redux";
import Helper from "../../services/helper";
import { getBasket, getEventDetails, getEventTickets } from "../../redux/actions/actions";

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuState: false,
            showScrollButton: false,
        };

        // if(this.props.homePage === "/")
        // alert(this.props.homePage + "~~~true");
        // else
        // alert(this.props.homePage + "~~~false");
    }

    componentDidMount() {
        // Add a scroll event listener to track when the user scrolls
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        // Clean up the scroll event listener when the component unmounts
        window.removeEventListener('scroll', this.handleScroll);
    }
    logout = () => {
        //Helper.Logout();
        window.location = "/";
    };

   /*  handleScroll = () => {
        const scrollTop = window.pageYOffset;
        if (scrollTop > 300) { setIsVisible(true); }
        else { setIsVisible(false); }
    };*/
    handleScroll = () => {
        const scrollPosition = window.scrollY;
        const documentHeight = document.documentElement.scrollHeight;
        const windowHeight = window.innerHeight;
        const offset = 400;
        if (scrollPosition + windowHeight >= documentHeight - offset) {
            // If the user has scrolled more than 200 pixels, show the button
            this.setState({ showScrollButton: false });
        } else {
            // Otherwise, hide the button
            this.setState({ showScrollButton: true });
        }
    };


    render() {
        const { children, homePage, basketDetails, selected, } = this.props;
        let isSelected = selected.some(elem => elem.Quantity > 0)
        let mobileView = window.matchMedia('(max-width: 600px)').matches
        return (
            <>
                { homePage === "/" ? <HomeHeader /> : <Header />}
                <div>{children}</div>
                 <Footer />
                {/* { <a id="scrollUp" href="#top" title="" style={{ position: "fixed", zIndex: "2147483647;" }}></a>} */}
                {/* { <a id="scrollUp" href="#top" title="" style={{ position: "fixed", zIndex: "2147483647;" }}></a>} */}
                <div>
                    {!this.state.showScrollButton && window.location.pathname.split('/')[1] === 'home'&& (
                        <button
                            id="scrollUp"
                            className={`${(window.location.pathname.split('/')[1] === 'tickets') ?
                                "tickets-btn_scroll display-none-mobile" :
                                "tickets-btn_scroll_overAll"}`}
                            style={{ position: "fixed", zIndex: "9999999", bottom: "30px" }}
                            onClick={() => window.scrollTo(0, 0)}
                            href="#top"
                            
                            type="button"
                            data-toggle="modal"
                        >
                        </button>
                    )}

                    {/* {isSelected && !this.state.showScrollButton &&
                        <button id="scrollUp" className={`${(window.location.pathname.split('/')[1] === 'tickets') ? "tickets-btn_scroll_overAll display-none-mobile" : ""}`}
                            style={{ position: "fixed", zIndex: "999999", bottom: "30px" }}
                            onClick={() => window.scrollTo(0, 0)} href="#top"
                            type="button"
                            data-toggle="modal" >

                        </button>} */}
                </div>
                {/* {!isSelected && !this.state.showScrollButton && window.location.pathname.split('/')[1] === 'tickets' && mobileView &&
                    <div >
                        <button id="scrollUp" className={`${(window.location.pathname.split('/')[1] === 'tickets') ? "tickets-btn_scroll_overAll display-none-mobile" : ""}`}
                            style={{ position: "fixed", zIndex: "999999", bottom: "40px" }}
                            onClick={() => window.scrollTo(0, 0)} href="#top"
                            type="button"
                            data-toggle="modal" >

                        </button>
                    </div>} */}

                {/* {!isSelected &&<a id="scrollUpBtn" href="#top" title="" style={{ position: "fixed", zIndex: "2147483647;" }}></a>} */}
                <Cookie />
            </>
        );
    }
}

Layout.propTypes = {
    homePage: PropTypes.string.isRequired,

};
function mapStateToProps(state) {
    return {

        selected: state.eventReducer.selected,

    };
}



export default connect(mapStateToProps)(Layout);
// export default connect(mapStateToProps)(Layout);
// export default Layout;
