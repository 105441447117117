import Helper from "../services/helper";
const axios = require("axios");

class UserAPI {
    static GetUserProfile = async (request) => {
        return await axios.get(
            `https://scankwebapi.azurewebsites.net/api/User/GetUserProfile/${request.id}`,
            {
                headers: Helper.authHeader(),
            }
        );
    }

    static GetUserTickets = async (request) => {
        return await axios.get(
            `https://scankwebapi.azurewebsites.net/api/Event/GetTicketList?userId=${request.id}`,
            {
                headers: Helper.authHeader(),
            }
        );
    }

    static DeleteAccount = async (request) => {
        return await axios.post(
            `https://scankwebapi.azurewebsites.net/api/user/DeleteAccount`,
            request.params,
            {
                headers: Helper.authHeader(),
            }
        );
    }
}

export default UserAPI;