import Helper from "../services/helper";
const axios = require("axios");

class EventAPI {
    static GetUpcomingEvents = async (id) => {
        return await axios.get(
            `https://scankwebapi.azurewebsites.net/api/organizer/GetUpcomingEvents?userId=${id}`,
            {
                headers: Helper.authHeader(),
            }
        );
    }

    static GetFilteredEvents = async (request) => {
        return await axios.post(
            `https://scankwebapi.azurewebsites.net/api/ScankEvent/FilterEvent`,
            request.params,
            {
                headers: Helper.authHeader(),
            }
        );
    }

    static GetFavouriteEvents = async (request) => {
        return await axios.get(
            `https://scankwebapi.azurewebsites.net/api/Event/GetFavouriteList?userId=${request.params}`,
            {
                headers: Helper.authHeader(),
            }
        );
    }

    static GetEventDetails = async (request) => {
        return await axios.get(
            `https://scankwebapi.azurewebsites.net/api/Event/GetDetail/${request.params.id}?userId=${request.params.userId}`,
            {
                headers: Helper.authHeader(),
            }
        );
    }

    static GetTrendingEvents = async (request) => {
        return await axios.get(
            `https://scankwebapi.azurewebsites.net/api/ScankEvent/GetWesbiteTrendingList?userId=${request.params}`,
            {
                headers: Helper.authHeader(),
            }
        );
    }

    static AddToFavourite = async (request) => {
        return await axios.post(
            `https://scankwebapi.azurewebsites.net/api/Event/AddToFavourite`,
            request.params,
            {
                headers: Helper.authHeader(),
            }
        );
    }

    static DeleteFavourite = async (request) => {
        return await axios.post(
            `https://scankwebapi.azurewebsites.net/api/Event/DeleteFavourite`,
            request.params,
            {
                headers: Helper.authHeader(),
            }
        );
    }

    static GetEventTickets = async (request) => {
        return await axios.get(
            `https://scankwebapi.azurewebsites.net/api/ScankEvent/GetTicketList?eventId=${request.id}`,
            {
                headers: Helper.authHeader(),
            }
        );
    }

    static AddItemToBasket = async (request) => {
        return await axios.post(
            `https://scankwebapi.azurewebsites.net/api/Basket/AddItemToBasket`,
            request.params,
            {
                headers: Helper.authHeader(),
            }
        );
    }

    static GetBasketDetails = async (request) => {
        return await axios.get(
            `https://scankwebapi.azurewebsites.net/api/Basket/GetBasketDetail?userId=${request.params.userId}&voucherCode=${request.params.voucherCode}`,
            {
                headers: Helper.authHeader(),
            }
        );
    }

    static ClearBasket = async (request) => {
        return await axios.post(
            `https://scankwebapi.azurewebsites.net/api/Basket/ClearBasket?userId=${request.params.userId}`,
            {
                headers: Helper.authHeader(),
            }
        );
    }

    static DoPayment = async (request) => {
        return await axios.post(
            `https://scankwebapi.azurewebsites.net/api/Payment/SetPaidBasket`,
            request.params,
            {
                headers: Helper.authHeader(),
            }
        );
    }

    static SendSMS = async (request) => {
        return await axios.post(
            `https://scankwebapi.azurewebsites.net/api/Event/SendMessageUsingTwilio`,
            request.params,
            {
                headers: Helper.authHeader(),
            }
        );
    }

    static GetDetailsByWebsiteURL = async (request) => {
        return await axios.get(
            `https://scankwebapi.azurewebsites.net/api/Event/GetDetailsByWebsiteURL/?websiteUrl=${request.params.websiteUrl}&userId=${request.params.userId}`,
            {
                headers: Helper.authHeader(),
            }
        );
    }
}

export default EventAPI;
