import Helper from "../services/helper";
const axios = require("axios");

class CategoryAPI {
    static GetCategories = async (id) => {
        return await axios.get(
            `https://scankwebapi.azurewebsites.net/api/Category/GetList?s=&userId=${id}`,
            {
                headers: Helper.authHeader(),
            }
        );
    }
}

export default CategoryAPI;