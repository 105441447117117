import React, { Component } from "react";
import { getSiteContent } from "../../redux/actions/actions"
import { connect } from "react-redux";
import loading from "../../images/loading.gif";
import Helper from '../../services/helper';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showProgress: true,
        };
    }

    componentDidMount() {
        if (!Helper.IsValidated()) {
            this.props.history.push("/");
        }

        this.getAbout();
    }

    getAbout = async () => {
        const { getSiteContent } = this.props;
        var About = new Object();
        About.title = 'About'
        await getSiteContent(About);
    }

    componentDidUpdate(prevProps) {
        const { isGetSiteContentSuccess: prevIsGetSiteContentSuccess } = prevProps;
        const { isGetSiteContentSuccess } = this.props;


        if (prevIsGetSiteContentSuccess === false && isGetSiteContentSuccess) {

            this.setState({ showProgress: false });
        }

    }

    render() {
        const { siteContent } = this.props
        const { showProgress } = this.state
        return (
            <div class='about'>
                {
                    showProgress ?
                        <div class="tickets" >
                            <div class="container-fluid">
                                <div class="tickets__wrapper faq__wrapper"
                                    style={{ background: "none", minHeight: "370px", height: "auto" }}>
                                    <div class="tab-content" style={{ textAlign: "center" }}>
                                        <img src={loading} style={{ width: "32px", height: "32px", alignitems: "center" }} />

                                    </div></div>
                                <div>
                                </div>
                            </div>
                        </div>
                        :
                        <div class="tickets" >
                            <div class="container-fluid">
                                <div class="tickets-container">
                                    <div class="tickets__wrapper faq__wrapper">
                                        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <a className="nav-link active" id="pills-profile-tab" data-toggle="pill" href="#" role="tab" aria-controls="pills-profile" aria-selected="false" style={{cursor: "default"}}>
                                                <i class="fas fa-edit"></i>
                                                    About
                                                    </a>
                                            </li>
                                        </ul>
                                        <div class="tab-content" id="pills-tabContent">
                                            <div dangerouslySetInnerHTML={{ __html: siteContent }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>);
    }
}


function mapStateToProps(state) {
    return {
        isGetSiteContentSuccess: state.siteContentReducer.isGetSiteContentSuccess,
        isGetSiteContentError: state.siteContentReducer.isGetSiteContentError,
        siteContent: state.siteContentReducer.siteContent,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        getSiteContent: (data) => dispatch(getSiteContent(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(About);