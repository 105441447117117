import React, { Component, Fragment, createRef } from "react";
import { getSiteContent } from "../../redux/actions/actions"
import { connect } from "react-redux";
import loading from "../../images/loading.gif";
import Helper from '../../services/helper';
import { Container, Row, Col, } from "react-bootstrap";
import bookImg1 from '../../images/bookImg1.png';
import bookImg2 from '../../images/bookImg2.png';
import bookImg3 from '../../images/bookImg3.png';
import bookImg4 from '../../images/bookImg4.png';
import bookImg5 from '../../images/bookImg5.png';
import sitelogo from "../../images/site-logo.png";
import Slide1 from "../../images/slide1.png";
import Slide2 from "../../images/slide2.png";
import Slide3 from "../../images/slide3.png";
import Slide4 from "../../images/slide4.png";
import whatsappFill from "../../images/WhatsAppfill.png";
import SklogoOR from "../../images/ORGANISER.png";
import appStoreImg from "../../images/app-store.svg";
import playStoreImg from "../../images/google-play-store.svg";
import QrcodeIMG from "../../images/mainAppQRCode.png";
import QrcodeBlk from "../../images/qrcode.png";
import Tryit from "../../images/try-it.png";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Slider from "react-slick";
import emailjs from '@emailjs/browser';
import { isValidPhoneNumber } from 'react-phone-number-input'; // Import phone number validation library
import PhoneInput from 'react-phone-input-2'; // Import phone input library
import 'react-phone-input-2/lib/style.css'; // Import styles for phone input
import { useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import dummy from "../../images/SK.jpg"
import dummyVideo from "../../images/video/phoneSpinAboutUs.mp4"

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showProgress: true,
            Modalshow: false,
            formData: {
                user_name: '',
                user_whatsapp: '',
                user_city: ''
            },
            errors: {}

        };
        this.form = createRef();
    }
    handleShow = () => {
        this.setState({ Modalshow: true });
    };

    handleClose = () => {
        this.setState({ Modalshow: false });
    };
    componentDidMount() {
        if (!Helper.IsValidated()) {
            this.props.history.push("/");
        }

        this.getAbout();
    }

    getAbout = async () => {
        const { getSiteContent } = this.props;
        var About = new Object();
        About.title = 'About'
        await getSiteContent(About);
    }

    componentDidUpdate(prevProps) {
        const { isGetSiteContentSuccess: prevIsGetSiteContentSuccess } = prevProps;
        const { isGetSiteContentSuccess } = this.props;

        if (prevIsGetSiteContentSuccess === false && isGetSiteContentSuccess) {

            this.setState({ showProgress: false });
        }

    }
    sendEmail = (e) => {
        e.preventDefault();
        const errors = this.validateForm();
        if (Object.keys(errors).length > 0) {
            this.setState({ errors });
            return;
        }
        // console.log('-----------------------------------------------')
        const templateParams = {
            from_name: this.state.formData.user_name,
            message: `Name: ${this.state.formData.user_name} City: ${this.state.formData.user_city} WhatsApp: ${this.state.formData.user_whatsapp}`
        };
        console.log("this form current ",templateParams);
        emailjs.send('service_4nvcz4h', 'template_y9x71qg', templateParams, 'rmwCRPWi_7CCG0AoM')
            .then(() => {
                console.log('SUCCESS!');
                // this.handleClose();
                this.setState({   formData: {
                    user_name: '',
                    user_whatsapp: '',
                    user_city: ''
                } });
            }, (error) => {
                console.log('FAILED...', error.text);
            });
    };

    validateForm = () => {
        const { user_name, user_whatsapp, user_city } = this.state.formData;
        const errors = {};
        // console.log(user_whatsapp,'---------------------------',isValidPhoneNumber(user_whatsapp))
        if (!user_name) errors.user_name = "Full Name is required.";
        if (!user_whatsapp) errors.user_whatsapp = "Valid WhatsApp number is required.";
        if (!user_city) errors.user_city = "City is required.";

        return errors;
    };

    handleChange = (name, value) => {
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: value
            },
            errors: {
                ...prevState.errors,
                [name]: null // Clear the error for the specific field
            }
        }));
    };
    render() {
        const { siteContent } = this.props
        const { showProgress, Modalshow, formData, errors } = this.state
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            autoplay: true,
            slidesToScroll: 1,
            pauseOnHover: true,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <>
                <div class='about-us'>
                    <Container>
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div className="text-center about-main">
                                    <Fragment>
                                        <hgroup>
                                            <h2 class="pixter-title pixter-font">The worlds first AR event booking portal</h2>
                                            <p class="">
                                                Just like your current booking platform... but with shades on
                                            </p>
                                        </hgroup>
                                        {/* <video class="featured__chessboard-video" autoPlay muted loop playsInline>
                            <source src={chessboardVideo} type="video/mp4" />
                        </video> */}
                                        <video width="80%" height="540" autoPlay muted loop playsInline>
                                            <source src={dummyVideo} type="video/mp4" />
                                        </video>
                                    </Fragment>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className="booking-section">
                        <Container >
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <hgroup>
                                        <h2 class="get-app__title title">The ultimate booking experience</h2>
                                        <p class="bottom-red"></p>
                                    </hgroup>
                                    <Row>
                                        <Col xl={2} lg={2} md={2}>
                                            <div className="booking-sec text-center">
                                                <h6> <span className="color-red">Explore</span>  the best events in your city!</h6>
                                                <img src={bookImg1} alt="img" />
                                            </div>
                                        </Col>
                                        <Col xl={2} lg={2} md={2}>
                                            <div className="booking-sec text-center">
                                                <h6> <span className="color-red">Scan </span> & <span className="color-red">preview </span> event footage on
                                                    a flyer with the power of <span className="color-red">AR</span></h6>
                                                <img src={bookImg2} alt="img" />
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={4} md={4}>
                                            <div className="booking-sec  text-center">
                                                <img src={bookImg3} alt="img" className="middleImg" />
                                            </div>
                                        </Col>
                                        <Col xl={2} lg={2} md={2}>
                                            <div className="booking-sec text-center">
                                                <h6> <span className="color-red">Allocate Tickets
                                                </span>  to friends </h6>
                                                <img src={bookImg4} alt="img" />
                                            </div>
                                        </Col>
                                        <Col xl={2} lg={2} md={2}>
                                            <div className="booking-sec text-center">
                                                <h6> View event details with cool
                                                    <span className="color-red"> Attraction stickers!</span> </h6>
                                                <img src={bookImg5} alt="img" />
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* <div className="d-flex justify-center align-items-center w-100">
                                    <div className="booking-sec text-center">
                                        <h6> <span className="color-red">Explore</span>  the best events in your city!</h6>
                                        <img src={bookImg1} alt="img" />
                                    </div>
                                    <div className="booking-sec text-center">
                                        <h6> <span className="color-red">Scan </span> & <span className="color-red">preview </span> event footage on
                                            a flyer with the power of <span className="color-red">AR</span></h6>
                                        <img src={bookImg2} alt="img" />
                                    </div>
                                    <div className="booking-sec  text-center">
                                        <img src={bookImg3} alt="img" />
                                    </div>
                                    <div className="booking-sec text-center">
                                        <h6> <span className="color-red">Allocate Tickets
                                        </span>  to friends </h6>
                                        <img src={bookImg4} alt="img" />
                                    </div>
                                    <div className="booking-sec text-center">
                                        <h6> View event details with cool 
                                        <span className="color-red"> Attraction stickers!</span> </h6>
                                        <img src={bookImg5} alt="img" />
                                    </div>
                                </div> */}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="qr-section">
                        <Container>
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div className="inner-qr-section">
                                        <div className="d-flex align-items-center justify-content-end ">
                                            <a class="" href="/">
                                                <img src={sitelogo} alt="SCANK" class="main-logo" />
                                            </a>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-end mt-3">
                                            <a href="https://apps.apple.com/gb/app/scank/id1574492173" class="confirmation__social-link">
                                                <img src={appStoreImg} width="120" height="31" alt="App Store" class="confirmation__social-icon mt-2 mb-4" />
                                            </a>
                                            <a href="https://play.google.com/store/apps/details?id=com.scank.smartapps" class="confirmation__social-link">
                                                <img src={playStoreImg} width="110" height="31" alt="Google Play Store" class="confirmation__social-icon mt-2 mb-4" />
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div className="inner-qr-section mb-4 no-show-mobile">
                                        <div className="d-flex align-items-center justify-content-start">
                                            {/* <a href="javascript:;" class="">
                                                <img src={QrcodeIMG} width="120" alt="Qr code" class="" />
                                            </a> */}
                                            <div style={{ width: "120" }}> <QRCodeCanvas value={`${window.location.origin}/redirect/main`} fgColor="#ff0000" size={110}  /> </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="download-try-section">
                        <Container>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className="inner-download-section d-flex justify-content-center text-center">
                                            <hgroup>
                                                <h3 className="color-red">Give it a try, download now!  </h3>
                                                <h4>Follow the link on a desktop/laptop to scan the image.
                                                    If you're on a mobile, try using another device to scan it."</h4>
                                            </hgroup>
                                        </div>
                                        <div className="try-it">
                                            <button onClick={this.handleShow}>
                                                <img src={Tryit} alt="try btn inage" />
                                            </button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="organizer-section top-bottom-custom-padding">
                        <hgroup>
                            <h2 class="get-app__title title">Scank for the Organiser</h2>
                            <p class="bottom-red"></p>
                        </hgroup>
                        <Container>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div className="inner-organizer text-center">
                                        <h4>Join the family, promote your events with Scank!</h4>
                                        <h5>Exclusive Early Access – Limited Invites Available</h5>
                                        <p>Secure your exclusive invite code to promote your events on Scank before they're gone!
                                            Simply fill out the form below, to receive your unique code and more info via whatsapp.</p>
                                        <h6>Early access comes with perks:</h6>
                                        <p>Discounts on design @paperboygraphics / Free AR promo / Exclusive marketing opportunities
                                            What you waiting for?</p>
                                    </div>
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Form ref={this.form} className="scank-form" onSubmit={this.sendEmail}>
                                        <Row>
                                            <Form.Group as={Col} controlId="formGridName">
                                                <Form.Label>Full Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="user_name"
                                                    value={formData.user_name}
                                                    onChange={(e) => this.handleChange('user_name', e.target.value)}
                                                    isInvalid={!!errors.user_name}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.user_name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridnumber">
                                                <Form.Label>
                                                    <img src={whatsappFill} alt="WhatsApp Icon" /> WhatsApp No
                                                </Form.Label>
                                                <PhoneInput
                                                    country={'us'} // Set default country
                                                      name="user_whatsapp"
                                                    value={formData.user_whatsapp}
                                                    onChange={(value) => this.handleChange('user_whatsapp', value)}
                                                    isInvalid={!!errors.user_whatsapp}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.user_whatsapp}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridcity">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control
                                                    name="user_city"
                                                    value={formData.user_city}
                                                    onChange={(e) => this.handleChange('user_city', e.target.value)}
                                                    isInvalid={!!errors.user_city}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.user_city}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Button className="w-100 btn-blue" variant="primary" type="submit">
                                            Request Code
                                        </Button>
                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="manage-slider-section top-bottom-custom-padding">
                        <Container>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Slider {...settings}>
                                        <div className="d-flex flex-column align-items-center">
                                            <h6> <span className="color-red">Manage </span> all your <span className="color-red">events </span> on the go! </h6>
                                            <img src={Slide1} alt="img" />
                                        </div>
                                        <div className="d-flex flex-column align-items-center">
                                            <h6> <span className="color-red">Quickly View </span> event list & live <span className="color-red">stats </span> stats</h6>
                                            <img src={Slide2} alt="img" />
                                        </div>
                                        <div className="d-flex flex-column align-items-center">
                                            <h6> <span className="color-red">Track sales </span> & event progress</h6>
                                            <img src={Slide3} alt="img" />
                                        </div>
                                        <div className="d-flex flex-column align-items-center">
                                            <h6>
                                                <span className="color-red">Monitor </span> guest list  & <span className="color-red">attendance </span>
                                            </h6>
                                            <img src={Slide4} alt="img" />
                                        </div>
                                        <div className="d-flex flex-column align-items-center">
                                            <h6> <span className="color-red">Manually </span> check guest in</h6>
                                            <img src={Slide1} alt="img" />
                                        </div>
                                        <div className="d-flex flex-column align-items-center">
                                            <h6> Add<span className="color-red"> cool Attraction </span> Icons to detail your event! </h6>
                                            <img src={Slide2} alt="img" />
                                        </div>
                                    </Slider>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="qr-section top-bottom-custom-padding">
                        <Container>
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div className="inner-qr-section">
                                        <div className="d-flex align-items-center justify-content-end ">
                                            <a class="w150-h120" href="/">
                                                <img src={SklogoOR} alt="SCANK" class="main-logo" />
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div className="inner-qr-section no-show-mobile">
                                        <div className="d-flex align-items-center justify-content-start ">
                                            {/* <a class="w150-h120" href="/">
                                                <img src={QrcodeBlk} alt="SCANK" class="main-logo" />
                                            </a> */}
                                            <div class="w150-h120"> <QRCodeCanvas value={`${window.location.origin}/redirect/organizer`}  size={115} /></div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>

                                    <div className="d-flex align-items-center justify-content-center mt-3">
                                        <a href="https://apps.apple.com/gb/app/scankorganizers/id1608159730" class="confirmation__social-link">
                                            <img src={appStoreImg} width="120" height="31" alt="App Store" class="confirmation__social-icon mt-2 mb-4" />
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=com.scank.organizer" class="confirmation__social-link">
                                            <img src={playStoreImg} width="110" height="31" alt="Google Play Store" class="confirmation__social-icon mt-2 mb-4" />
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Modal dialogClassName="modal-90w" className="try-modal" show={Modalshow} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <img src={dummy} alt="Dummy Image" class="w-100" />
                    </Modal.Body>
                </Modal>
            </>

        );
    }
}


function mapStateToProps(state) {
    return {
        isGetSiteContentSuccess: state.siteContentReducer.isGetSiteContentSuccess,
        isGetSiteContentError: state.siteContentReducer.isGetSiteContentError,
        siteContent: state.siteContentReducer.siteContent,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        getSiteContent: (data) => dispatch(getSiteContent(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(About);