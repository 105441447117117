import { GET_CITIES, GET_CITIES_SUCCESS, GET_CITIES_ERROR } from '../actions/actionTypes';

const initialState = {
    isGetCitiesSuccess: false,
    isGetCitiesError: false,
    cities: []
};

export function cityReducer(state = initialState, { type, response }) {
    switch (type) {
        case GET_CITIES:
            return {
                ...state,
                isGetCitiesSuccess: false,
                isGetCitiesError: false,
                cities: []
            };
        case GET_CITIES_SUCCESS:
            return {
                ...state,
                isGetCitiesSuccess: true,
                isGetCitiesError: false,
                cities: response,
            };
        case GET_CITIES_ERROR:
            return {
                ...state,
                isGetCitiesSuccess: false,
                isGetCitiesError: true,
                cities: []
            };
        default:
            return state;
    }
}
