import React from 'react';
import { Route } from 'react-router-dom';
import routes from './routes';
import Layout from '../components/layout';
import AppLogout from "../services/appLogout";

const PublicRoutes = () => {
    // Get public routes from all routes
    const publicRoutes = routes.filter((route) => route.isPublic);

    // Render public routes
    return publicRoutes.map((route) => {
        const RouteVal = route.component;
        
        return (
            <Route
                key={route.id}
                path={route.path}
                exact
                render={(props) => (
                    <AppLogout>
                        <Layout homePage={route.path}>
                            <RouteVal {...props} />
                        </Layout>
                    </AppLogout>
                )}
            />
        );
    });
};

export default PublicRoutes;