import $ from 'jquery';
import './slick/slick';

$(document).ready(function() {
    $('#featuredCarousel').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        variableWidth: false,
        autoplaySpeed: 2000,
        autoplay: true,
        arrows: true,
        prevArrow: $('.featured__carousel-prev'),
        nextArrow: $('.featured__carousel-next'),
        responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
              }
            },
            {
                breakpoint: 568,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });

    $('.favouriteButt').on('click', function() {
        $(this).toggleClass('active');
    });

    //window.onscroll = function() {myFunction()};
    var header = document.getElementById("nav");
    var sticky = (header) ? header.offsetTop : 0;

    // function myFunction() {
    //     if (window.pageYOffset > sticky) {
    //         header.class.add("sticky-bg");
    //     } else {
    //         header.class.remove("sticky-bg");
    //     }
    // }

    $('#videoMuted').click(function () {
        var video =  $('#videoBG');

        if (video[0].muted === false) {
            video[0].muted = true;
            $(this).html('<i class="fas fa-volume-mute"></i>');
        } else {
            video[0].muted = false;
            $(this).html('<i class="fas fa-volume-up"></i>');
        }
    });
});