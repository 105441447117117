import React, { Component } from "react";
import { connect } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Modal } from "react-bootstrap";
import Slider from "react-slick";
import CheckoutForm from '../../stripe/checkoutForm';
import timeIcon from "../../images/time.png";
import calendarIcon from "../../images/calendar_ic.svg";
import locationIcon from "../../images/location-pin.svg";
import ticketIcon from "../../images/ticket-icon.svg";
import closeIcon from "../../images/close.svg";
import loading from "../../images/loading.gif";
import cardsIcon from "../../images/cards.png";
import paypalIcon from "../../images/paypal.png";
import orderLogo from "../../images/order-modal-logo.png";
import closeWhite from "../../images/close_white.png";
import okImg from "../../images/ok.png";
import appStoreImg from "../../images/app-store.svg";
import playStoreImg from "../../images/google-play-store.svg";
import Helper from '../../services/helper';
import { PayPalButton } from "react-paypal-button-v2";
import { getEventDetailsByUrl, getEventTickets, addToBasket, getBasket, clearBasket, doPayment, addToSelected } from "../../redux/actions/actions";

let countdown = 600;
const axios = require("axios");

class EventDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userId: (Helper.IsAuthenticated()) ? Helper.GetCurrentUser().UserId : null,
            eventId: "0",
            mapLocation: '',
            showProgress: true,
            notFound: false,
            paymentSuccess: (this.props.match.params["p"] === undefined) ? false : true,
        };
    }

    componentDidMount() {
        if (!Helper.IsValidated()) {
            this.props.history.push("/");
        }

        countdown = 600;

        this.getStripePubk();
        this.getPayplPubk();
        this.getDetails();
        
        if (this.state.paymentSuccess) {
            this.setState({ showPaymentSuccess: true });
        }
    }

    startTimer = () => {
        let _this = this;

        let interval = setInterval(() => {
            let minutes; let seconds;

            minutes = parseInt(countdown / 60, 10)
            seconds = parseInt(countdown % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            let t = minutes + ":" + seconds;
            _this.setState({ timer: t });

            if (--countdown < 0) {
                clearInterval(interval);
                countdown = 600;
                _this.props.history.push('/events');
            }
        }, 1000);
    }

    componentDidUpdate(prevProps, prevState) {
        const { isGetEventDetailsByUrlSuccess: prevIsGetEventDetailsByUrlSuccess, isGetEventDetailsByUrlError: prevIsGetEventDetailsByUrlError, isGetEventTicketsSuccess: prevIsGetEventTicketsSuccess, isAddItemToBasketSuccess: prevIsAddItemToBasketSuccess, isAddItemToBasketError: prevIsAddItemToBasketError, isClearBasketSuccess: prevIsClearBasketSuccess, isGetBasketSuccess: prevIsGetBasketSuccess } = prevProps;
        const { isGetEventDetailsByUrlSuccess, isGetEventDetailsByUrlError, eventDetailsByUrl, isGetEventTicketsSuccess, eventTickets, isAddItemToBasketSuccess, basket, isAddItemToBasketError, clearBasket, isClearBasketSuccess, isGetBasketSuccess, basketDetails } = this.props;

        if (prevIsGetEventDetailsByUrlError === false && isGetEventDetailsByUrlError) {
            this.setState({ notFound: true, showProgress: false });
        }

        if (prevIsGetEventDetailsByUrlSuccess === false && isGetEventDetailsByUrlSuccess) {
            let coord = eventDetailsByUrl.MapLat + "," + eventDetailsByUrl.MapLng;

            let mapURL = 'https://maps.google.com/maps?q=' + coord + '&t=&z=14&ie=UTF8&iwloc=&output=embed';

            this.setState({ mapLocation: mapURL, showProgress: false, eventId: eventDetailsByUrl.Id }, function () {
                this.getTickets();
            });
        }

        if (prevIsGetEventTicketsSuccess === false && isGetEventTicketsSuccess) {
            let tickets = [];

            eventTickets.map((tkt) => {
                tickets.push({
                    TicketId: tkt.Id,
                    Title: tkt.Title,
                    StartTime: tkt.StartTime,
                    EndTime: tkt.EndTime,
                    Currency: tkt.Currency,
                    MaxQtyPerUser: tkt.MaxQtyPerUser,
                    AvaibleTicket: tkt.AvaibleTicket,
                    Price: parseFloat(tkt.Price).toFixed(2),
                    Quantity: 0,
                    TotalPrice: 0
                })
            });

            this.setState({ loadTickets: false, tickets: tickets }, function () {
                this.startTimer();
            });
        }

        if (prevIsAddItemToBasketSuccess === false && isAddItemToBasketSuccess) {
            this.getBasketDetails();

            this.setState({ showPayment: true });
        }

        if (prevIsAddItemToBasketError === false && isAddItemToBasketError) {
            if (basket === 'Sepette başka bir evente ait bilet var') {
                var objUser = new Object();

                objUser.userId = this.state.userId;

                clearBasket(objUser);
            }
        }

        if (prevIsClearBasketSuccess === false && isClearBasketSuccess) {
            this.handleGetTickets();
        }

        if (prevIsGetBasketSuccess === false && isGetBasketSuccess) {
            let basket = { eventId: this.state.eventId, basketId: basketDetails.BasketId, totalAmount: basketDetails.TotalPrice };
            Helper.saveObject("basket", JSON.stringify(basket));
        }
    }

    getDetails = async () => {
        const { userId } = this.state;
        const { getEventDetailsByUrl } = this.props;

        var objEvent = new Object();

        objEvent.websiteUrl = window.location.pathname;
        objEvent.userId = userId;

        await getEventDetailsByUrl(objEvent);
    }

    getTickets = async () => {
        const { eventId } = this.state;
        const { getEventTickets } = this.props;

        await getEventTickets(eventId);
    }

    updateQty = async (ticket, mode) => {
        let allTickets = this.state.tickets;
        const { addToSelected } = this.props;

        if (mode === "plus") {
            let id = ticket.TicketId;
            let qty = parseInt(ticket.Quantity) + 1;
            let total = parseFloat(ticket.Price) * qty;

            for (var i in allTickets) {
                if (allTickets[i].TicketId == id) {
                    allTickets[i].Quantity = qty;
                    allTickets[i].TotalPrice = total;
                    break;
                }
            }
        }
        else {
            let id = ticket.TicketId;
            let qty = parseInt(ticket.Quantity) - 1;
            let total = parseFloat(ticket.Price) * qty;

            for (var i in allTickets) {
                if (allTickets[i].TicketId == id) {
                    allTickets[i].Quantity = qty;
                    allTickets[i].TotalPrice = total;
                    break;
                }
            }
        }
        await addToSelected(allTickets)
        this.setState({ tickets: allTickets });
    }

    handleGetTickets = async () => {
        const { userId, tickets } = this.state;
        const { addToBasket } = this.props;

        let totalZeroQty = 0;

        if (userId == null) {
            // alert('Please login to continue.');
            Helper.toast("OOPs Please login to continue.", "warning")
            document.getElementById("navSignIn").click();

            setTimeout(() => {
                document.getElementById("divSignIn").click();
            }, 200);

            //document.getElementById("divSignIn").click();
            return;
        }

        var objTicket = new Object();

        objTicket.UserId = userId;
        objTicket.List = tickets;

        for (var i in tickets) {
            if (tickets[i].Quantity > 0) {
                if (tickets[i].AvaibleTicket <= 0) {
                    Helper.toast("Sorry, " + tickets[i].Title + " tickets are sold out!", "warning")
                    return;
                }
                else if (tickets[i].Quantity > tickets[i].AvaibleTicket) {
                    Helper.toast("Sorry, only " + tickets[i].AvaibleTicket + " " + tickets[i].Title + " tickets remaining!", "warning")
                    return;
                }
            }
            else if (tickets[i].Quantity == 0) {
                totalZeroQty++;

            }
        }

        if (totalZeroQty === tickets.length) {
            Helper.toast("Please select valid quantity", "warning")
            return;
        }
        else
            await addToBasket(objTicket);
    }

    getBasketDetails = async () => {
        const { userId } = this.state;
        const { getBasket } = this.props;

        var objBasket = new Object();

        objBasket.userId = userId;
        objBasket.voucherCode = '';

        await getBasket(objBasket);
    }

    handleClosePayment = () => {
        this.setState({ showPayment: false });
    }

    handleShowPaymentSuccess = () => {
        this.setState({ showPayment: false, showPaymentSuccess: true });
    }

    handleClosePaymentSuccess = () => {
        this.setState({ showPaymentSuccess: false }, function () {
            this.props.history.push("/");
        });
    }

    createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: "0.01",
                    },
                },
            ],
        });
    }

    onApprove = (data, actions) => {
        return actions.order.capture();
    }

    getStripePubk = async () => {
        await axios.get("https://scankwebapi.azurewebsites.net/api/Payment/Stripe/PublicKey").then(response => {

            this.setState({ stripePubKey: loadStripe(response.data) });
        });
    }

    getPayplPubk = async () => {


        await axios.get("https://scankwebapi.azurewebsites.net/api/Payment/Paypal/PublicKey").then(response => {
            //console.log('ppl ky:' + response.data);
            this.setState({ payPalPubKey: response.data });

            const script = document.createElement("script");

            script.src = "https://www.paypal.com/sdk/js?client-id=" + response.data + "&disable-funding=credit,card";
            script.async = true;

            document.body.appendChild(script);
        });
    }

    render() {
        const { userId, timer, mapLocation, showProgress, notFound, loadTickets, tickets, showPayment, showPaymentSuccess, stripePubKey, payPalPubKey } = this.state;
        const { eventDetailsByUrl, basketDetails, isLoadingPayment } = this.props;

        //console.log("showProgress: ", showProgress);
        //console.log("notFound: ", notFound);

        let isSelected = tickets ? tickets.some(elem => elem.Quantity > 0) : false;

        return (
            <>
                {
                    showProgress ?
                        <div className="order"><div className="order__title-bg-clear" style={{ background: "none", minHeight: "370px", height: "auto" }}><div className="container-fluid"><img src={loading} style={{ width: "32px", height: "32px" }} /></div></div></div>
                        :
                        notFound ?
                            <div className="order">
                                <div className="container-fluid">
                                    <div className="">
                                        &nbsp;</div>
                                </div>
                                <div className="order__tickets">
                                    <div className="order__tickets-wrapper" style={{ paddingBottom: "100px", justifyContent: "center", fontWeight: "600", fontSize: "18px" }}>
                                        <h1 class="order__title1 title">Sorry! We could not find the page you are looking for.</h1>
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                            <div className="order">
                                <div class="order__title-bg" style={{ backgroundImage: `url(${eventDetailsByUrl.CoverPictures[0]})`, display: 'none' }}>
                                    <div class="container-fluid">
                                        <h1 class="order__title title">{eventDetailsByUrl.Title}</h1>
                                    </div>
                                </div>

                                <div class="order__title-bg" style={{ backgroundImage: `url(${eventDetailsByUrl.CoverPictures[0]})` }}>
                                </div>
                                <div style={{ textAlign: "center", height: "0px" }}>
                                    <h1 class="order__title title" style={{ marginTop: "-175px" }}>{eventDetailsByUrl.Title}</h1>
                                </div>

                                <div className="container-fluid">
                                    <div className="order__tickets-reserved">

                                        {isLoadingPayment ? <div className="loadingOverlay">

                                            <img src={loading} style={{ width: "32px", height: "32px" }} />
                                            <h3 style={{ color: "#fff" }}>Your payment is being processed. Please wait!</h3>
                                        </div> : ''}

                                        <div className="order__tickets-time">
                                            <div className="order__tickets-description">
                                                <img src={timeIcon} alt="Timer" className="order__tickets-timer" />
                                                <span className="order__tickets-text">
                                                    Tickets will be reserved for <span className="order__tickets-time">{timer}</span> Minutes
                                                </span>
                                            </div>
                                        </div>
                                        <ul className="order__tickets-date list">
                                            <li className="order__date-item">
                                                <img src={calendarIcon} width="30" height="30" alt="icon" className="order__date-image" />
                                                <p className="order__date-text">
                                                    {Helper.getDayFromDate(eventDetailsByUrl.StartDate)}{" "}{Helper.formatDate(eventDetailsByUrl.StartDate)}{" "}{Helper.getYearFromDate(eventDetailsByUrl.StartDate)}
                                                </p>
                                                <p className="order__date-time">
                                                    {Helper.formatTime(eventDetailsByUrl.StartDate)}
                                                </p>
                                            </li>
                                            <li className="order__date-item">
                                                <img src={locationIcon} width="20" height="30" alt="icon" className="order__date-image" />
                                                <p className="order__date-text">
                                                    {(eventDetailsByUrl.Venue) ? eventDetailsByUrl.Venue + "," : "-"}
                                                </p>
                                                <p className="order__date-time">
                                                    {(eventDetailsByUrl.City) ? eventDetailsByUrl.City + "," : ""}{" "}{(eventDetailsByUrl.Postcode) ? eventDetailsByUrl.Postcode : ""}
                                                </p>
                                            </li>
                                            <li className="order__date-item">
                                                <img src={ticketIcon} width="32" height="32" alt="icon" className="order__date-image" />
                                                <p className="order__date-text">
                                                    Tickets
                                                </p>
                                                <p className="order__date-time">
                                                    From {eventDetailsByUrl.Currency}{parseFloat(eventDetailsByUrl.Price).toFixed(2)}
                                                </p>
                                            </li>
                                        </ul>
                                        <div className="order__ticket-description" dangerouslySetInnerHTML={{ __html: eventDetailsByUrl.Description }}>
                                        </div>
                                    </div>
                                    <div className="order__tickets">
                                        <h4 className="order__tickets-title">
                                            Tickets
                                        </h4>
                                        <div className="order__tickets-wrapper">
                                            <div className="order__tickets-box">
                                                {loadTickets ?
                                                    <ul className="order__tikets-blank list">
                                                        <li className="order__blank-item active">
                                                            <img src={loading} style={{ width: "32px", height: "32px" }} />{" "}
                                                        </li>
                                                    </ul> :
                                                    <ul className="order__tikets-blank list">
                                                            {tickets &&tickets.map((tkt) => (
                                                            <li className="order__blank-item active">
                                                                <span className="order__blank-number">
                                                                    {tkt.Quantity}
                                                                </span>
                                                                <p className="order__blank-date">
                                                                    <span className="order__blank-name">{tkt.Title}</span>
                                                                    <span className="order__blank-datetime">{tkt.Title}: {tkt.Currency}{tkt.Price}</span>
                                                                    {/* <span className="order__blank-datetime">{tkt.StartTime} - {tkt.EndTime}</span> */}

                                                                    <span className="order__blank-price">x {tkt.Currency}{tkt.Price} = <span>{tkt.Currency}{parseFloat(tkt.TotalPrice).toFixed(2)}</span></span>
                                                                </p>
                                                                <div className="order__blank-button">
                                                                    {tkt.Quantity === tkt.MaxQtyPerUser ?
                                                                        <button className="order__blank-btn order__blank-btn_plus" disabled style={{
                                                                            opacity: '0.4', pointerEvents: "none", backgroundColor: "#b8b8b8", color: "#fff"
                                                                        }}>
                                                                            +
                                                                        </button>
                                                                        :
                                                                        <button className="order__blank-btn order__blank-btn_plus" onClick={() => this.updateQty(tkt, 'plus')}>
                                                                            +
                                                                        </button>
                                                                    }
                                                                    {tkt.Quantity === 0 ?
                                                                        <button className="order__blank-btn order__blank-btn_minus" disabled style={{ opacity: '0.4', pointerEvents: "none", backgroundColor: "#b8b8b8", color: "#fff" }}>
                                                                            -
                                                                        </button>
                                                                        :
                                                                        <button className="order__blank-btn order__blank-btn_minus" onClick={() => this.updateQty(tkt, 'minus')}>
                                                                            -
                                                                        </button>
                                                                    }
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                }
                                                <button className="order__gettickets-btn" style={{ display: `${(!isSelected && window.matchMedia('(max-width: 600px)').matches) ? 'none' : ''}` }} type="button" data-toggle="modal" onClick={this.handleGetTickets}>
                                                    Get Tickets
                                                </button>
                                            </div>

                                            <div className="order__entertainment-box">
                                                <div className="order__entertainment-wrap">
                                                    <Slider slidesToShow={1}
                                                        slidesToScroll={1}
                                                        infinite={true}
                                                        autoplay={true}
                                                        arrows={true}
                                                        speed={3000}>
                                                        {eventDetailsByUrl.CoverPictures.map((f) => (
                                                            <img src={f} alt="cover picture" class="order__entertainment-image" />
                                                        ))}
                                                    </Slider>
                                                    <h4 className="order__entertainment-title">
                                                        Attractions
                                                    </h4>
                                                    {eventDetailsByUrl.Features.map((item, index) => (
                                                        <img src={item} key={index} alt="icon" style={{ height: "60px", marginLeft: "10px" }} className="order__entertainment-icon" />
                                                    ))}
                                                    <h4 className="order__entertainment-title">
                                                        Restrictions
                                                    </h4>
                                                    {eventDetailsByUrl.RestrictionList.map((item, index) => (
                                                        <img src={item.Picture} key={index} alt="icon" style={{ height: "60px", marginLeft: "10px" }} className="order__entertainment-icon" />
                                                    ))}
                                                    <h4 className="order__entertainment-title">
                                                        Get There
                                                    </h4>
                                                    <iframe width="100%" height="210" src={mapLocation} frameBorder="1" scrolling="no" marginHeight="0" marginWidth="0" style={{ border: "2px solid #adadad", borderRadius: "5px" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <Modal show={showPayment} className="payment">
                                    <Modal.Body>
                                        <div class="modal-content-body">
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ textAlign: "right", margin: "10px 20px 10px 0" }}>
                                                <img src={closeIcon} width="22" height="22" alt="Close icon" onClick={() => this.handleClosePayment()} />
                                            </button>
                                            <div class="payments__wrapper">
                                                <div class="payments__form">
                                                    <h2 class="payments__form-title">Select Payment Method</h2>
                                                    <div>
                                                        <div class="payments__form-item">
                                                            <div class="payments__form-select">
                                                                <div class="custom-control custom-radio">
                                                                    <input type="radio" name="payments" id="credit-card" class="custom-control-input" />
                                                                    <label class="custom-control-label" for="credit-card">
                                                                        <span class="custom-control-label__text">Credit Card</span>
                                                                    </label>
                                                                </div>
                                                                <img src={cardsIcon} alt="cards" class="payments__form-picture" />
                                                            </div>
                                                            <p class="payments__form-describe">
                                                                Safe money transfer using your bank account. Visa, Maestro, Discover, American Express.
                                                            </p>
                                                            <Elements stripe={stripePubKey}>
                                                                <CheckoutForm />
                                                            </Elements>
                                                        </div>
                                                        <div class="payments__form-item">
                                                            <div class="payments__form-select">
                                                                <div class="custom-control custom-radio">
                                                                    <input type="radio" name="payments" id="paypal" class="custom-control-input" />
                                                                    <label class="custom-control-label" for="paypal">
                                                                        <span class="custom-control-label__text">PayPal</span>
                                                                    </label>
                                                                </div>
                                                                <img src={paypalIcon} alt="cards" class="payments__form-picture" />
                                                            </div>
                                                            <p class="payments__form-describe">
                                                                You will be redirected to PayPal website to complete your purchase securely.
                                                            </p>
                                                            <PayPalButton
                                                                amount={parseFloat(basketDetails.TotalPrice).toFixed(2)}
                                                                // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                                                onSuccess={async (details, data) => {
                                                                    //alert("Transaction completed by " + details.payer.name.given_name);

                                                                    // OPTIONAL: Call your server to save the transaction
                                                                    //return fetch("/paypal-transaction-complete", {
                                                                    //    method: "post",
                                                                    //    body: JSON.stringify({
                                                                    //        orderID: data.orderID
                                                                    //    })
                                                                    //});

                                                                    this.setState({ isLoadingPayment: true });

                                                                    //console.log("Your payment was succeeded!", data);

                                                                    var objPayment = new Object();

                                                                    objPayment.UserId = userId;
                                                                    objPayment.Amount = parseFloat(basketDetails.TotalPrice).toFixed(2);
                                                                    objPayment.PaymentMethodNonce = "paypal";
                                                                    objPayment.BasketId = basketDetails.BasketId;
                                                                    objPayment.Token = data.token;
                                                                    objPayment.TransactionId = data.orderID;
                                                                    objPayment.Region = "";

                                                                    //  await doPayment(objPayment);
                                                                    var self123 = this;
                                                                    await axios.post(
                                                                        `https://scankwebapi.azurewebsites.net/api/Payment/SetPaidBasket`,
                                                                        objPayment,
                                                                        {
                                                                            headers: Helper.authHeader(),
                                                                        }
                                                                    ).then(function (response) {
                                                                        //console.log("done: " + JSON.stringify(response));
                                                                        self123.setState({ isLoadingPayment: false });
                                                                        localStorage.removeItem("basket");
                                                                        if (response.data.State) {
                                                                            window.location.href = window.location.href + "/s";
                                                                        }
                                                                    });
                                                                }}

                                                                options={{
                                                                    clientId: payPalPubKey
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                                <Modal show={showPaymentSuccess} className="paymentSuccess">
                                    <Modal.Body>
                                        <div class="modal-content confirmation__modal-content">
                                            <button type="button" class="close confirmation__close-btn" data-dismiss="modal" aria-label="Close" onClick={this.handleClosePaymentSuccess}>
                                                <span aria-hidden="true"><img src={closeWhite} alt="close" class="confirmation__close-icon" /></span>
                                            </button>
                                            <div class="confirmation__wrapper">
                                                <img src={okImg} alt="" class="confirmation__icon" />
                                                <h3 class="confirmation__title">Order Complete!</h3>
                                                <p class="confirmation__text">Check your email account for confirmation and a copy of your eTicket.</p><br />
                                                <p class="confirmation__text">Log into the Scank App for a copy of your eTicket</p><br />
                                                <a href="#" class="confirmation__title-text">Download the Scank App today for The Ultimate Experience!</a>

                                                <div class="confirmation__wrapper">
                                                    <a href="https://apps.apple.com/gb/app/scank/id1574492173" class="confirmation__social-link">
                                                        <img src={appStoreImg} width="120" height="31" alt="App Store" class="confirmation__social-icon" />
                                                    </a>
                                                    <a href="https://play.google.com/store/apps/details?id=com.scank.smartapps" class="confirmation__social-link">
                                                        <img src={playStoreImg} width="110" height="31" alt="Google Play Store" class="confirmation__social-icon" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </>
                }
            </>);
    }
}

function mapStateToProps(state) {
    return {
        isGetEventDetailsByUrlSuccess: state.eventReducer.isGetEventDetailsByUrlSuccess,
        isGetEventDetailsByUrlError: state.eventReducer.isGetEventDetailsByUrlError,
        eventDetailsByUrl: state.eventReducer.eventDetailsByUrl,
        isGetEventTicketsSuccess: state.eventReducer.isGetEventTicketsSuccess,
        isGetEventTicketsError: state.eventReducer.isGetEventTicketsError,
        eventTickets: state.eventReducer.eventTickets,
        isAddItemToBasketSuccess: state.eventReducer.isAddItemToBasketSuccess,
        isAddItemToBasketError: state.eventReducer.isAddItemToBasketError,
        basket: state.eventReducer.basket,
        isGetBasketSuccess: state.eventReducer.isGetBasketSuccess,
        isGetBasketError: state.eventReducer.isGetBasketError,
        basketDetails: state.eventReducer.basketDetails,
        showBasket: state.eventReducer.showBasket,
        isClearBasketSuccess: state.eventReducer.isClearBasketSuccess,
        isClearBasketError: state.eventReducer.isClearBasketError,
        clearBasketMessage: state.eventReducer.clearBasketMessage,
        doPaymentSuccess: state.eventReducer.doPaymentSuccess,
        doPaymentError: state.eventReducer.doPaymentError,
        orderDetails: state.eventReducer.orderDetails
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getEventDetailsByUrl: (id) => dispatch(getEventDetailsByUrl(id)),
        getEventTickets: (id) => dispatch(getEventTickets(id)),
        addToBasket: (data) => dispatch(addToBasket(data)),
        getBasket: (data) => dispatch(getBasket(data)),
        clearBasket: (data) => dispatch(clearBasket(data)),
        doPayment: (data) => dispatch(doPayment(data)),
        addToSelected: (data) => dispatch(addToSelected(data))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventDetails);