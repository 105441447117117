import { GET_UPCOMING_EVENTS, GET_UPCOMING_EVENTS_SUCCESS, GET_UPCOMING_EVENTS_ERROR } from '../actions/actionTypes';
import { GET_FILTERED_EVENTS, GET_FILTERED_EVENTS_SUCCESS, GET_FILTERED_EVENTS_ERROR } from '../actions/actionTypes';
import { GET_EVENT_DETAILS, GET_EVENT_DETAILS_SUCCESS, GET_EVENT_DETAILS_ERROR } from '../actions/actionTypes';
import { GET_FAVOURITE_EVENTS, GET_FAVOURITE_EVENTS_SUCCESS, GET_FAVOURITE_EVENTS_ERROR } from '../actions/actionTypes';
import { GET_TRENDING_EVENTS, GET_TRENDING_EVENTS_SUCCESS, GET_TRENDING_EVENTS_ERROR } from '../actions/actionTypes';
import { ADD_TO_FAVOURITE, ADD_TO_FAVOURITE_SUCCESS, ADD_TO_FAVOURITE_ERROR } from '../actions/actionTypes';
import { REMOVE_FROM_FAVOURITE, REMOVE_FROM_FAVOURITE_SUCCESS, REMOVE_FROM_FAVOURITE_ERROR } from '../actions/actionTypes';
import { GET_EVENT_TICKETS, GET_EVENT_TICKETS_SUCCESS, GET_EVENT_TICKETS_ERROR } from '../actions/actionTypes';
import { ADD_ITEM_TO_BASKET, ADD_ITEM_TO_BASKET_SUCCESS, ADD_ITEM_TO_BASKET_ERROR,ITEM_TO_BASKET } from '../actions/actionTypes';
import { GET_BASKET, GET_BASKET_SUCCESS, GET_BASKET_ERROR } from '../actions/actionTypes';
import { CLEAR_BASKET, CLEAR_BASKET_SUCCESS, CLEAR_BASKET_ERROR } from '../actions/actionTypes';
import { DO_PAYMENT, DO_PAYMENT_SUCCESS, DO_PAYMENT_ERROR } from '../actions/actionTypes';
import { SEND_SMS, SEND_SMS_SUCCESS, SEND_SMS_ERROR } from '../actions/actionTypes';
import { GET_EVENT_DETAILS_BY_URL, GET_EVENT_DETAILS_BY_URL_SUCCESS, GET_EVENT_DETAILS_BY_URL_ERROR } from '../actions/actionTypes';

const initialState = {
    showProgress: false,
    showBasket: false,
    isGetUpcomingEventsSuccess: false,
    isGetUpcomingEventsError: false,
    upcomingEvents: [],
    isGeFilteredEventsSuccess: false,
    isGeFilteredEventsError: false,
    filteredEvents: [],
    isGetEventDetailsSuccess: false,
    isGetEventDetailsError: false,
    eventDetails: {},
    isGetFavouriteEventsSuccess: false,
    isGetFavouriteEventsError: false,
    favouriteEvents: [],
    isGetTrendingEventsSuccess: false,
    isGetTrendingEventsError: false,
    trendingEvents: [],
    isAddToFavouriteSuccess: false,
    isAddToFavouriteError: false,
    addedToFavourite: false,
    isRemoveFromFavouriteSuccess: false,
    isRemoveFromFavouriteError: false,
    removedFromFavourite: false,
    isGetEventTicketsSuccess: false,
    isGetEventTicketsError: false,
    eventTickets: [],
    isAddItemToBasketSuccess: false,
    isAddItemToBasketError: false,
    basket: {},
    isGetBasketSuccess: false,
    isGetBasketError: false,
    basketDetails: {},
    isClearBasketSuccess: false,
    isClearBasketError: false,
    clearBasketMessage: '',
    doPaymentSuccess: false,
    doPaymentError: false,
    orderDetails: {},
    sendSMSSuccess: false,
    sendSMSError: false,
    smsResponse: '',
    selected: [],
    isGetEventDetailsByUrlSuccess: false,
    isGetEventDetailsByUrlError: false,
    eventDetailsByUrl: {}
};

export function eventReducer(state = initialState, { type, response }) {
    switch (type) {
        case GET_UPCOMING_EVENTS:
            return {
                ...state,
                isGetUpcomingEventsSuccess: false,
                isGetUpcomingEventsError: false,
                upcomingEvents: []
            };
        case GET_UPCOMING_EVENTS_SUCCESS:
            return {
                ...state,
                isGetUpcomingEventsSuccess: true,
                isGetUpcomingEventsError: false,
                upcomingEvents: response,
            };
        case GET_UPCOMING_EVENTS_ERROR:
            return {
                ...state,
                isGetUpcomingEventsSuccess: false,
                isGetUpcomingEventsError: true,
                upcomingEvents: []
            };
        case GET_FILTERED_EVENTS:
            return {
                ...state,
                showProgress: true,
                isGeFilteredEventsSuccess: false,
                isGeFilteredEventsError: false,
                filteredEvents: []
            };
        case GET_FILTERED_EVENTS_SUCCESS:
            return {
                ...state,
                showProgress: false,
                isGeFilteredEventsSuccess: true,
                isGeFilteredEventsError: false,
                filteredEvents: response,
            };
        case GET_FILTERED_EVENTS_ERROR:
            return {
                ...state,
                showProgress: false,
                isGeFilteredEventsSuccess: false,
                isGeFilteredEventsError: true,
                filteredEvents: []
            };
        case GET_FAVOURITE_EVENTS:
            return {
                ...state,
                isGetFavouriteEventsSuccess: false,
                isGetFavouriteEventsError: false,
                favouriteEvents: []
            };
        case GET_FAVOURITE_EVENTS_SUCCESS:
            return {
                ...state,
                isGetFavouriteEventsSuccess: true,
                isGetFavouriteEventsError: false,
                favouriteEvents: response,
            };
        case GET_FAVOURITE_EVENTS_ERROR:
            return {
                ...state,
                isGetFavouriteEventsSuccess: false,
                isGetFavouriteEventsError: true,
                favouriteEvents: []
            };
        case GET_EVENT_DETAILS:
            return {
                ...state,
                isGetEventDetailsSuccess: false,
                isGetEventDetailsError: false,
                eventDetails: {}
            };
        case GET_EVENT_DETAILS_SUCCESS:
            return {
                ...state,
                isGetEventDetailsSuccess: true,
                isGetEventDetailsError: false,
                eventDetails: response,
            };
        case GET_EVENT_DETAILS_ERROR:
            return {
                ...state,
                isGetEventDetailsSuccess: false,
                isGetEventDetailsError: true,
                eventDetails: {}
            };
        case GET_TRENDING_EVENTS:
            return {
                ...state,
                isGetTrendingEventsSuccess: false,
                isGetTrendingEventsError: false,
                trendingEvents: []
            };
        case GET_TRENDING_EVENTS_SUCCESS:
            return {
                ...state,
                isGetTrendingEventsSuccess: true,
                isGetTrendingEventsError: false,
                trendingEvents: response,
            };
        case GET_TRENDING_EVENTS_ERROR:
            return {
                ...state,
                isGetTrendingEventsSuccess: false,
                isGetTrendingEventsError: true,
                trendingEvents: []
            };
        case ADD_TO_FAVOURITE:
            return {
                ...state,
                isAddToFavouriteSuccess: false,
                isAddToFavouriteError: false,
                addedToFavourite: false
            };
        case ADD_TO_FAVOURITE_SUCCESS:
            return {
                ...state,
                isAddToFavouriteSuccess: true,
                isAddToFavouriteError: false,
                addedToFavourite: response.State,
            };
        case ADD_TO_FAVOURITE_ERROR:
            return {
                ...state,
                isAddToFavouriteSuccess: false,
                isAddToFavouriteError: true,
                addedToFavourite: false
            };
        case REMOVE_FROM_FAVOURITE:
            return {
                ...state,
                isRemoveFromFavouriteSuccess: false,
                isRemoveFromFavouriteError: false,
                removedFromFavourite: false
            };
        case REMOVE_FROM_FAVOURITE_SUCCESS:
            return {
                ...state,
                isRemoveFromFavouriteSuccess: true,
                isRemoveFromFavouriteError: false,
                removedFromFavourite: response.State,
            };
        case REMOVE_FROM_FAVOURITE_ERROR:
            return {
                ...state,
                isRemoveFromFavouriteSuccess: false,
                isRemoveFromFavouriteError: true,
                removedFromFavourite: false
            };
        case GET_EVENT_TICKETS:
            return {
                ...state,
                isGetEventTicketsSuccess: false,
                isGetEventTicketsError: false,
                eventTickets: []
            };
        case GET_EVENT_TICKETS_SUCCESS:
            return {
                ...state,
                isGetEventTicketsSuccess: true,
                isGetEventTicketsError: false,
                eventTickets: response,
            };
        case GET_EVENT_TICKETS_ERROR:
            return {
                ...state,
                isGetEventTicketsSuccess: false,
                isGetEventTicketsError: true,
                eventTickets: []
            };
        case ADD_ITEM_TO_BASKET:
            return {
                ...state,
                isAddItemToBasketSuccess: false,
                isAddItemToBasketError: false,
                basket: {}
            };
            case ITEM_TO_BASKET:
                //console.log(response)
                return {
                    ...state,
                    isAddItemToBasketSuccess: false,
                    isAddItemToBasketError: false,
                    selected: response
                };
        case ADD_ITEM_TO_BASKET_SUCCESS:
            return {
                ...state,
                isAddItemToBasketSuccess: true,
                isAddItemToBasketError: false,
                basket: response,
            };
        case ADD_ITEM_TO_BASKET_ERROR:
            // console.log(response.Message[0])
            return {
                ...state,
                isAddItemToBasketSuccess: false,
                isAddItemToBasketError: true,
                basket: response?.Message?.length>0?response?.Message[0]:[]
            };
        case GET_BASKET:
            return {
                ...state,
                showBasket: true,
                isGetBasketSuccess: false,
                isGetBasketError: false,
                basketDetails: {}
            };
        case GET_BASKET_SUCCESS:
            return {
                ...state,
                showBasket: false,
                isGetBasketSuccess: true,
                isGetBasketError: false,
                basketDetails: response,
            };
        case GET_BASKET_ERROR:
            return {
                ...state,
                showBasket: false,
                isGetBasketSuccess: false,
                isGetBasketError: true,
                basketDetails: {}
            };
        case CLEAR_BASKET:
            return {
                ...state,
                isClearBasketSuccess: false,
                isClearBasketError: false,
                clearBasketMessage: ''
            };
        case CLEAR_BASKET_SUCCESS:
            return {
                ...state,
                isClearBasketSuccess: true,
                isClearBasketError: false,
                clearBasketMessage: ''
            };
        case CLEAR_BASKET_ERROR:
            return {
                ...state,
                isClearBasketSuccess: false,
                isClearBasketError: true,
                clearBasketMessage: response.Message[0]
            };
        case DO_PAYMENT:
            return {
                ...state,
                doPaymentSuccess: false,
                doPaymentError: false,
                orderDetails: {}
            };
        case DO_PAYMENT_SUCCESS:
            return {
                ...state,
                doPaymentSuccess: true,
                doPaymentError: false,
                orderDetails: response,
            };
        case DO_PAYMENT_ERROR:
            return {
                ...state,
                doPaymentSuccess: false,
                doPaymentError: true,
                orderDetails: {}
            };
        case SEND_SMS:
            return {
                ...state,
                sendSMSSuccess: false,
                sendSMSError: false,
                smsResponse: {}
            };
        case SEND_SMS_SUCCESS:
            return {
                ...state,
                sendSMSSuccess: true,
                sendSMSError: false,
                smsResponse: response,
            };
        case SEND_SMS_ERROR:
            return {
                ...state,
                sendSMSSuccess: false,
                sendSMSError: true,
                smsResponse: {}
            };


        case GET_EVENT_DETAILS_BY_URL:
            return {
                ...state,
                isGetEventDetailsByUrlSuccess: false,
                isGetEventDetailsByUrlError: false,
                eventDetailsByUrl: {}
            };
        case GET_EVENT_DETAILS_BY_URL_SUCCESS:
            return {
                ...state,
                isGetEventDetailsByUrlSuccess: true,
                isGetEventDetailsByUrlError: false,
                eventDetailsByUrl: response,
            };
        case GET_EVENT_DETAILS_BY_URL_ERROR:
            return {
                ...state,
                isGetEventDetailsByUrlSuccess: false,
                isGetEventDetailsByUrlError: true,
                eventDetailsByUrl: {}
            };


        default:
            return state;
    }
}
