import Helper from "../services/helper";
const axios = require("axios");

class CityAPI {
    static GetCities = async () => {
        return await axios.get(
            `https://scankwebapi.azurewebsites.net/api/Category/GetCityList`,
            {
                headers: Helper.authHeader(),
            }
        );
    }
}

export default CityAPI;