import { takeLatest, put, call } from "redux-saga/effects";
import { GET_SITE_CONTENT, GET_PRESS_FILE, GET_HOME_PAGE_IMG } from "../actions/actionTypes";
import { getSiteContentSuccess, getSiteContentError, getPressFileSuccess, getPressFileError, getHomePageImageSuccess, getHomePageImageError } from "../actions/actions";
import SiteContentApi from '../../apis/siteContentApi';

function* getSiteContent(params) {
    try {
        const response = yield call(SiteContentApi.GetSiteContent, params);

        const data = yield response.data;

        //console.log((data);

        if (response.statusText === "OK")
            yield put(getSiteContentSuccess(data));
        else
            yield put(getSiteContentError(data));
    } catch (error) {
        yield put(getSiteContentError(error.message));
    }
}

function* getPressFile(params) {
    try {
        const response = yield call(SiteContentApi.GetPressFile, params);

        const data = yield response.data;

        //console.log((data);

        if (response.statusText === "OK")
            yield put(getPressFileSuccess(data));
        else
            yield put(getPressFileError(data));
    } catch (error) {
        yield put(getPressFileError(error.message));
    }
}

function* getHomePageImg(params) {
    try {
        const response = yield call(SiteContentApi.GetHomePageImg, params);

        const data = yield response.data;

        //console.log((data);

        if (response.statusText === "OK")
            yield put(getHomePageImageSuccess(data));
        else
            yield put(getHomePageImageError(data));
    } catch (error) {
        yield put(getHomePageImageError(error.message));
    }
}


export default function* () {
    yield takeLatest(GET_SITE_CONTENT, getSiteContent);
    yield takeLatest(GET_PRESS_FILE, getPressFile);
    yield takeLatest(GET_HOME_PAGE_IMG, getHomePageImg);
}