import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import Helper from '../services/helper';
import Tickets from '../views/tickets';
import CardSection from "./cardSection";
import loading from "../images/loading.gif";

const axios = require("axios");

class CheckoutPaypal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: (Helper.IsAuthenticated()) ? Helper.GetCurrentUser().UserId : null,
            currentBasket: (Helper.IsAuthenticated()) ? Helper.GetCurrentBasket() : null,
            loading: false
        };
    }

    componentDidMount = () => {
        const script = document.createElement("script");

        script.src = "https://seal.starfieldtech.com/getSeal?sealID=1T8NHRnzZYG4y7B7eGpyIk3SGaRcU4VVe1kCXWHBoKnijr26ZPAXx5duy37P";
        script.async = true;

        document.body.appendChild(script);
    }

    handleSubmit = async event => {
        event.preventDefault();
        this.setState({ loading: true });

        const { stripe, elements } = this.props;

        if (!stripe || !elements) {
            this.setState({ loading: false });
            return;
        }

        let currentBasket = (Helper.IsAuthenticated()) ? await Helper.GetCurrentBasket() : null;

        //console.log("11: ", Helper.IsAuthenticated());
        //console.log("1: ", (this.state.userId));
        //console.log("3: ", JSON.parse(currentBasket));
        var objPayment = new Object();

        objPayment.UserId = this.state.userId;
        objPayment.Amount = JSON.parse(currentBasket).totalAmount;
        objPayment.PaymentMethodNonce = "paypalThroughStripe";
        objPayment.BasketId = JSON.parse(currentBasket).basketId;
        objPayment.Token = "";
        objPayment.IntentId = null;
        objPayment.TransactionId = "";
        objPayment.Region = "domestic";

        let uId = this.state.userId;

        await axios.post(
            `https://scankwebapi.azurewebsites.net/api/Payment/SetPaidBasket`,
            objPayment,
            {
                headers: Helper.authHeader(),
            }
        ).then(async function (response) {
            //console.log("done: " + JSON.stringify(response));
            //localStorage.removeItem("basket");
            //if (response.data.State) {
            //    window.location.href = window.location.origin + "/tickets/" + currentBasket.eventId + "/s";
            //}

            const paymentResponse = await response;

            // Handle server response (see Step 4)
            handleServerResponse(stripe, paymentResponse, uId, currentBasket);
        });
    };

    render() {
        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    {this.state.loading ? <div><div style={{ background: "none", height: "auto", textAlign: "center", marginBottom: "15px" }}><div className="container-fluid"><img src={loading} style={{ width: "32px", height: "32px" }} /></div></div><div style={{ textAlign: "center", fontSize: "12px", marginTop: "-10px", marginBottom: "10px" }}>Payment processing. Please wait!</div></div> : <div class="payments__form-wrap">
                        <button disabled={!this.props.stripe} class="payments__form-submit">Complete Order</button>
                    </div>
                    }
                </form>
            </div>
        );
    }
}

async function handleServerResponse(stripe, response, userId, currentBasket) {
    debugger
    if (response.error) {
        // Show error from server on payment form
        this.setState({ loading: false });
    } else if (response.data.State) {


        if (response.data.Message[0] !== "success\n") {
            // Use Stripe.js to handle the required card action
            //const { error } = await stripe.confirmPayPalPayment(
            //    response.data.Message[0],
            //    {
            //        return_url: window.location.origin + "/tickets/143"// + currentBasket.eventId
            //    }
            //);

            let curBasket = JSON.parse(currentBasket);

            stripe.confirmPayPalPayment(response.data.Message[0], {
                return_url: window.location.origin + "/payment-response/" + curBasket.eventId + "/" + curBasket.totalAmount + "/" + curBasket.basketId
            });
        }
        else {
            localStorage.removeItem("basket");
            if (response.data.State) {
                window.location.href = window.location.origin + "/tickets/" + currentBasket.eventId + "/s";
            }
        }
    } else {
        // Show success message
    }
}

export default function InjectedCheckoutPaypal() {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <CheckoutPaypal stripe={stripe} elements={elements} />
            )}
        </ElementsConsumer>
    );
}