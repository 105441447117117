import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./redux/store/configureStore";
import Routing from "./Router/Routing";
import Notifications from 'react-notify-toast';
const store = configureStore(window.__State__);

function App() {
  return (
    <div className="App">
       <Notifications options={{ zIndex: 9999999, top: '0px' }} />
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Routing />
          </Switch>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
