import { GET_SITE_CONTENT, GET_SITE_CONTENT_SUCCESS, GET_SITE_CONTENT_ERROR, GET_IS_MODAL_OPEN } from "../actions/actionTypes";
import { GET_PRESS_FILE, GET_PRESS_FILE_SUCCESS, GET_PRESS_FILE_ERROR } from "../actions/actionTypes";
import { GET_HOME_PAGE_IMG, GET_HOME_PAGE_IMG_SUCCESS, GET_HOME_PAGE_IMG_ERROR } from "../actions/actionTypes";

const initialState = {
    isGetSiteContentSuccess: false,
    isGetSiteContentError: false,
    siteContent: '',
    isGetPressFileSuccess: false,
    isGetPressFileError: false,
    pressFile: '',
    isGetHomePageImgSuccess: false,
    isGetHomePageImgError: false,
    homePageImage: '',
    isModalOpen:false
};

export function siteContentReducer(state = initialState, { type, response }) {
    switch (type) {
        case GET_SITE_CONTENT:
            return {
                ...state,
                isGetSiteContentSuccess: false,
                isGetSiteContentError: false,
                siteContent: ''
            };
        case GET_SITE_CONTENT_SUCCESS:
            return {
                ...state,
                isGetSiteContentSuccess: true,
                isGetSiteContentError: false,
                siteContent: response.Content,
            };
        case GET_SITE_CONTENT_ERROR:
            return {
                ...state,
                isGetSiteContentSuccess: false,
                isGetSiteContentError: true,
                siteContent: ''
            };
        case GET_PRESS_FILE:
            return {
                ...state,
                isGetPressFileSuccess: false,
                isGetPressFileError: false,
                pressFile: ''
            };
        case GET_PRESS_FILE_SUCCESS:
            return {
                ...state,
                isGetPressFileSuccess: true,
                isGetPressFileError: false,
                pressFile: response.Content,
            };
        case GET_PRESS_FILE_ERROR:
            return {
                ...state,
                isGetPressFileSuccess: false,
                isGetPressFileError: true,
                pressFile: ''
            };
        case GET_HOME_PAGE_IMG:
            return {
                ...state,
                isGetHomePageImgSuccess: false,
                isGetHomePageImgError: false,
                homePageImage: ''
            };
        case GET_HOME_PAGE_IMG_SUCCESS:
            return {
                ...state,
                isGetHomePageImgSuccess: true,
                isGetHomePageImgError: false,
                homePageImage: response.Content,
            };
        case GET_HOME_PAGE_IMG_ERROR:
            return {
                ...state,
                isGetHomePageImgSuccess: false,
                isGetHomePageImgError: true,
                homePageImage: ''
            };
        case GET_IS_MODAL_OPEN:
            return {
                 ...state,
                isModalOpen: response,
            };
        default:
            return state;
    }
}