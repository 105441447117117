import { GET_CATEGORIES, GET_CATEGORIES_SUCCESS, GET_CATEGORIES_ERROR } from '../actions/actionTypes';

const initialState = {
    isGetCategoriesSuccess: false,
    isGetCategoriesError: false,
    categories: []
};

export function categoryReducer(state = initialState, { type, response }) {
    switch (type) {
        case GET_CATEGORIES:
            return {
                ...state,
                isGetCategoriesSuccess: false,
                isGetCategoriesError: false,
                categories: []
            };
        case GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                isGetCategoriesSuccess: true,
                isGetCategoriesError: false,
                categories: response,
            };
        case GET_CATEGORIES_ERROR:
            return {
                ...state,
                isGetCategoriesSuccess: false,
                isGetCategoriesError: true,
                categories: []
            };
        default:
            return state;
    }
}
