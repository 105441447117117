import React from 'react';
import PublicRoutes from './publicRoutes';

function Routing() {
  return (
    <>
      {/* Render public routes */}
      <PublicRoutes />
    </>
  );
}

export default Routing;