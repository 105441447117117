import React, { Component } from "react";
import Helper from "../../services/helper";
import landingImg from "../../images/code_page.jpg";
import landingVideo from "../../images/video/braidedGirl.mp4";
import scank from "../../images/scank.png";
import comingSoon from "../../images/coming_soon.png";
import firstArEvent from "../../images/first_AR_event.png";
import icon from "../../images/icon.png";
import Whatsapp from "../../images/WHATSAPP.png";
import Emoji from "../../images/emoji.png";
import Beta_testing from "../../images/beta_testing.png";
import Connect_with_us from "../../images/connect_with_us.png";
import layer_7 from "../../images/layer_7.png";
import Website_preiview from "../../images/video/website_preiview.mp4";

class Landing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            code: '',
            windowHeight: window.innerHeight,
            pgCode: (this.props.match.params["f"] === undefined) ? "0" : this.props.match.params["f"],
        }
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        if (window.location.pathname.toLowerCase() === "/getin/890185") {
            localStorage.setItem("code", "890185");
            window.location.href = "/home";
        }
        else {
            this.updateDimensions();
            window.addEventListener('resize', this.handleResize);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({ windowHeight: window.innerHeight });
    }

    updateDimensions = () => {
        this.setState({ windowHeight: window.innerHeight });
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        });
    }

    handleEnter = (e) => {
        if (e.key === "Enter")
            this.handleGo();
    };

    handleGo = () => {
        const { code } = this.state;

        if (code === "") {
            alert("Please enter code");
            return;
        }

        if (code === "890185") {
            this.setState({ code: "" });

            localStorage.setItem("code", this.state.code);
            this.props.history.push("/home");

            //this.props.history.push({
            //    pathname: "/home",
            //    state: {
            //        code: code
            //    },
            //});
        }
        else {
            alert("Invalid code");
            return;
        }
    }

    render() {

        return (
            <div className="min-100" style={{ overflow: "hidden" }} >
                <div className="row  fill min-100 align-items-center">
                    <div className="col-lg-8" style={{ padding: "0px", }}>
                        <video autoPlay loop muted playsInline src={landingVideo} className="col-md-12" style={{ height: this.state.windowHeight, paddingLeft: "0px", paddingRight: "0px", objectFit: "cover" }}></video>
                    </div>
                    <div className="top-section">
                        <div className="col-lg-4 col-12">
                            <div style={{ paddingTop: "0%" }}>
                            </div>
                            <center>
                                <img src={scank} style={{ width: "50%" }} />
                            </center>
                            <div className="col-xl-12 min-100" style={{ padding: "0px" }}>
                                <div class="basic-form p-6" >
                                    <br />
                                    <form >
                                        <div class="form-group" style={{ marginBottom: "0px" }}>
                                            <div style={{ textAlign: "center", }}>
                                                <div style={{ paddingTop: "2%" }}>
                                                    <center>
                                                        <img src={firstArEvent} style={{ width: "50%" }} />
                                                    </center>
                                                </div>
                                                {/* <div style={{ paddingTop: "15%" }}>
                                                    Enter Code:&nbsp;
                                                    <input
                                                        type="text"
                                                        id="code"
                                                        name="code"
                                                        className="contacts__follow-email"
                                                        placeholder=""
                                                        style={{ width: "150px", color: "#000", padding: "5px" }}
                                                        aria-label="Code"
                                                        value={this.state.code} onChange={this.handleChange}
                                                        onKeyDown={(e) => {
                                                            this.handleEnter(e);
                                                        }}
                                                    />
                                                    &nbsp;&nbsp;<button class="upcoming__btn-optional_all" style={{ padding: "7px", borderRadius: "7px" }} type="button" aria-label="All" onClick={this.handleGo}>
                                                        Go
                                                    </button>
                                                </div>*/}
                                                <div style={{ paddingTop: "6%" }}>
                                                    <center>
                                                        <img src={comingSoon} style={{ width: "35%" }} />
                                                    </center>
                                                </div>
                                                <center>
                                                    <video autoPlay loop muted playsInline controls src={Website_preiview} style={{ width: "90%", marginTop: "4%", marginLeft: "2%" }}>
                                                    </video>
                                                </center>
                                                <div style={{ paddingTop: "4%" }}>
                                                    <center>
                                                        <a href="https://wa.me/+447379296347" target="_blank">
                                                            <div className="ctaDiv">
                                                                <div className="ctaleft">
                                                                    <img src={Whatsapp} style={{ width: "50%", marginLeft: "30%" }} />
                                                                </div>
                                                                <div className="ctaright">
                                                                    <div class="row">
                                                                        <img src={Beta_testing} className="betaTesting" />
                                                                    </div>
                                                                    <div class="row">
                                                                        <img src={Connect_with_us} className="connectUs" />

                                                                        <img src={Emoji} className="emoji" />
                                                                    </div>
                                                                    <div class="row">
                                                                        <img src={layer_7} style={{ width: "50%" }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </center>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div style={{ position: "fixed", bottom: 0, right: 0, padding: "0px", display: "flex", justifyContent: "end" }}>
                            <img src={icon} style={{ width: "15%" }} />
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default Landing;