import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Slider from "react-slick";
import CheckoutForm from '../../stripe/checkoutForm';
import CheckoutPaypal from '../../stripe/checkoutPaypal';
import timeIcon from "../../images/time.png";
import calendarIcon from "../../images/calendar_ic.svg";
import locationIcon from "../../images/location-pin.svg";
import ticketIcon from "../../images/ticket-icon.svg";
import entIcon from "../../images/order-intertaiment-image.jpg";
import attractionsIcon from "../../images/order-attractions-icons.png";
import restrictionIcon from "../../images/order-restrictions-icons.png";
import closeIcon from "../../images/close.svg";
import loading from "../../images/loading.gif";
import cardsIcon from "../../images/cards.png";
import paypalIcon from "../../images/paypal.png";
import apple from "../../images/ApplePay.png";
import google from "../../images/GooglePay.png";
import orderLogo from "../../images/order-modal-logo.png";
import sitelogo from "../../images/site-logo.png";
import closeWhite from "../../images/close_white.png";
import okImg from "../../images/ok.png";
import appStoreImg from "../../images/app-store.svg";
import playStoreImg from "../../images/google-play-store.svg";
import secureIcon from "../../images/organize-app.png";
import shopingBag from "../../images/shopping-bag.png";
import Helper from '../../services/helper';
import { PayPalButton } from "react-paypal-button-v2";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { getEventDetails, getEventTickets, addToBasket, getBasket, clearBasket, doPayment, addToSelected } from "../../redux/actions/actions";
import PaymentSection from '../../stripe/paymentSection';

const axios = require("axios");

class Tickets extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userId: (Helper.IsAuthenticated()) ? Helper.GetCurrentUser().UserId : null,
            eventId: (this.props.match.params["eid"] === undefined) ? "0" : this.props.match.params["eid"],
            paymentSuccess: (this.props.match.params["p"] === undefined) ? false : true,
            mapLocation: '',
            showProgress: true,
            loadTickets: true,
            tickets: [],
            timer: '10:00',
            showPayment: false,
            showPaymentSuccess: false,
            stripePubKey: '',
            payPalPubKey: '',
            isLoadingPayment: false,
            orderConfirmProgress: false,
            isCollapsed: false,
            applePaySelected: false,
            googlePaySelected: false,
            creditCardSelected: false,
            paymentOptions: null,
            showPaymentLoader: false,
        };
        //loadStripe(stripePubk)
        // const [isLoadingPayment, setIsLoadingPayment] = useState(false);
    }


    componentDidMount() {
        if (!Helper.IsValidated()) {
            this.props.history.push("/");
        }

        this.getStripePubk();
        this.getPayplPubk();
        this.getDetails();
        this.getTickets();

        if (this.state.paymentSuccess) {
            this.setState({ showPaymentSuccess: true });
        }
    }

    updateCountDown = (time) => {
        let minutes; let seconds;

        minutes = parseInt(time / 60, 10)
        seconds = parseInt(time % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        let t = minutes + ":" + seconds;

        if (time === 0)
            this.setState({ timer: '10:00' });
        else
            this.setState({ timer: t });
    }

    startTimer = (time) => {
        let _this = this;

        let interval = setInterval(() => {
            if (--time < 0) {
                _this.stop(interval);
                _this.props.history.push('/events');
            }
            else {
                _this.setState({ intr: interval });
                _this.updateCountDown(time);
            }
        }, 1000);
    }

    stop = (interval) => {
        this.updateCountDown(0);  // This line sets time to 0
        clearInterval(interval);
    }

    componentDidUpdate(prevProps, prevState) {
        const { isGetEventDetailsSuccess: prevIsGetEventDetailsSuccess, isGetEventTicketsSuccess: prevIsGetEventTicketsSuccess, isAddItemToBasketSuccess: prevIsAddItemToBasketSuccess, isAddItemToBasketError: prevIsAddItemToBasketError, isClearBasketSuccess: prevIsClearBasketSuccess, isGetBasketSuccess: prevIsGetBasketSuccess } = prevProps;
        const { isGetEventDetailsSuccess, eventDetails, isGetEventTicketsSuccess, eventTickets, isAddItemToBasketSuccess, basket, isAddItemToBasketError, clearBasket, isClearBasketSuccess, isGetBasketSuccess, basketDetails } = this.props;

        if (prevIsGetEventDetailsSuccess === false && isGetEventDetailsSuccess) {
            let coord = eventDetails.MapLat + "," + eventDetails.MapLng;

            let mapURL = 'https://maps.google.com/maps?q=' + coord + '&t=&z=14&ie=UTF8&iwloc=&output=embed';

            this.setState({ mapLocation: mapURL, showProgress: false });
        }



        if (prevIsGetEventTicketsSuccess === false && isGetEventTicketsSuccess) {
            let tickets = [];

            eventTickets.map((tkt) => {
                tickets.push({
                    TicketId: tkt.Id,
                    Title: tkt.Title,
                    StartTime: tkt.StartTime,
                    EndTime: tkt.EndTime,
                    Currency: tkt.Currency,
                    MaxQtyPerUser: tkt.MaxQtyPerUser,
                    AvaibleTicket: tkt.AvaibleTicket,
                    Price: parseFloat(tkt.Price).toFixed(2),
                    Quantity: 0,
                    TotalPrice: 0
                })
            });

            this.setState({ loadTickets: false, tickets: tickets }, function () {
                //this.startTimer();
            });
        }

        if (prevIsAddItemToBasketSuccess === false && isAddItemToBasketSuccess) {
            this.getBasketDetails();

            //this.setState({ showPayment: true });
        }

        if (prevIsAddItemToBasketError === false && isAddItemToBasketError) {
            if (basket === 'Sepette başka bir evente ait bilet var') {
                var objUser = new Object();

                objUser.userId = this.state.userId;

                clearBasket(objUser);
            }
        }

        if (prevIsClearBasketSuccess === false && isClearBasketSuccess) {
            this.handleGetTickets();
        }

        if (prevIsGetBasketSuccess === false && isGetBasketSuccess) {
            let basket = { eventId: this.state.eventId, basketId: basketDetails.BasketId, totalAmount: basketDetails.TotalPrice };
            Helper.saveObject("basket", JSON.stringify(basket));

            let currentBasket = (Helper.IsAuthenticated()) ? Helper.GetCurrentBasket() : null;

            this.setState({
                showPayment: true,
                showPaymentLoader: false,
                paymentOptions: {
                    mode: 'payment',
                    currency: 'gbp',
                    amount: JSON.parse(currentBasket).totalAmount * 100,
                }
            })
        }
    }

    getDetails = async () => {
        const { userId, eventId } = this.state;
        const { getEventDetails } = this.props;

        var objEvent = new Object();

        objEvent.id = eventId;
        objEvent.userId = userId;

        await getEventDetails(objEvent);
    }

    getTickets = async () => {
        const { eventId } = this.state;
        const { getEventTickets } = this.props;

        await getEventTickets(eventId);
    }

    updateQty = async (ticket, mode) => {
        let allTickets = this.state.tickets;
        const { addToSelected } = this.props;

        if (mode === "plus") {
            if (new Date() >= new Date(ticket.StartTime)) {
                let id = ticket.TicketId;
                let qty = parseInt(ticket.Quantity) + 1;
                let total = parseFloat(ticket.Price) * qty;

                if (qty === 1) {
                    this.startTimer(600);
                }

                for (var i in allTickets) {
                    if (allTickets[i].TicketId == id) {
                        allTickets[i].Quantity = qty;
                        allTickets[i].TotalPrice = total;
                        break;
                    }
                }
            }
            else {
                let sdt = Helper.getDayFromDate(ticket.StartTime) + " " + Helper.formatDate(ticket.StartTime) + " " + Helper.getYearFromDate(ticket.StartTime) + " " + Helper.formatTime(ticket.StartTime);

                Helper.toast("Sorry, event tickets will be available from " + sdt, "warning")
                return;
            }
        }
        else {
            let id = ticket.TicketId;
            let qty = parseInt(ticket.Quantity) - 1;
            let total = parseFloat(ticket.Price) * qty;

            if (qty === 0) {
                this.stop(this.state.intr);
            }

            for (var i in allTickets) {
                if (allTickets[i].TicketId == id) {
                    allTickets[i].Quantity = qty;
                    allTickets[i].TotalPrice = total;
                    break;
                }
            }
        }
        await addToSelected(allTickets)
        this.setState({ tickets: allTickets });
    }

    handleGetTickets = async () => {
        const { userId, tickets } = this.state;
        const { addToBasket } = this.props;

        this.setState({ showPaymentLoader: true });

        let totalZeroQty = 0;

        if (userId == null) {
            // alert('Please login to continue.');
            Helper.toast("OOPs Please login to continue.", "warning")
            document.getElementById("navSignIn").click();

            setTimeout(() => {
                document.getElementById("divSignIn").click();
            }, 200);

            //document.getElementById("divSignIn").click();
            return;
        }

        let missedTickets = 0;
        var objTicket = new Object();

        objTicket.UserId = userId;
        objTicket.List = tickets;

        for (var i in tickets) {
            if (new Date() > new Date(tickets[i].EndTime)) {
                missedTickets++;
            }
        }

        if (missedTickets == tickets.length) {
            Helper.toast("Missed event!", "warning")
            return;
        }

        for (var i in tickets) {
            if (tickets[i].Quantity > 0) {
                if (tickets[i].AvaibleTicket <= 0) {
                    Helper.toast("Sorry, " + tickets[i].Title + " tickets are sold out!", "warning")
                    return;
                }
                else if (tickets[i].Quantity > tickets[i].AvaibleTicket) {
                    Helper.toast("Sorry, only " + tickets[i].AvaibleTicket + " " + tickets[i].Title + " tickets remaining!", "warning")
                    return;
                }
                else if (new Date() > new Date(tickets[i].EndTime)) {
                    Helper.toast("Sorry, " + tickets[i].Title + " tickets are sold out!", "warning")
                    return;
                }
            }
            else if (tickets[i].Quantity == 0) {
                totalZeroQty++;

            }
        }

        if (totalZeroQty === tickets.length) {
            Helper.toast("Please select valid quantity", "warning")
            return;
        }
        else {
            await addToBasket(objTicket);
        }
    }

    getBasketDetails = async () => {
        const { userId } = this.state;
        const { getBasket } = this.props;

        var objBasket = new Object();

        objBasket.userId = userId;
        objBasket.voucherCode = '';

        await getBasket(objBasket);
    }

    handleClosePayment = () => {
        this.setState({ showPayment: false });
    }

    handleShowPaymentSuccess = () => {
        this.setState({ showPayment: false, showPaymentSuccess: true });
    }

    handleClosePaymentSuccess = () => {
        this.setState({ showPaymentSuccess: false }, function () {
            this.props.history.push("/");
        });
    }

    createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: "0.01",
                    },
                },
            ],
        });
    }

    onApprove = (data, actions) => {
        return actions.order.capture();

    }

    getStripePubk = async () => {
        await axios.get("https://scankwebapi.azurewebsites.net/api/Payment/Stripe/PublicKey").then(async (response) => {

            const stripePromise = await loadStripe(response.data)

            this.setState({ stripePubKey: stripePromise });
        });
    }

    getPayplPubk = async () => {
        await axios.get("https://scankwebapi.azurewebsites.net/api/Payment/Paypal/PublicKey").then(response => {
            //console.log(('ppl ky:' + response.data);
            this.setState({ payPalPubKey: response.data });

            const script = document.createElement("script");

            script.src = "https://www.paypal.com/sdk/js?client-id=" + response.data + "&currency=GBP&disable-funding=credit,card";
            script.async = true;

            document.body.appendChild(script);

        });
    }

    completeOrder = async () => {
        this.setState({ orderConfirmProgress: true });
        var objPayment = new Object();

        let eventId = this.state.eventId;

        objPayment.UserId = this.state.userId;
        objPayment.Amount = 0;
        objPayment.PaymentMethodNonce = "card";
        objPayment.BasketId = this.props.basketDetails.BasketId;
        objPayment.Token = "";
        objPayment.IntentId = null;
        objPayment.TransactionId = "";
        objPayment.Region = "";

        await axios.post(
            `https://scankwebapi.azurewebsites.net/api/Payment/SetPaidBasket`,
            objPayment,
            {
                headers: Helper.authHeader(),
            }
        ).then(async function (response) {
            //console.log("done: " + JSON.stringify(response));
            //localStorage.removeItem("basket");
            //if (response.data.State) {
            //    window.location.href = window.location.origin + "/tickets/" + currentBasket.eventId + "/s";
            //}

            // Handle server response (see Step 4)
            if (response.data.Message[0] === "0 Amount") {
                localStorage.removeItem("basket");
                if (response.data.State) {
                    window.location.href = window.location.origin + "/tickets/" + eventId + "/s";
                }
            }
        });
    }
    handleApplePayChange = () => {
        this.setState({
            applePaySelected: true,
            googlePaySelected: false,
            creditCardSelected: false
        });
    };

    handleGooglePayChange = () => {
        this.setState({
            applePaySelected: false,
            googlePaySelected: true,
            creditCardSelected: false
        });
    };

    handleCreditCardChange = () => {
        this.setState({
            applePaySelected: false,
            googlePaySelected: false,
            creditCardSelected: true
        });
    };
    getMobileOperatingSystem = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Detect iOS
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return 'iOS';
        }

        // Detect Android
        if (/android/i.test(userAgent)) {
            return 'Android';
        }

        // Other mobile OSes can be added here
        return 'unknown';
    };

    toggleCollapse = () => {
        this.setState(prevState => ({
            isCollapsed: !prevState.isCollapsed
        }));
    }
    render() {
        const { userId, timer, mapLocation, showProgress, loadTickets, tickets, showPayment, showPaymentSuccess, stripePubKey, payPalPubKey, isCollapsed } = this.state;
        const { eventDetails, eventTickets, basketDetails, showBasket, doPayment } = this.props;
        const { isLoadingPayment, showPaymentLoader } = this.state;
        // const isModalOpen = localStorage.getItem('isModalOpen')
        //console.log("eventTickets: ", eventTickets);
        //console.log((eventDetails.CoverPictures) ? eventDetails.CoverPictures.length : '--');
        const onSuccess = async (payment) => {
            //console.log(("Your payment was succeeded!", payment);

            //var objPayment = new Object();

            //objPayment.UserId = userId;
            //objPayment.Amount = basketDetails.TotalPrice;
            //objPayment.PaymentMethodNonce = "paypal";
            //objPayment.BasketId = basketDetails.BasketId;
            //objPayment.Token = payment.token;
            //objPayment.TransactionId = payment.id;
            //objPayment.Region = "";

            //await doPayment(objPayment);
        }
        const onCancel = (data) => {
            // User pressed "cancel" or close Paypal's popup! 
            //console.log(('You have cancelled the payment!', data);
        }
        const onError = (err) => {
            // The main Paypal's script cannot be loaded or somethings block the loading of that script! 
            console.log("Error!", err);
            // Since the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js" 
            // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear			 
        }
        let isSelected = tickets.some(elem => elem.Quantity > 0)
        let currency = 'USD'; // or you can set this value from your props or state   
        let total = 1; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout 




        return (
            <>
                {

                    showProgress ?
                        <div className="order"><div className="order__title-bg-clear" style={{ background: "none", minHeight: "370px", height: "auto" }}><div className="container-fluid"><img src={loading} style={{ width: "32px", height: "32px" }} /></div></div></div>
                        :
                        <>
                            <div className="order">
                                <div style={{ display: "none" }}>
                                    <div className="backgroundImage" style={{ backgroundImage: `url(${eventDetails.CoverPictures[0]})`, filter: "blur(5px)", WebkitFilter: "blur(5px)", zIndex: "1" }}>
                                    </div>
                                    <div className="content">
                                        <h1 className="order__title title">{eventDetails.Title}</h1>
                                    </div>
                                </div>

                                <div class="order__title-bg" style={{ backgroundImage: `url(${eventDetails.CoverPictures[0]})`, display: 'none' }}>
                                    <div class="container-fluid">
                                        <h1 class="order__title title">{eventDetails.Title}</h1>
                                    </div>
                                </div>

                                <div class="order__title-bg" style={{ backgroundImage: `url(${eventDetails.CoverPictures[0]})` }}>
                                </div>
                                <div style={{ textAlign: "center", height: "0px" }}>
                                    <h1 class="order__title title" style={{ marginTop: "-290px" }}>{eventDetails.Title}</h1>
                                </div>
                                <div className="order__tickets-reserved-min">
                                    <div className="order__tickets-time">
                                        <div className="order__tickets-description">
                                            <img src={timeIcon} alt="Timer" className="order__tickets-timer" />
                                            <span className="order__tickets-text">
                                                Tickets will be reserved for <span className="order__tickets-time">{timer}</span> Minutes
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid">
                                    <div className="order__tickets-reserved">

                                        {isLoadingPayment ? <div className="loadingOverlay">

                                            <img src={loading} style={{ width: "32px", height: "32px" }} />
                                            <h3 style={{ color: "#fff" }}>Your payment is being processed. Please wait!</h3>
                                        </div> : ''}


                                        <ul className="order__tickets-date list">
                                            <li className="order__date-item">
                                                <img src={calendarIcon} width="30" height="30" alt="icon" className="order__date-image" />
                                                <p className="order__date-text">
                                                    {Helper.getDayFromDate(eventDetails.StartDate)}{" "}{Helper.formatDate(eventDetails.StartDate)}{" "}{Helper.getYearFromDate(eventDetails.StartDate)}
                                                </p>
                                                <p className="order__date-time">
                                                    {Helper.formatTime(eventDetails.StartDate)}
                                                </p>
                                            </li>
                                            <li className="order__date-item">
                                                <img src={locationIcon} width="20" height="30" alt="icon" className="order__date-image" />
                                                <p className="order__date-text">
                                                    {(eventDetails.Venue) ? eventDetails.Venue + "," : "-"}
                                                </p>
                                                <p className="order__date-time">
                                                    {(eventDetails.City) ? eventDetails.City + "," : ""}{" "}{(eventDetails.Postcode) ? eventDetails.Postcode : ""}
                                                </p>
                                            </li>
                                            <li className="order__date-item">
                                                <img src={ticketIcon} width="32" height="32" alt="icon" className="order__date-image" />
                                                <p className="order__date-text">
                                                    Tickets
                                                </p>
                                                <p className="order__date-time">
                                                    From {eventDetails.Currency}{parseFloat(eventDetails.Price).toFixed(2)}
                                                </p>
                                            </li>
                                        </ul>
                                        <div className="order__ticket-description" dangerouslySetInnerHTML={{ __html: eventDetails.Description }}>
                                        </div>
                                    </div>
                                    <div className="order__tickets">
                                        <h4 className="order__tickets-title">
                                            Tickets
                                        </h4>
                                        <div className="order__tickets-wrapper">
                                            <div className="order__tickets-box">
                                                {loadTickets ?
                                                    <ul className="order__tikets-blank list">
                                                        <li className="order__blank-item active">
                                                            <img src={loading} style={{ width: "32px", height: "32px" }} />{" "}
                                                        </li>
                                                    </ul> :
                                                    <ul className="order__tikets-blank list">
                                                        {tickets.map((tkt) => (
                                                            <li className="order__blank-item active">
                                                                <span className="order__blank-number">
                                                                    {tkt.Quantity}
                                                                </span>
                                                                <p className="order__blank-date">
                                                                    <span className="order__blank-name">{tkt.Title}</span>
                                                                    <span className="order__blank-datetime">{tkt.Title}: {tkt.Currency}{tkt.Price}</span>
                                                                    {/* <span className="order__blank-datetime">{tkt.StartTime} - {tkt.EndTime}</span> */}

                                                                    <span className="order__blank-price">x {tkt.Currency}{tkt.Price} = <span>{tkt.Currency}{parseFloat(tkt.TotalPrice).toFixed(2)}</span></span>
                                                                </p>
                                                                <div className="order__blank-button">
                                                                    {tkt.Quantity === tkt.MaxQtyPerUser ?
                                                                        <button className="order__blank-btn order__blank-btn_plus" disabled style={{
                                                                            opacity: '0.4', pointerEvents: "none", backgroundColor: "#b8b8b8", color: "#fff"
                                                                        }}>
                                                                            +
                                                                        </button>
                                                                        :
                                                                        <button className="order__blank-btn order__blank-btn_plus" onClick={() => this.updateQty(tkt, 'plus')}>
                                                                            +
                                                                        </button>
                                                                    }
                                                                    {tkt.Quantity === 0 ?
                                                                        <button className="order__blank-btn order__blank-btn_minus" disabled style={{ opacity: '0.4', pointerEvents: "none", backgroundColor: "#b8b8b8", color: "#fff" }}>
                                                                            -
                                                                        </button>
                                                                        :
                                                                        <button className="order__blank-btn order__blank-btn_minus" onClick={() => this.updateQty(tkt, 'minus')}>
                                                                            -
                                                                        </button>
                                                                    }
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                }
                                                {/* {(!showPayment && isSelected && !window.matchMedia('(max-width: 600px)').matches) ? (
                                                    <div className="tickets-btn">
                                                        <button className="order__gettickets-btn" onClick={this.handleGetTickets}>
                                                            Get Tickets
                                                        </button>
                                                    </div>
                                                ) : null} */}

                                                {!showPayment && <div className="tickets-btn" style={{ textAlign: "center", display: `${(!isSelected && window.matchMedia('(max-width: 600px)').matches)|| (this.props.isModalOpen )? 'none' : ''}` }} >
                                                    {showPaymentLoader ? <>
                                                        <img src={loading} style={{ width: "32px", height: "32px" }} />
                                                        <h3 style={{ color: "#fff" }}>Please wait...</h3>
                                                    </> :
                                                    
                                                        <button className="order__gettickets-btn" style={{ display: `${(!isSelected && window.matchMedia('(max-width: 600px)').matches) || (this.props.isModalOpen )? 'none' : ''}` }} type="button" data-toggle="modal" onClick={this.handleGetTickets}>
                                                            Get Tickets
                                                        </button>
                                                    }
                                                </div>}
                                            </div>

                                            <div className="order__entertainment-box">
                                                <div className="order__entertainment-wrap">
                                                    <Slider slidesToShow={1}
                                                        slidesToScroll={1}
                                                        infinite={true}
                                                        autoplay={true}
                                                        arrows={true}
                                                        speed={3000}>
                                                        {eventDetails.CoverPictures.map((f) => (
                                                            <img src={f} alt="cover picture" class="order__entertainment-image" />
                                                        ))}
                                                    </Slider>
                                                    <h4 className="order__entertainment-title">
                                                        Attractions
                                                    </h4>
                                                    {eventDetails.Features.map((item, index) => (
                                                        <img src={item} key={index} alt="icon" style={{ height: "60px", marginLeft: "10px" }} className="order__entertainment-icon" />
                                                    ))}
                                                    <h4 className="order__entertainment-title">
                                                        Restrictions
                                                    </h4>
                                                    {eventDetails.RestrictionList.map((item, index) => (
                                                        <img src={item.Picture} key={index} alt="icon" style={{ height: "60px", marginLeft: "10px" }} className="order__entertainment-icon" />
                                                    ))}
                                                    <h4 className="order__entertainment-title">
                                                        Get There
                                                    </h4>
                                                    <iframe width="100%" height="210" src={mapLocation} frameBorder="1" scrolling="no" marginHeight="0" marginWidth="0" style={{ border: "2px solid #adadad", borderRadius: "5px" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Modal show={showPayment} className="payment">
                                <Modal.Body>
                                    <div class="modal-content-body">
                                        {basketDetails.TotalPrice === null || basketDetails.TotalPrice === undefined ?
                                            <div class="payments__wrapper" style={{ minHeight: "500px" }}>
                                                <div style={{ width: "29%" }}>
                                                    &nbsp;
                                                </div>
                                                <div style={{ marginTop: "250px" }}>
                                                    <img src={loading} style={{ width: "32px", height: "32px" }} />
                                                </div>
                                                <div style={{ width: "29%" }}>
                                                    &nbsp;
                                                </div>
                                            </div>
                                            :
                                            <>
                                                {parseFloat(basketDetails.TotalPrice) === 0 ?
                                                    <div class="payments__wrapper">
                                                        <div style={{ width: "29%" }}>
                                                            &nbsp;
                                                        </div>
                                                        <div class="payments__data">
                                                            {!basketDetails.hasOwnProperty('EventTitle') ? null :
                                                                <>
                                                                    <div class="payments__data-wrapper">
                                                                        <div class="payments__logo-wrapper">
                                                                            <img src={sitelogo} alt="SCANK" class="payments__data-logo" />
                                                                        </div>
                                                                        <h3 class="payments__data-title">Your Ticket</h3>
                                                                        <h3 class="payments__data-name">{basketDetails.EventTitle}</h3>
                                                                        <div class="payments__data-row">
                                                                            <span class="payments__data-part">Date </span>
                                                                            <p class="payments__data-value">{Helper.getDayFromDate(basketDetails.StartDate)} / {Helper.formatDate(basketDetails.StartDate)} / {Helper.getYearFromDate(basketDetails.StartDate)}</p>
                                                                        </div>
                                                                        <div class="payments__data-row">
                                                                            <span class="payments__data-part">Time</span>
                                                                            <p class="payments__data-value">{Helper.formatTime(basketDetails.StartDate)}</p>
                                                                        </div>
                                                                        <div class="payments__data-row">
                                                                            <span class="payments__data-part">Location </span>
                                                                            <p class="payments__data-value">{basketDetails.Location}</p>
                                                                        </div>
                                                                        <div class="payments__data-row">
                                                                            <span class="payments__data-part">item </span>
                                                                            {basketDetails.Items.map((itm) => (
                                                                                <p class="payments__data-value">x {itm.Quantity} {itm.Title} : £{parseFloat(itm.Price).toFixed(2)}</p>
                                                                            ))}
                                                                        </div>
                                                                        <div class="payments__data-discount">
                                                                            <input type="text" placeholder="Enter Discount Code" class="payments__discount-field" />
                                                                            <button type="button" class="payments__discount-butt">Apply</button>
                                                                        </div>

                                                                        <div class="payments__data-sub">
                                                                            <p class="payments__data-common">Sub Total: £{parseFloat(basketDetails.SubTotal).toFixed(2)}</p>
                                                                            <p class="payments__data-common">Booking fee: £{parseFloat(basketDetails.BookingFee).toFixed(2)}</p>
                                                                            <p class="payments__data-common">Discount: -£{parseFloat(basketDetails.Discount).toFixed(2)}</p>
                                                                        </div>

                                                                        <div class="payments__total">
                                                                            <div class="payments__total-title">Total price</div>
                                                                            <div class="payments__total-value">£{parseFloat(basketDetails.TotalPrice).toFixed(2)}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ textAlign: "center", marginTop: "30px" }}>
                                                                        {this.state.orderConfirmProgress ?
                                                                            <>
                                                                                <img src={loading} style={{ width: "32px", height: "32px" }} />
                                                                                <div style={{ marginTop: "5px", textAlign: "center", fontSize: "12px" }}>Please wait!</div>
                                                                            </>
                                                                            :
                                                                            <button type="button" class="payments__form-submit" onClick={() => this.completeOrder()}>Confirm</button>
                                                                        }
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                        <div style={{ width: "27%" }}>&nbsp;</div>
                                                    </div>
                                                    :
                                                    <div class="payments__wrapper">
                                                        <div class="payments__form">
                                                            <div className="payment-header">
                                                                <div>
                                                                    <h2 class="payments__form-title">Select Payment Method</h2>
                                                                </div>
                                                                <div>
                                                                    <button type="button" class="close cancel-btn" data-dismiss="modal" aria-label="Close" onClick={() => this.handleClosePayment()} style={{ textAlign: "right", margin: "10px 20px 10px 0" }}>
                                                                        Cancel Order
                                                                        {/* <img src={closeIcon} width="22" height="22" alt="Close icon" onClick={() => this.handleClosePayment()} /> */}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="checkout-section">
                                                                <div class="payments__data">
                                                                    {!basketDetails.hasOwnProperty('EventTitle') ? null :
                                                                        <div class="payments__data-wrapper">
                                                                            <div class="payments__logo-wrapper">
                                                                                <img src={sitelogo} alt="SCANK" class="payments__data-logo" />
                                                                            </div>
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <div>
                                                                                    <h3 class="payments__data-title">Your Ticket</h3>
                                                                                </div>
                                                                                <div>
                                                                                    <button class="navbar-toggler" type="button" onClick={this.toggleCollapse} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={isCollapsed ? "true" : "false"} aria-label="Toggle navigation">
                                                                                        {isCollapsed ? (
                                                                                            <>
                                                                                                Show <FaAngleUp />
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                Hide <FaAngleDown />
                                                                                            </>
                                                                                        )}
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <div class="collapse navbar-collapse show" id="navbarSupportedContent">
                                                                                <h3 class="payments__data-name">{basketDetails.EventTitle}</h3>
                                                                                <div class="payments__data-row">
                                                                                    <span class="payments__data-part">Date </span>
                                                                                    <p class="payments__data-value">{Helper.getDayFromDate(basketDetails.StartDate)} / {Helper.formatDate(basketDetails.StartDate)} / {Helper.getYearFromDate(basketDetails.StartDate)}</p>
                                                                                </div>
                                                                                <div class="payments__data-row">
                                                                                    <span class="payments__data-part">Time</span>
                                                                                    <p class="payments__data-value">{Helper.formatTime(basketDetails.StartDate)}</p>
                                                                                </div>
                                                                                <div class="payments__data-row">
                                                                                    <span class="payments__data-part">Location </span>
                                                                                    <p class="payments__data-value">{basketDetails.Location}</p>
                                                                                </div>
                                                                                <div class="payments__data-row">
                                                                                    <span class="payments__data-part">item </span>
                                                                                    {basketDetails.Items.map((itm) => (
                                                                                        <p class="payments__data-value">x {itm.Quantity} {itm.Title} : £{parseFloat(itm.Price).toFixed(2)}</p>
                                                                                    ))}
                                                                                </div>
                                                                                <div class="payments__data-discount">
                                                                                    <input type="text" placeholder="Enter Discount Code" class="payments__discount-field" />
                                                                                    <button type="button" class="payments__discount-butt">Apply</button>
                                                                                </div>

                                                                                <div class="payments__data-sub">
                                                                                    <p class="payments__data-common">Sub Total: £{parseFloat(basketDetails.SubTotal).toFixed(2)}</p>
                                                                                    <p class="payments__data-common">Booking fee: £{parseFloat(basketDetails.BookingFee).toFixed(2)}</p>
                                                                                    <p class="payments__data-common">Discount: -£{parseFloat(basketDetails.Discount).toFixed(2)}</p>
                                                                                </div>

                                                                                <div class="payments__total">
                                                                                    <div class="payments__total-title">Total price</div>
                                                                                    <div class="payments__total-value">£{parseFloat(basketDetails.TotalPrice).toFixed(2)}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div class="right-checkout-sec">
                                                                    {/*<div class="payments__form-item">
                                                                        <div class="payments__form-select">
                                                                            <div class="custom-control custom-radio">
                                                                                <input type="radio" name="payments" id="credit-card" class="custom-control-input" onChange={this.handleCreditCardChange} checked={this.state.creditCardSelected} />
                                                                                <label class="custom-control-label" for="credit-card">
                                                                                    <span class="custom-control-label__text">Credit Card</span>
                                                                                </label>
                                                                            </div>
                                                                            <img src={cardsIcon} alt="cards" class="payments__form-picture" />
                                                                        </div>
                                                                        <p class="payments__form-describe">
                                                                            Safe money transfer using your bank account. Visa, Maestro, Discover, American Express.
                                                                        </p>
                                                                        <Elements stripe={stripePubKey}>
                                                                            <CheckoutForm />
                                                                        </Elements>
                                                                    </div>*/}

                                                                    {/* <div class="payments__form-item">
                                                                        <div class="payments__form-select">
                                                                            <div class="custom-control custom-radio">
                                                                                <input type="radio" name="payments" id="paypal" class="custom-control-input" />
                                                                                <label class="custom-control-label" for="paypal">
                                                                                    <span class="custom-control-label__text">PayPal</span>
                                                                                </label>
                                                                            </div>
                                                                            <img src={paypalIcon} alt="cards" class="payments__form-picture" />
                                                                        </div>
                                                                        <p class="payments__form-describe">
                                                                            You will be redirected to confirmation page to complete your purchase securely.
                                                                        </p>
                                                                        <Elements stripe={stripePubKey}>
                                                                            <CheckoutPaypal />
                                                                        </Elements>
                                                                    </div> */}

                                                                    {stripePubKey && this.state.paymentOptions && <div class="payments__form-item">

                                                                        <Elements stripe={stripePubKey} options={this.state.paymentOptions}>
                                                                            <PaymentSection />
                                                                        </Elements>
                                                                    </div>}

                                                                    {/* {this.getMobileOperatingSystem() === "iOS" && <div class="payments__form-item"> */}
                                                                        {/* <div class="payments__form-select"> */}
                                                                            {/* <div class="custom-control custom-radio"> */}
                                                                                {/* <input type="radio" name="payments" id="applePay" onChange={this.handleApplePayChange} checked={this.state.applePaySelected} class="custom-control-input" /> */}
                                                                                {/* <label class="custom-control-label" for="applePay"> */}
                                                                                    {/* <span class="custom-control-label__text">Apple Pay</span> */}
                                                                                {/* </label> */}
                                                                            {/* </div> */}
                                                                            {/* <img width={'45px'} src={apple} alt="cards" class="payments__form-picture" /> */}
                                                                        {/* </div> */}
                                                                        {/* <p class="payments__form-describe"> */}
                                                                            {/* You will be redirected to confirmation page to complete your purchase securely. */}
                                                                        {/* </p> */}
                                                                        {/* <div class="payments__form-wrap"> */}
                                                                        {/* <button   disabled={!this.state.applePaySelected} class={`payments__form-submit ${!this.state.applePaySelected?"disabled":""}`}>Complete Order</button>  </div> */}
                                                                    {/* </div>} */}
                                                                    {/* {this.getMobileOperatingSystem() ==="Android" &&   <div class="payments__form-item"> */}
                                                                        {/* <div class="payments__form-select"> */}
                                                                            {/* <div class="custom-control custom-radio"> */}
                                                                                {/* <input type="radio" name="payments" id="googlepay" class="custom-control-input"  onChange={this.handleGooglePayChange} checked={this.state.googlePaySelected} /> */}
                                                                                {/* <label class="custom-control-label" for="googlepay"> */}
                                                                                    {/* <span class="custom-control-label__text">Google Pay</span> */}
                                                                                {/* </label> */}
                                                                            {/* </div> */}
                                                                            {/* <img width={'45px'} src={google} alt="cards" class="payments__form-picture" /> */}
                                                                        {/* </div> */}
                                                                        {/* <p class="payments__form-describe"> */}
                                                                            {/* You will be redirected to confirmation page to complete your purchase securely. */}
                                                                        {/* </p> */}
                                                                        {/* <div class="payments__form-wrap"> */}
                                                                        {/* <button disabled={!this.state.googlePaySelected}  class={`payments__form-submit ${!this.state.googlePaySelected?"disabled":""}`} >Complete Order</button>  </div> */}
                                                                    {/* </div>} */}
                                                                    <div class="payments__form-item">
                                                                        <ul className="secure-head">
                                                                            <li><img src={shopingBag} alt="icon" /></li>
                                                                            <li> <p>Secure Checkout</p></li>
                                                                        </ul>
                                                                        <p>Your payment is secure and your information is protected. We use indystry standard encryption to safe-gaurd your personal and payment information.<br />
                                                                            We respect your privacy and are committed to protecting it.For more details please review our Privacy Policy.By completing your purchase you agree to our Terms and Conditions.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                </Modal.Body>
                            </Modal>
                            <Modal show={showPaymentSuccess} className="paymentSuccess confirm-modal">
                                <Modal.Body>
                                    <div class="modal-content confirmation__modal-content">
                                        <button type="button" class="close confirmation__close-btn" data-dismiss="modal" aria-label="Close" onClick={this.handleClosePaymentSuccess}>
                                            <span aria-hidden="true"><img src={closeWhite} alt="close" class="confirmation__close-icon" /></span>
                                        </button>
                                        <div class="confirmation__wrapper">
                                            <img src={okImg} alt="" class="confirmation__icon" />
                                            <h3 class="confirmation__title">Order Complete!</h3>
                                            <p class="confirmation__text">Check your email account for confirmation and a copy of your eTicket.</p><br />
                                            <p class="confirmation__text">Log into the Scank App for a copy of your eTicket</p><br />
                                            <a href="#" class="confirmation__title-text">Download the Scank App today for The Ultimate Experience!</a>

                                            <div class="confirmation__wrapper">
                                                <a href="https://apps.apple.com/gb/app/scank/id1574492173" class="confirmation__social-link">
                                                    <img src={appStoreImg} width="120" height="31" alt="App Store" class="confirmation__social-icon" />
                                                </a>
                                                <a href="https://play.google.com/store/apps/details?id=com.scank.smartapps" class="confirmation__social-link">
                                                    <img src={playStoreImg} width="110" height="31" alt="Google Play Store" class="confirmation__social-icon" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </>
                }
            </>);
    }
}

function mapStateToProps(state) {
    return {
        isGetEventDetailsSuccess: state.eventReducer.isGetEventDetailsSuccess,
        isGetEventDetailsError: state.eventReducer.isGetEventDetailsError,
        eventDetails: state.eventReducer.eventDetails,
        isGetEventTicketsSuccess: state.eventReducer.isGetEventTicketsSuccess,
        isGetEventTicketsError: state.eventReducer.isGetEventTicketsError,
        eventTickets: state.eventReducer.eventTickets,
        isAddItemToBasketSuccess: state.eventReducer.isAddItemToBasketSuccess,
        isAddItemToBasketError: state.eventReducer.isAddItemToBasketError,
        basket: state.eventReducer.basket,
        isGetBasketSuccess: state.eventReducer.isGetBasketSuccess,
        isGetBasketError: state.eventReducer.isGetBasketError,
        basketDetails: state.eventReducer.basketDetails,
        showBasket: state.eventReducer.showBasket,
        isClearBasketSuccess: state.eventReducer.isClearBasketSuccess,
        isClearBasketError: state.eventReducer.isClearBasketError,
        clearBasketMessage: state.eventReducer.clearBasketMessage,
        doPaymentSuccess: state.eventReducer.doPaymentSuccess,
        doPaymentError: state.eventReducer.doPaymentError,
        orderDetails: state.eventReducer.orderDetails,
        isModalOpen:state.siteContentReducer.isModalOpen
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getEventDetails: (id) => dispatch(getEventDetails(id)),
        getEventTickets: (id) => dispatch(getEventTickets(id)),
        addToBasket: (data) => dispatch(addToBasket(data)),
        getBasket: (data) => dispatch(getBasket(data)),
        clearBasket: (data) => dispatch(clearBasket(data)),
        doPayment: (data) => dispatch(doPayment(data)),
        addToSelected: (data) => dispatch(addToSelected(data))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);