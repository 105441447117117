import { USER_REGISTER_REQUEST, USER_REGISTER_SUCCESS, USER_REGISTER_ERROR } from '../actions/actionTypes';
import { USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGIN_ERROR } from '../actions/actionTypes';
import { FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR } from '../actions/actionTypes';
import { UPDATE_PASSWORD_REQUEST, UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_ERROR } from '../actions/actionTypes';
import { GOOGLE_LOGIN_REQUEST, GOOGLE_LOGIN_SUCCESS, GOOGLE_LOGIN_ERROR } from '../actions/actionTypes';
import { FACEBOOK_LOGIN_REQUEST, FACEBOOK_LOGIN_SUCCESS, FACEBOOK_LOGIN_ERROR } from '../actions/actionTypes';
import { RESEND_VERIFICATION_REQUEST, RESEND_VERIFICATION_SUCCESS, RESEND_VERIFICATION_ERROR } from '../actions/actionTypes';

const initialState = {
    showProgress: false,
    isRegisterSuccess: false,
    isRegisterError: false,
    userData: {},
    isLoginSuccess: false,
    isLoginError: false,
    loginData: {},
    isForgotPwdSuccess: false,
    isForgotPwdError: false,
    message: '',
    isUpdatePwdSuccess: false,
    isUpdatePwdError: false,
    updatePwdMessage: '',
    isGoogleLoginSuccess: false,
    isGoogleLoginError: false,
    isFacebookLoginSuccess: false,
    isFacebookLoginError: false,
    isResendVerificationSuccess: false,
    isResendVerificationError: false,
    resendVerificationData: {}
};

export function authReducer(state = initialState, { type, response }) {
    switch (type) {
        case USER_REGISTER_REQUEST:
            return {
                ...state,
                showProgress: true,
                isRegisterSuccess: false,
                isRegisterError: false,
                userData: {}
            };
        case USER_REGISTER_SUCCESS:
            return {
                ...state,
                showProgress: false,
                isRegisterSuccess: true,
                isRegisterError: false,
                userData: response
            };
        case USER_REGISTER_ERROR:
            return {
                ...state,
                showProgress: false,
                isRegisterSuccess: false,
                isRegisterError: true,
                userData: response.Message[0]
            };
        case USER_LOGIN_REQUEST:
            return {
                ...state,
                showProgress: true,
                isLoginSuccess: false,
                isLoginError: false,
                loginData: {}
            };
        case USER_LOGIN_SUCCESS:
            return {
                ...state,
                showProgress: false,
                isLoginSuccess: true,
                isLoginError: false,
                loginData: response
            };
        case USER_LOGIN_ERROR:
            return {
                ...state,
                showProgress: false,
                isLoginSuccess: false,
                isLoginError: true,
                loginData: response.Message[0]
            };
        case FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                showProgress: true,
                isForgotPwdSuccess: false,
                isForgotPwdError: false,
                message: ''
            };
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                showProgress: false,
                isForgotPwdSuccess: true,
                isForgotPwdError: false,
                message: response
            };
        case FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                showProgress: false,
                isForgotPwdSuccess: false,
                isForgotPwdError: true,
                message: response.Message[0]
            };
        case UPDATE_PASSWORD_REQUEST:
            return {
                ...state,
                showProgress: true,
                isUpdatePwdSuccess: false,
                isUpdatePwdError: false,
                updatePwdMessage: ''
            };
        case UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                showProgress: false,
                isUpdatePwdSuccess: true,
                isUpdatePwdError: false,
                updatePwdMessage: response
            };
        case UPDATE_PASSWORD_ERROR:
            return {
                ...state,
                showProgress: false,
                isUpdatePwdSuccess: false,
                isUpdatePwdError: true,
                updatePwdMessage: response.Message[0]
            };
        case GOOGLE_LOGIN_REQUEST:
            return {
                ...state,
                showProgress: true,
                isGoogleLoginSuccess: false,
                isGoogleLoginError: false,
                userData: {}
            };
        case GOOGLE_LOGIN_SUCCESS:
            return {
                ...state,
                showProgress: false,
                isGoogleLoginSuccess: true,
                isGoogleLoginError: false,
                userData: response
            };
        case GOOGLE_LOGIN_ERROR:
            return {
                ...state,
                showProgress: false,
                isGoogleLoginSuccess: false,
                isGoogleLoginError: true,
                userData: response.Message[0]
            };

        case FACEBOOK_LOGIN_REQUEST:
            return {
                ...state,
                showProgress: true,
                isFacebookLoginSuccess: false,
                isFacebookLoginError: false,
                userData: {}
            };
        case FACEBOOK_LOGIN_SUCCESS:
            return {
                ...state,
                showProgress: false,
                isFacebookLoginSuccess: true,
                isFacebookLoginError: false,
                userData: response
            };
        case FACEBOOK_LOGIN_ERROR:
            return {
                ...state,
                showProgress: false,
                isFacebookLoginSuccess: false,
                isFacebookLoginError: true,
                userData: response.Message[0]
            };


        case RESEND_VERIFICATION_REQUEST:
            return {
                ...state,
                showProgress: true,
                isResendVerificationSuccess: false,
                isResendVerificationError: false,
                resendVerificationData: {}
            };
        case RESEND_VERIFICATION_SUCCESS:
            return {
                ...state,
                showProgress: false,
                isResendVerificationSuccess: true,
                isResendVerificationError: false,
                resendVerificationData: response
            };
        case RESEND_VERIFICATION_ERROR:
            return {
                ...state,
                showProgress: false,
                isResendVerificationSuccess: false,
                isResendVerificationError: true,
                resendVerificationData: response.Message[0]
            };


        default:
            return state;
    }
}