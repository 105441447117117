import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { userReducer } from './userReducer';
import { eventReducer } from './eventReducer';
import { cityReducer } from './cityReducer';
import { categoryReducer } from './categoryReducer';
import { siteContentReducer } from './siteContentReducer';

const rootReducer = combineReducers({
    authReducer,
    userReducer,
    eventReducer,
    cityReducer,
    categoryReducer,
    siteContentReducer
});

export default rootReducer;