import { takeLatest, put, call } from "redux-saga/effects";
import { GET_CITIES } from "../actions/actionTypes";
import { getCitiesSuccess, getCitiesError } from "../actions/actions";
import CityAPI from '../../apis/cityApi';

function* getCities() {
    try {
        const response = yield call(CityAPI.GetCities);

        const data = yield response.data.List;

        //console.log(data);

        if (response.statusText === "OK")
            yield put(getCitiesSuccess(data));
        else
            yield put(getCitiesError(data));
    } catch (error) {
        yield put(getCitiesError(error.message));
    }
}

export default function* () {
    yield takeLatest(GET_CITIES, getCities);
}