import React, { Component } from "react";
import { connect } from "react-redux";
import Select from 'react-select';
import Slider from "react-slick";
import chessboardVideo from "../../images/video/videoplayback.mp4";
import featuredImg from "../../images/featured__select-sk-img.svg";

import calendarIcon from "../../images/calendar_ic.svg";
import locationIcon from "../../images/location-pin.svg";
import clockIcon from "../../images/clock.svg";
import getAppIcon from "../../images/get-app-back.jpg";
import loading from "../../images/loading.gif";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Helper from '../../services/helper';
import { getCities, getCategories, getFilteredEvents, getTrendingEvents, addToFavourite, removeFromFavourite } from "../../redux/actions/actions";
import { getHomePageImage, sendSMSRequest } from "../../redux/actions/actions";
import qrcod from '../../images/qrcode.png';
import qrcode1 from '../../images/qrcode1.png';
import qrcode2 from '../../images/qrcode2.png';
import skbtn from '../../images/booking-app.png';
import skbtn2 from '../../images/organize-app.png';
import appStoreImg from "../../images/app-store.svg";
import playStoreImg from "../../images/google-play-store.svg";
import Preloader from '../../components/loader';
import mainAppQRCode from "../../images/mainAppQRCode.png";
import organizerQRCode from "../../images/organizerQRCode.png";
import { QRCodeCanvas } from 'qrcode.react';
const qrCodeStyle = {
    marginTop: "20%",
    width: "150px",
    // backgroundColor: "red", // or set bgColor here if you want to keep it dynamic
  };

const customStyles = {
    option: (provided) => ({
        ...provided,
        fontWeight: 500,
        fontSize: '14px/19px',
        color: '#4c4c4c !important',
        zIndex: '999',
    }),
    placeholder: (defaultStyles) => ({
        ...defaultStyles,
        color: '#4c4c4c',
        textAlign: "center"
    }),
    control: (provided) => ({
        ...provided,
        minWidth: '160px',
        font: '600 18px/25px "Open Sans", sans-serif',
        backgroundColor: '#fff !important',
        color: '#4c4c4c !important',
        borderRadius: '30px !important',
        border: 'none'
    }),
    indicatorSeparator: (provided) => ({
        color: "white"
    }),
    menu: (base) => ({ ...base, zIndex: '999' }),
    container: (provided) => ({
        ...provided,
        width: '200px'
    })
};

const multiCustomStyles = {
    option: (provided) => ({
        ...provided,
        minWidth: '205px',
        fontWeight: 500,
        fontSize: '14px/19px',
        color: '#4c4c4c !important',    
        zIndex: '9999',
    }),
    placeholder: (defaultStyles) => ({
        ...defaultStyles,
        color: '#4c4c4c',
        textAlign: "center"
    }),
    control: (provided) => ({
        ...provided,
        minWidth: '205px',
        font: '600 18px/25px "Open Sans", sans-serif',
        backgroundColor: '#fff !important',
        color: '#4c4c4c !important',
        borderRadius: '30px !important',
        border: 0,
        // This line disable the blue border
        boxShadow: 'none',
    }),
    indicatorSeparator: (provided) => ({
        color: "white"
    }),
    menu: (base) => ({ ...base, zIndex: '999' }),
    container: (provided) => ({
        ...provided,
        width: '205px',
    }),
    multiValue: (provided) => ({
        ...provided,
        background: 'none',
        border: '0px'
    })
};

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: (Helper.IsAuthenticated()) ? Helper.GetCurrentUser().UserId : null,
            eventName: '',
            categorySelect: [],
            category: [],
            citySelect: [],
            city: [],
            isAllCategoryShown: false,
            selectedCategory: 0,
            categoryPlaceholder: 'Category',
            cityPlaceholder: 'City',
            mobileNo: '',
            email: '',
            feId: 0,
            events: [],
            unmute: false,
        };
    }

    componentDidMount() {
        // if (!Helper.IsValidated()) {
        //     this.props.history.push("/");
        // }

        this.loadCities();
        this.loadCategories();
        this.getEventList();
        this.getTrendingEventList();

        setTimeout(this.getHomePageImg(), 5000);
    }

    componentDidUpdate(prevProps, prevState) {
        const { city, category, events } = this.state;
        const { isGetCategoriesSuccess: prevIsGetCategoriesSuccess, isGetCitiesSuccess: prevIsGetCitiesSuccess, isAddToFavouriteSuccess: prevIsAddToFavouriteSuccess, isGeFilteredEventsSuccess: prevIsGeFilteredEventsSuccess } = prevProps;
        const { isRemoveFromFavouriteSuccess: prevIsRemoveFromFavouriteSuccess } = prevProps;
        const { isGetCategoriesSuccess, categories, isGetCitiesSuccess, cities, isAddToFavouriteSuccess, isRemoveFromFavouriteSuccess, isGeFilteredEventsSuccess } = this.props;
        const { sendSMSSuccess: prevSendSMSSuccess } = prevProps;
        const { sendSMSSuccess, filteredEvents } = this.props;

        if (prevIsGetCitiesSuccess === false && isGetCitiesSuccess) {
            let data = [];

            cities.map((itm) =>
                data.push({ value: itm.Id, label: itm.City })
            );

            this.setState({ citySelect: data });
        }

        if (prevIsGeFilteredEventsSuccess === false && isGeFilteredEventsSuccess) {
            let _this = this;

            this.setState({ events: filteredEvents }, function () {
                _this.state.events.forEach((element, index) => {
                    element["showAddOrRemoveFavProgress"] = false
                });
            });
        }

        if (prevIsGetCategoriesSuccess === false && isGetCategoriesSuccess) {
            let data = [];

            categories.map((itm) =>
                data.push({ value: itm.Id, label: itm.Title })
            );

            this.setState({ categorySelect: data });
        }

        if (prevIsAddToFavouriteSuccess === false && isAddToFavouriteSuccess) {
            let events = [...this.state.events];
            let index = events.findIndex(d => d.Id === this.state.feId);
            this.setState(prevState => {
                const events = [...prevState.events];
                events[index].IsFavourite = true;
                events[index].showAddOrRemoveFavProgress = false;
                return { events: events };
            });

            //this.getEventList();
        }

        if (prevIsRemoveFromFavouriteSuccess === false && isRemoveFromFavouriteSuccess) {
            let feId = localStorage.getItem("feId") ? parseInt(localStorage.getItem("feId")) : this.state.feId;
            let events = [...this.state.events];
            let index = events.findIndex(d => d.Id === feId);

            if (index !== -1) {
                this.setState(prevState => {
                    const events = [...prevState.events];
                    events[index].IsFavourite = false;
                    events[index].showAddOrRemoveFavProgress = false;
                    return { events: events };
                }, function () {
                    localStorage.removeItem("feId");
                });
            }
        }

        if (prevSendSMSSuccess === false && sendSMSSuccess) {
            alert("All sent! Check your messages and follow the link!");
        }
    }

    getHomePageImg = async () => {
        const { getHomePageImage } = this.props;

        var pFile = new Object();
        pFile.title = 'HomePageImage';

        await getHomePageImage(pFile);
    }

    sendAppLinkSMS = async () => {
        const { mobileNo } = this.state;
        const { sendSMSRequest } = this.props;

        if (mobileNo === "")
            alert("Please enter email address");

        var objSMS = new Object();
        objSMS.ToMobileNo = mobileNo;

        await sendSMSRequest(objSMS);
    }

    loadCities = async () => {
        const { getCities } = this.props;

        await getCities();
    }

    loadCategories = async () => {
        const { userId } = this.state;
        const { getCategories } = this.props;

        await getCategories(userId);
    }

    getEventList = async () => {
        const { userId, eventName } = this.state;
        const { getFilteredEvents } = this.props;

        var objEvent = new Object();

        objEvent.When = 1;
        objEvent.TagIdList = [];
        objEvent.UserId = userId;
        objEvent.CityId = [];
        objEvent.OnlyFreeEvent = false;
        objEvent.MinPrice = 0;
        objEvent.MaxPrice = 5000;
        objEvent.MapLat = null;
        objEvent.MapLng = null;
        objEvent.Distance = 0;
        objEvent.EventName = eventName;

        await getFilteredEvents(objEvent);
    }

    getTrendingEventList = async () => {
        const { userId } = this.state;
        const { getTrendingEvents } = this.props;

        await getTrendingEvents(userId);
    }

    filterEvents = async () => {
        const { userId, eventName, city, category } = this.state;
        const { getFilteredEvents } = this.props;
        let filterByCity = []; let filterByCategory = [];

        //if (city.value !== undefined)
        //    filterByCity.push(city.value);
        //if (category.value !== undefined)
        //    filterByCategory.push(category.value);

        if (city !== undefined) {
            for (let i = 0; i < city.length; i++) {
                filterByCity.push(city[i].value)
            }
        }

        if (category !== undefined) {
            for (let i = 0; i < category.length; i++) {
                filterByCategory.push(category[i].value)
            }
        }

        var objEvent = new Object();

        objEvent.When = 1;
        objEvent.TagIdList = filterByCategory;
        objEvent.UserId = userId;
        objEvent.CityId = filterByCity;
        objEvent.OnlyFreeEvent = false;
        objEvent.MinPrice = 0;
        objEvent.MaxPrice = 5000;
        objEvent.MapLat = null;
        objEvent.MapLng = null;
        objEvent.Distance = 0;
        objEvent.EventName = eventName;

        //console.log(JSON.stringify(objEvent));

        await getFilteredEvents(objEvent);
    }

    filterEventsByCategory = async (categoryId) => {
        const { userId, eventName } = this.state;
        const { getFilteredEvents } = this.props;
        let filterByCategory = [];

        this.setState({ city: [], category: [], selectedCategory: categoryId });

        if (categoryId !== 0)
            filterByCategory.push(categoryId);

        var objEvent = new Object();

        objEvent.When = 1;
        objEvent.TagIdList = filterByCategory;
        objEvent.UserId = userId;
        objEvent.CityId = [];
        objEvent.OnlyFreeEvent = false;
        objEvent.MinPrice = 0;
        objEvent.MaxPrice = 5000;
        objEvent.MapLat = null;
        objEvent.MapLng = null;
        objEvent.Distance = 0;
        objEvent.EventName = eventName;

        await getFilteredEvents(objEvent);
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
    }

    handleCategoryChange = (e) => {
        this.setState({
            category: e
        }, function () {
            this.setState({ categoryPlaceholder: this.state.category.length > 1 ? 'MULTIPLE' : ((this.state.category.length > 0) ? this.state.category[0].label : 'Category') });
        });
    }

    handleCityChange = (e) => {
        this.setState({
            city: e
        }, function () {
            this.setState({ cityPlaceholder: this.state.city.length > 1 ? 'Multiple' : ((this.state.city.length > 0) ? this.state.city[0].label : 'City') });
        });
    }

    onPressEnter = (e) => {
        if (e.key === 'Enter') {
            this.getEventList();
        }
    }

    setIsAllCategoryShown = async (e) => {
        this.setState({ isAllCategoryShown: e });
    }

    handleAddToFavourite = async (eventId) => {
        const { userId } = this.state;
        const { addToFavourite } = this.props;

        let events = [...this.state.events];
        let index = events.findIndex(d => d.Id === eventId);
        this.setState(prevState => {
            const events = [...prevState.events];
            events[index].showAddOrRemoveFavProgress = true;
            return { events: events };
        });

        this.setState({ feId: eventId });

        let isValid = true;

        const messages = [];

        if (!Helper.IsAuthenticated()) {
            isValid = false;
            messages.push("- Please login to add event as favourite\n");
        }

        if (!isValid) {
            alert(Helper.arrayToString(messages));

            document.getElementById("navSignIn").click();

            setTimeout(() => {
                document.getElementById("divSignIn").click();
            }, 200);

            this.setState(prevState => {
                const events = [...prevState.events];
                events[index].showAddOrRemoveFavProgress = false;
                return { events: events };
            });

            return;
        }

        var objEvent = new Object();

        objEvent.EventId = eventId;
        objEvent.UserId = userId;

        await addToFavourite(objEvent);
    }

    handleRemoveFavourite = async (eventId) => {
        const { userId } = this.state;
        const { removeFromFavourite } = this.props;

        let events = [...this.state.events];
        let index = events.findIndex(d => d.Id === eventId);
        this.setState(prevState => {
            const events = [...prevState.events];
            events[index].showAddOrRemoveFavProgress = true;
            return { events: events };
        });

        this.setState({ feId: eventId });

        var objEvent = new Object();

        objEvent.EventId = eventId;
        objEvent.UserId = userId;

        await removeFromFavourite(objEvent);
    }

    handleGrabTicket = async (ticketURL) => {
        this.props.history.push(ticketURL);
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        });
    }
    unmuteVideo = (e) => {
        this.setState({ unmute: e });
    };
    getMobileOperatingSystem = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Detect iOS
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return 'iOS';
        }

        // Detect Android
        if (/android/i.test(userAgent)) {
            return 'Android';
        }

        // Other mobile OSes can be added here
        return 'unknown';
    };

    render() {
        const {unmute, categorySelect, category, citySelect, city, isAllCategoryShown, categoryPlaceholder, cityPlaceholder } = this.state;
        const { events } = this.state;
        const { showProgress, filteredEvents, trendingEvents, eventName, homePageImage } = this.props;

        return (   <Preloader>
            <>
                <div class="featured">
                    <div class="featured__chessboard-wrapper">
                        <video class="featured__chessboard-video" autoPlay muted loop playsInline>
                            <source src={chessboardVideo} type="video/mp4" />
                        </video>
                    </div>
                    <p style={{ display: "none" }}>{trendingEvents.length}</p>
                    <p style={{ display: "none" }}>{filteredEvents.length}</p>
                    {/*{console.log(trendingEvents, filteredEvents)}*/}
                    {trendingEvents && trendingEvents.length > 0 ?
                        <>
                            <div class="container-fluid">
                                <h2 class="featured__title title">Featured Events</h2>
                            </div>
                            <div class="featured__carousel-wrapper">
                                <Slider slidesToShow={(trendingEvents.length < 1) ? trendingEvents.length : 1}
                                    slidesToScroll={1}
                                    infinite={true}
                                    autoplay={true}
                                    autoplaySpeed={8000}
                                    arrows={true}
                                    speed={3000}>
                                    {trendingEvents.map((f) => (
                                        <div class="featured__carousel-box">
                                            <div class="featured__carousel-item">
                                                <div class="featured__carousel-wr" onClick={() => this.handleGrabTicket('/tickets/' + f.Id)}>
                                                    <img src={f.CoverPictureUrl} alt="trending" class="featured__carousel-img" />
                                                </div>
                                            </div>
                                        </div>))}
                                </Slider>
                            </div>
                        </> : <>
                            <div class="container-fluid">&nbsp;</div>
                            <div class="featured__carousel-wrapper">&nbsp;</div>
                        </>}

                    <div className="featured__select-row">
                        <div className="featured__select-wrapper">
                            <div className="featured__select-box">
                                <input type="text" className="featured__select-name input-field" placeholder="Search Event" aria-label="Event name" id="eventName" name="eventName" value={eventName} onChange={this.handleChange} style={{ border: "none" }} onKeyDown={(e) => { this.onPressEnter(e) }} />
                            </div>

                            <div className="featured__select-box ">
                                <Select id="category" name="category" style={{ aria_hidden: "true", boxShadow: 'none', width:"100%" }} options={categorySelect} styles={multiCustomStyles} value={category} isMulti onChange={this.handleCategoryChange} placeholder={categoryPlaceholder} controlShouldRenderValue={false} hideSelectedOptions={false} isSearchable={false} />
                            </div>

                            <div className="featured__select-box featured__select-box_align">
                                <Select id="city" name="city" style={{ aria_hidden: "true" }} options={citySelect} styles={multiCustomStyles} value={city} isMulti onChange={this.handleCityChange} placeholder={cityPlaceholder} controlShouldRenderValue={false} hideSelectedOptions={false} isSearchable={false} />
                            </div>
                            <div className="featured__btn__wrapper">
                                <button className="featured__select-btn" type="search" onClick={this.filterEvents}>
                                    <svg width="25.969" height={21} viewBox="0 0 25.969 21">
                                        <path id="Search_908" data-name="Search 908" className="cls-1" d="M976.492,167.886a2.088,2.088,0,0,1-2.912.532l-8.6-5.976a2.1,2.1,0,0,1-.9-1.8,7.624,7.624,0,1,1-1.227-11.507,7.674,7.674,0,0,1,2.5,9.644,2.083,2.083,0,0,1,2,.208l8.6,5.976A2.106,2.106,0,0,1,976.492,167.886Zm-12.751-13.421a5.3,5.3,0,0,0-9.585-2.079A5.315,5.315,0,1,0,963.741,154.465Z"
                                            transform="translate(-950.906 -147.781)" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        {!window.matchMedia('(max-width: 600px)').matches && <img src={featuredImg} alt="SK" className="featured__select-img" width="174px" height="140px" />}
                    </div>            

                </div>
                <div class="upcoming">
                    <div class="container-fluid">
                        <h2 class="upcoming__title title">Upcoming Events</h2>

                        <div class="upcoming__menu-wrapper">
                            <div class="upcoming__menu-lists">
                                <ul class="upcoming__menu list">
                                    <li class="upcoming__menu-item">
                                        <button class="upcoming__btn-optional upcoming__btn-optional_orang-dark" type="button" aria-label="Clubbing" onClick={() => this.filterEventsByCategory(categorySelect[0].value)}>
                                            #{categorySelect.length > 0 ? categorySelect[0].label : ""}
                                        </button>
                                    </li>
                                    <li class="upcoming__menu-item">
                                        <button class="upcoming__btn-optional upcoming__btn-optional_pink-dark" type="button" aria-label="Comedy" onClick={() => this.filterEventsByCategory(categorySelect[1].value)}>
                                            #{categorySelect.length > 0 ? categorySelect[1].label : ""}
                                        </button>
                                    </li>
                                    <li class="upcoming__menu-item">
                                        <button class="upcoming__btn-optional upcoming__btn-optional_pink-light" type="button" aria-label="Live" onClick={() => this.filterEventsByCategory(categorySelect[2].value)}>
                                            #{categorySelect.length > 0 ? categorySelect[2].label : ""}
                                        </button>
                                    </li>
                                    <li class="upcoming__menu-item">
                                        <button class="upcoming__btn-optional upcoming__btn-optional_orang-light" type="button" aria-label="Network" onClick={() => this.filterEventsByCategory(categorySelect[3].value)}>
                                            #{categorySelect.length > 0 ? categorySelect[3].label : ""}
                                        </button>
                                    </li>
                                    <li class="upcoming__menu-item">
                                        <button class="upcoming__btn-optional upcoming__btn-optional_blue-light" type="button" aria-label="Network" onClick={() => this.filterEventsByCategory(categorySelect[18].value)}>
                                            #{categorySelect.length > 0 ? categorySelect[18].label : ""}
                                        </button>
                                    </li>
                                    <li class="upcoming__menu-item">
                                        <button class="upcoming__btn-optional upcoming__btn-optional_blue-dark" type="button" aria-label="Network" onClick={() => this.filterEventsByCategory(categorySelect[5].value)}>
                                            #{categorySelect.length > 0 ? categorySelect[5].label : ""}
                                        </button>
                                    </li>
                                </ul>
                                <div class="upcoming__collapse" style={isAllCategoryShown ? { display: "" } : { display: "none" }}>
                                    <ul class="upcoming__menu list">
                                        <li class="upcoming__menu-item">
                                            <button class="upcoming__btn-optional upcoming__btn-optional_orang-dark" type="button" aria-label="Clubbing" onClick={() => this.filterEventsByCategory(categorySelect[6].value)}>
                                                #{categorySelect.length > 0 ? categorySelect[6].label : ""}
                                            </button>
                                        </li>
                                        <li class="upcoming__menu-item">
                                            <button class="upcoming__btn-optional upcoming__btn-optional_pink-dark" type="button" aria-label="Comedy" onClick={() => this.filterEventsByCategory(categorySelect[7].value)}>
                                                #{categorySelect.length > 0 ? categorySelect[7].label : ""}
                                            </button>
                                        </li>
                                        <li class="upcoming__menu-item">
                                            <button class="upcoming__btn-optional upcoming__btn-optional_pink-light" type="button" aria-label="Live" onClick={() => this.filterEventsByCategory(categorySelect[8].value)}>
                                                #{categorySelect.length > 0 ? categorySelect[8].label : ""}
                                            </button>
                                        </li>
                                        <li class="upcoming__menu-item">
                                            <button class="upcoming__btn-optional upcoming__btn-optional_orang-light" type="button" aria-label="Network" onClick={() => this.filterEventsByCategory(categorySelect[9].value)}>
                                                #{categorySelect.length > 0 ? categorySelect[9].label : ""}
                                            </button>
                                        </li>
                                        <li class="upcoming__menu-item">
                                            <button class="upcoming__btn-optional upcoming__btn-optional_blue-light" type="button" aria-label="Network" onClick={() => this.filterEventsByCategory(categorySelect[10].value)}>
                                                #{categorySelect.length > 0 ? categorySelect[10].label : ""}
                                            </button>
                                        </li>
                                        <li class="upcoming__menu-item">
                                            <button class="upcoming__btn-optional upcoming__btn-optional_blue-dark" type="button" aria-label="Network" onClick={() => this.filterEventsByCategory(categorySelect[11].value)}>
                                                #{categorySelect.length > 0 ? categorySelect[11].label : ""}
                                            </button>
                                        </li>
                                    </ul>
                                    <ul class="upcoming__menu list">
                                        <li class="upcoming__menu-item">
                                            <button class="upcoming__btn-optional upcoming__btn-optional_orang-dark" type="button" aria-label="Clubbing" onClick={() => this.filterEventsByCategory(categorySelect[12].value)}>
                                                #{categorySelect.length > 0 ? categorySelect[12].label : ""}
                                            </button>
                                        </li>
                                        <li class="upcoming__menu-item">
                                            <button class="upcoming__btn-optional upcoming__btn-optional_pink-dark" type="button" aria-label="Comedy" onClick={() => this.filterEventsByCategory(categorySelect[13].value)}>
                                                #{categorySelect.length > 0 ? categorySelect[13].label : ""}
                                            </button>
                                        </li>
                                        <li class="upcoming__menu-item">
                                            <button class="upcoming__btn-optional upcoming__btn-optional_pink-light" type="button" aria-label="Live" onClick={() => this.filterEventsByCategory(categorySelect[14].value)}>
                                                #{categorySelect.length > 0 ? categorySelect[14].label : ""}
                                            </button>
                                        </li>
                                        <li class="upcoming__menu-item">
                                            <button class="upcoming__btn-optional upcoming__btn-optional_orang-light" type="button" aria-label="Network" onClick={() => this.filterEventsByCategory(categorySelect[15].value)}>
                                                #{categorySelect.length > 0 ? categorySelect[15].label : ""}
                                            </button>
                                        </li>
                                        <li class="upcoming__menu-item">
                                            <button class="upcoming__btn-optional upcoming__btn-optional_blue-light" type="button" aria-label="Network" onClick={() => this.filterEventsByCategory(categorySelect[16].value)}>
                                                #{categorySelect.length > 0 ? categorySelect[16].label : ""}
                                            </button>
                                        </li>
                                        <li class="upcoming__menu-item">
                                            <button class="upcoming__btn-optional upcoming__btn-optional_blue-dark" type="button" aria-label="Network" onClick={() => this.filterEventsByCategory(categorySelect[17].value)}>
                                                #{categorySelect.length > 0 ? categorySelect[17].label : ""}
                                            </button>
                                        </li>
                                    </ul>
                                    <ul class="upcoming__menu list">
                                        <li class="upcoming__menu-item">
                                            <button class="upcoming__btn-optional upcoming__btn-optional_orang-dark" type="button" aria-label="Clubbing" onClick={() => this.filterEventsByCategory(categorySelect[4].value)}>
                                                #{categorySelect.length > 0 ? categorySelect[4].label : ""}
                                            </button>
                                        </li>
                                        <li class="upcoming__menu-item">
                                            <button class="upcoming__btn-optional upcoming__btn-optional_pink-dark" type="button" aria-label="Comedy" onClick={() => this.filterEventsByCategory(categorySelect[19].value)}>
                                                #{categorySelect.length > 0 ? categorySelect[19].label : ""}
                                            </button>
                                        </li>
                                        <li class="upcoming__menu-item">&nbsp;</li>
                                        <li class="upcoming__menu-item">&nbsp;</li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <div class="upcoming__menu-item_all">
                                    <button class="upcoming__btn-optional_all" type="button" aria-label="All" onClick={() => this.filterEventsByCategory(0)}>
                                        All
                                    </button>
                                </div>
                                <button class="upcoming__btn-arrow" type="button" onClick={() => this.setIsAllCategoryShown(!isAllCategoryShown)}>
                                    <i className={isAllCategoryShown ? "fas fa-chevron-up upcoming__arrow-icon" : "fas fa-chevron-down upcoming__arrow-icon"}></i>
                                </button>
                            </div>
                        </div>

                        {showProgress ?
                            (
                                <ul class="upcoming__cart-list list">
                                    <li>&nbsp;</li>
                                    <li style={{ textAlign: "center" }}>
                                        <img src={loading} style={{ width: "32px", height: "32px" }} />{" "}
                                    </li>
                                    <li>&nbsp;</li>
                                </ul>
                            )
                            :
                            (events.length > 0 ? (
                                <ul class="upcoming__cart-list list">
                                    {events.slice(0, 6).map((evt) => (
                                        <li class="upcoming__cart-item" style={{ cursor: "pointer" }}>
                                            <div class="upcoming__cart-wrap" onClick={() => this.handleGrabTicket('/tickets/' + evt.Id)}>
                                                <img src={evt.CoverPictureUrl} alt="Photo" class="upcoming__cart-image" />
                                            </div>
                                            <div class="upcoming__cart-content">
                                                <div class="upcoming__cart-box" onClick={() => this.handleGrabTicket('/tickets/' + evt.Id)}>
                                                    <h3 class="upcoming__cart-title">{(evt.Title.length > 12) ? (evt.Title.substring(0, 11) + '...') : evt.Title}</h3>
                                                    <div class="upcoming__cart-price">
                                                        <span class="upcoming__price-from">from</span>

                                                        <span class="upcoming__price">{evt.Currency}{parseFloat(evt.Price).toFixed(2)}</span>
                                                    </div>
                                                </div>

                                                <ul class="upcoming__date list">
                                                    <li class="upcoming__date-item">
                                                        <img src={calendarIcon} width="19" height="19" alt="Calendar" class="upcoming__calendar-icon" />
                                                        <p class="upcoming__date-text upcoming__date-text_big">
                                                            <span class="upcoming__date-sun">{Helper.getDayFromDate(evt.StartDate)}</span>{" "}
                                                            <span class="upcoming__calendar-number">
                                                                {Helper.formatDate(evt.StartDate)}
                                                            </span>{" "}
                                                            {Helper.getYearFromDate(evt.StartDate)}
                                                        </p>
                                                    </li>

                                                    <li class="upcoming__date-item">
                                                        <img src={locationIcon} width="13" height="16" alt="Location" class="upcoming__location-icon" />
                                                        <p class="upcoming__date-text">
                                                            {(evt.Venue) ? evt.Venue + "," : "-"}{" "}
                                                            <span class="upcoming__location-text">{(evt.City) ? evt.City : ""}</span>
                                                        </p>
                                                    </li>

                                                    <li class="upcoming__date-item upcoming__date-item_space-between">
                                                        <div class="upcoming__time-wrap">
                                                            <img src={clockIcon} width="13" height="13" alt="Clock" class="upcoming__clock-icon" />
                                                            <p class="upcoming__date-text">{Helper.formatTime(evt.StartDate)}</p>
                                                        </div>
                                                        {evt.showAddOrRemoveFavProgress ? <img src={loading} style={{ width: "32px", height: "32px" }} /> :
                                                            <button type="button" className={evt.IsFavourite ? "upcoming__date-link favouriteButt active" : "upcoming__date-link favouriteButt"} onClick={() => { evt.IsFavourite ? this.handleRemoveFavourite(evt.Id) : this.handleAddToFavourite(evt.Id) }}>
                                                                <svg width="32" height="28" viewBox="0 0 597 512" version="1.1">
                                                                    <g id="#4c4c4cff">
                                                                        <path fill="#4c4c4c" opacity="1.00" d=" M 159.50 0.00 L 188.36 0.00 C 228.88 3.72 267.58 21.08 298.55 47.24 C 330.49 20.38 370.65 2.38 412.56 0.00 L 439.41 0.00 C 485.66 3.98 531.23 24.72 561.93 60.01 C 583.33 84.42 595.19 116.37 597.00 148.63 L 597.00 170.43 C 594.74 207.83 581.74 243.93 563.24 276.28 C 553.98 292.76 542.19 307.63 530.18 322.17 C 484.90 376.08 428.99 419.48 374.30 463.28 C 365.73 470.08 357.38 477.18 348.58 483.67 C 336.29 493.15 324.69 504.27 309.86 509.65 C 299.54 513.49 287.40 512.15 278.47 505.62 C 208.81 454.94 141.69 400.35 81.35 338.74 C 57.66 314.17 37.53 286.02 22.84 255.18 C 9.28 226.58 0.66 195.36 0.00 163.61 L 0.00 155.43 C 0.55 138.55 2.88 121.58 8.52 105.61 C 18.87 76.11 39.69 50.98 65.17 33.19 C 92.82 13.88 125.82 2.11 159.50 0.00 M 118.00 88.08 C 103.95 96.08 91.76 107.59 83.73 121.69 C 78.32 133.31 75.92 146.17 75.45 158.93 C 75.12 185.43 84.18 211.13 96.83 234.10 C 104.95 248.54 114.14 262.47 125.17 274.87 C 176.25 332.98 238.20 379.91 298.74 427.54 C 357.86 380.55 418.60 334.67 469.16 278.17 C 479.54 266.95 488.30 254.35 496.24 241.32 C 507.90 221.90 517.06 200.64 520.44 178.12 C 523.14 160.83 521.67 142.79 515.44 126.39 C 510.46 115.81 503.12 106.39 494.32 98.72 C 460.24 69.48 407.33 65.98 368.97 88.82 C 363.54 91.99 358.74 96.07 353.60 99.66 C 341.38 109.23 334.42 123.78 322.54 133.71 C 318.22 137.39 313.02 140.07 307.45 141.23 C 298.36 142.74 288.41 142.80 280.36 137.68 C 264.11 127.76 256.58 108.74 241.19 97.81 C 236.42 94.55 231.86 90.95 226.79 88.14 C 193.81 69.34 151.03 69.46 118.00 88.08 Z" />
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        }
                                                    </li>
                                                </ul>
                                                {evt.SoldOut ? <button class="upcoming__card-btn" disabled style={{ cursor: "default" }}>Sold Out</button> :
                                                    <button class="upcoming__card-btn" onClick={() => this.handleGrabTicket('/tickets/' + evt.Id)}>Get Tickets</button>
                                                }
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <ul class="upcoming__cart-list list">
                                    <li>&nbsp;</li>
                                    <li style={{ textAlign: "center" }}>
                                        <span style={{ color: "red" }}>No records found</span>
                                    </li>
                                    <li>&nbsp;</li>
                                </ul>
                            ))
                        }
                        <div class="upcoming__btn-wrap">
                            <hr class="upcoming__btn-wrap_line" />
                            <a href="/events" class="upcoming__all-events">
                                All Events
                            </a>
                            <hr class="upcoming__btn-wrap_line" />
                        </div>
                    </div>
                </div>
                <div class="m-40" id="app">
                    &nbsp;
                </div>
                <div class="get-app">
                    <div class="container-fluid">
                        <h2 class="get-app__title title">Get The App</h2>

                        <p class="get-app__title-description">
                            The ultimate experience starts here
                        </p>
                    </div>
                    <div class="get-app__wrapper">
                        <div class="get-app__upload">
                            {homePageImage.includes(".mp4") ?
                                <>
                                    <video autoPlay={true} muted={unmute ? false : true} loop id="videoBG" class="get-app__upload-icon" playsInline>
                                        <source src={homePageImage} type="video/mp4" />
                                    </video>    
                                    <button id="videoMuted" type="button" class="app-video-muted" onClick={() => this.unmuteVideo(!unmute)}>
                                        <i class={unmute ? "fas fa-volume-up" : "fas fa-volume-mute"}></i>
                                    </button>
                                    </>                                
                                :
                                <img src={homePageImage} alt="upload" height="300" class="get-app__upload-icon" />
                            }
                        </div>
                       

                        <div class="get-app__number-wrap" style={{ flexDirection: "column" }}>
                            <div class="get-app__number-wrappp" style={{ flexDirection: "row" }}>
                                <div style={{ marginRight: "50px" }}>
                                    {this.getMobileOperatingSystem() === "unknown" ?<div style={{ marginTop: "20%", width: "150px" }}> <QRCodeCanvas value={`${window.location.origin}/redirect/main`} fgColor="#ff0000" size={150}  /> </div>: <img class="qrcodeimg" src={skbtn} onClick={(e) => {
                                        e.preventDefault(); // Prevents the default action
                                        const os = this.getMobileOperatingSystem();
                                        if (os === "iOS") {
                                            window.location.href = "https://apps.apple.com/gb/app/scank/id1574492173";
                                        } else if (os === "Android") {
                                            window.location.href = "https://play.google.com/store/apps/details?id=com.scank.smartapps";
                                        } else {
                                            console.log("Unsupported OS or unknown")
                                        }
                                        // For other cases, do nothing
                                    }}></img>}
                                      <br />
                                    <label onClick={(e) => {
                                        e.preventDefault(); // Prevents the default action
                                        const os = this.getMobileOperatingSystem();
                                        if (os === "iOS") {
                                            window.location.href = "https://apps.apple.com/gb/app/scank/id1574492173";
                                        } else if (os === "Android") {
                                            window.location.href = "https://play.google.com/store/apps/details?id=com.scank.smartapps";
                                        } else {
                                            console.log("Unsupported OS or unknown")
                                        }
                                        // For other cases, do nothing
                                    }} class="qrcodelbl">Grab Tickets!</label>
                                </div>
                                <div style={{ marginLeft: "50px" }}>
                                    {this.getMobileOperatingSystem() === "unknown" ?<div style={{ marginTop: "20%", width: "150px" }}> <QRCodeCanvas value={`${window.location.origin}/redirect/organizer`}   size={150} /></div>:<img class="qrcodeimg" src={skbtn2}
                                        onClick={(e) => {
                                            e.preventDefault(); // Prevents the default action
                                            const os = this.getMobileOperatingSystem();
                                            if (os === "iOS") {
                                                window.location.href = "https://apps.apple.com/gb/app/scankorganizers/id1608159730";
                                            } else if (os === "Android") {
                                                window.location.href = "https://play.google.com/store/apps/details?id=com.scank.organizer";
                                            } else {
                                                console.log("Unsupported OS or unknown")
                                            }
                                            // For other cases, do nothing
                                        }}>
                                    </img>}<br />
                                    <label onClick={(e) => {
                                            e.preventDefault(); // Prevents the default action
                                            const os = this.getMobileOperatingSystem();
                                            if (os === "iOS") {
                                                window.location.href = "https://apps.apple.com/gb/app/scankorganizers/id1608159730";
                                            } else if (os === "Android") {
                                                window.location.href = "https://play.google.com/store/apps/details?id=com.scank.organizer";
                                            } else {
                                                console.log("Unsupported OS or unknown")
                                            }
                                            // For other cases, do nothing
                                        }} class="qrcodelbl">Organise events!</label>
                                </div>
                            </div>
                            <div class="get-app__number-wrappp">

                                <div className="d-flex align-items-center justify-content-center mt-5">
                                    <a href="https://apps.apple.com/gb/app/scank/id1574492173" class="confirmation__social-link">
                                        <img src={appStoreImg} width="120" height="31" alt="App Store" class="confirmation__social-icon mt-2 mb-4" />
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.scank.smartapps" class="confirmation__social-link">
                                        <img src={playStoreImg} width="110" height="31" alt="Google Play Store" class="confirmation__social-icon mt-2 mb-4" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            </Preloader>
        );
    }
}

function mapStateToProps(state) {
    return {
        showProgress: state.eventReducer.showProgress,
        isGetCitiesSuccess: state.cityReducer.isGetCitiesSuccess,
        isGetCitiesError: state.cityReducer.isGetCitiesError,
        cities: state.cityReducer.cities,
        isGetCategoriesSuccess: state.categoryReducer.isGetCategoriesSuccess,
        isGetCategoriesError: state.categoryReducer.isGetCategoriesError,
        categories: state.categoryReducer.categories,
        isGeFilteredEventsSuccess: state.eventReducer.isGeFilteredEventsSuccess,
        isGeFilteredEventsError: state.eventReducer.isGeFilteredEventsError,
        filteredEvents: state.eventReducer.filteredEvents,
        isGetTrendingEventsSuccess: state.eventReducer.isGetTrendingEventsSuccess,
        isGetTrendingEventsError: state.eventReducer.isGetTrendingEventsError,
        trendingEvents: state.eventReducer.trendingEvents,
        isAddToFavouriteSuccess: state.eventReducer.isAddToFavouriteSuccess,
        isAddToFavouriteError: state.eventReducer.isAddToFavouriteError,
        addedToFavourite: state.eventReducer.addedToFavourite,
        isRemoveFromFavouriteSuccess: state.eventReducer.isRemoveFromFavouriteSuccess,
        isRemoveFromFavouriteError: state.eventReducer.isRemoveFromFavouriteError,
        removedFromFavourite: state.eventReducer.removedFromFavourite,
        isGetHomePageImgSuccess: state.siteContentReducer.isGetHomePageImgSuccess,
        isGetHomePageImgError: state.siteContentReducer.isGetHomePageImgError,
        homePageImage: state.siteContentReducer.homePageImage,
        sendSMSSuccess: state.eventReducer.sendSMSSuccess,
        sendSMSError: state.eventReducer.sendSMSError,
        smsResponse: state.eventReducer.smsResponse
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCities: () => dispatch(getCities()),
        getCategories: (data) => dispatch(getCategories(data)),
        getFilteredEvents: (data) => dispatch(getFilteredEvents(data)),
        getTrendingEvents: (data) => dispatch(getTrendingEvents(data)),
        addToFavourite: (data) => dispatch(addToFavourite(data)),
        removeFromFavourite: (data) => dispatch(removeFromFavourite(data)),
        getHomePageImage: (data) => dispatch(getHomePageImage(data)),
        sendSMSRequest: (data) => dispatch(sendSMSRequest(data))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
