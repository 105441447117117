import React, { useEffect } from 'react';

const RedirectToApp = ({ appType }) => {
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
        const isAndroid = /android/i.test(userAgent);

        if (appType === 'main') {
            if (isIOS) {
                window.location.href = "https://apps.apple.com/gb/app/scank/id1574492173";
            } else if (isAndroid) {
                window.location.href = "https://play.google.com/store/apps/details?id=com.scank.smartapps";
            }
        } else if (appType === 'organizer') {
            if (isIOS) {
                window.location.href = "https://apps.apple.com/gb/app/scankorganizers/id1608159730";
            } else if (isAndroid) {
                window.location.href = "https://play.google.com/store/apps/details?id=com.scank.organizer";
            }
        }
    }, [appType]);

    return (
        <div 
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: '#f0f0f0',
                textAlign: 'center',
            }}
        >
            <p 
                style={{
                    fontSize: '20px',
                    color: '#333',
                }}
            >
                Redirecting...
            </p>
        </div>
    );

};

export default RedirectToApp;
