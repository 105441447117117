import React, { useState } from "react";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Helper from '../services/helper';
import axios from "axios";
import loading from "../images/loading.gif";

function PaymentSection() {

    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);    

    const handleChange = (event) => {
        setIsFormValid(event.complete && event.error === undefined);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        if (!stripe || !elements) {
            setIsLoading(false)
            return;
        }

        const { error: submitError } = await elements.submit();
        if (submitError) {
            setIsLoading(false);
            setErrorMessage(submitError.message);
            return;
        }

        let currentBasket = (Helper.IsAuthenticated()) ? await Helper.GetCurrentBasket() : null;
        const userId = (Helper.IsAuthenticated()) ? await Helper.GetCurrentUser().UserId : null;

        var objPayment = new Object();

        objPayment.UserId = userId;
        objPayment.Amount = JSON.parse(currentBasket).totalAmount;
        objPayment.PaymentMethodNonce = "paypalThroughStripe";
        objPayment.BasketId = JSON.parse(currentBasket).basketId;
        objPayment.Token = "";
        objPayment.IntentId = null;
        objPayment.TransactionId = "";
        objPayment.Region = "domestic";

        const intent = await axios.post(
            `https://scankwebapi.azurewebsites.net/api/Payment/SetPaidBasketNew`,
            objPayment,
            {
                headers: Helper.authHeader(),
            }
        )

        if (intent.error) {
            setIsLoading(false);
            setErrorMessage(intent.error);
        } else if (intent.data.State) {
            let curBasket = JSON.parse(currentBasket);

            const clientSecret = intent.data.Message[0]

            const { error } = await stripe.confirmPayment({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: window.location.origin + "/payment-response/" + curBasket.eventId + "/" + curBasket.totalAmount + "/" + curBasket.basketId
                }
            })
            if (error) {
                setIsLoading(false);
                setErrorMessage(error.message);
            }
        } else {
            setIsLoading(false);
            setErrorMessage(intent.data.Message[0])
        }
    };


    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement onChange={handleChange}/>
            {isLoading ? <div><div style={{ background: "none", height: "auto", textAlign: "center", marginBottom: "15px" }}><div className="container-fluid"><img src={loading} style={{ width: "32px", height: "32px" }} /></div></div><div style={{ textAlign: "center", fontSize: "12px", marginTop: "-10px", marginBottom: "10px" }}>Payment processing. Please wait!</div></div> : <div class="payments__form-wrap">
                <button disabled={!stripe||!isFormValid} class={`payments__form-submit ${!isFormValid?"disabled":""}`}>Complete Order</button>
            </div>
            }
            {errorMessage && <div>{errorMessage}</div>}
        </form>
    )
}

export default PaymentSection;