import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import calendarIcon from "../../images/calendar_ic.svg";
import locationIcon from "../../images/location-pin.svg";
import { getSiteContent } from "../../redux/actions/actions"
import loading from "../../images/loading.gif";
import Helper from '../../services/helper';
import { loadStripe } from "@stripe/stripe-js";

const axios = require("axios");

class PaymentResponse extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: (Helper.IsAuthenticated()) ? Helper.GetCurrentUser().UserId : null,
            eventId: (this.props.match.params["eid"] === undefined) ? "0" : this.props.match.params["eid"],
            totalAmount: (this.props.match.params["tAmt"] === undefined) ? "0" : this.props.match.params["tAmt"],
            basketId: (this.props.match.params["bid"] === undefined) ? "0" : this.props.match.params["bid"],
            stripePubKey: "",
            showProgress: true,
            errorMsg: ""
        };
    }

    componentDidMount = async () => {
        this.completePayment();
    }

    completePayment = async () => {
        const { userId, eventId, totalAmount, basketId } = this.state;
        debugger
        const url = new URL(window.location);

        if (url.searchParams.get('redirect_status') === "failed") {
            this.setState({ errorMsg: "Payment failed!!", showProgress: false }, function () {
                localStorage.removeItem("basket");

                setTimeout(() => {
                    window.location.href = window.location.origin + "/tickets/" + eventId;
                }, 5000);
            });
        }
        else {
            await axios.get("https://scankwebapi.azurewebsites.net/api/Payment/Stripe/PublicKey").then(response => {
                let _this = this;

                this.setState({ stripePubKey: loadStripe(response.data) }, async function () {
                    const stripe = await loadStripe(response.data);

                    const clientSecret = url.searchParams.get('payment_intent_client_secret');

                    const { paymentIntent, error } = await stripe.retrievePaymentIntent(clientSecret);

                    if (error) {
                        this.setState({ errorMsg: error, showProgress: false });
                    } else {
                        if (paymentIntent && paymentIntent.status === 'succeeded') {
                            var objPayment = new Object();

                            objPayment.UserId = userId;
                            objPayment.Amount = totalAmount;
                            objPayment.PaymentMethodNonce = "paypalThroughStripe";
                            objPayment.BasketId = basketId;
                            objPayment.Token = null;
                            objPayment.IntentId = paymentIntent.id;
                            objPayment.TransactionId = "";
                            objPayment.Region = "";

                            await axios.post(
                                `https://scankwebapi.azurewebsites.net/api/Payment/SetPaidBasketNew`,
                                objPayment,
                                {
                                    headers: Helper.authHeader(),
                                }
                            ).then(async function (response) {
                                localStorage.removeItem("basket");
                                if (response.data.State) {
                                    window.location.href = window.location.origin + "/tickets/" + eventId + "/s";
                                }
                            });
                        }
                        else {
                            this.setState({ errorMsg: "Payment failed!!", showProgress: false }, function () {
                                localStorage.removeItem("basket");
                                setTimeout(() => {
                                    window.location.href = window.location.origin + "/tickets/" + eventId;
                                }, 5000);
                            });
                        }
                    }
                });
            });
        }
    }

    render() {
        const { showProgress, errorMsg } = this.state;

        return (
            <>
                {
                    showProgress ?
                        <div className="order">< div className="order__title-bg-clear" style={{ background: "none", minHeight: "370px", height: "auto" }
                        }>
                            <div className="container-fluid">
                                <img src={loading} style={{ width: "32px", height: "32px" }} />
                                <h3 style={{ color: "#000", fontSize: "16px", marginTop: "15px" }}>Your payment is being processed. Please wait!</h3>
                            </div>

                        </div>
                        </div>
                        :
                        <div className="order">< div className="order__title-bg-clear" style={{ background: "none", minHeight: "370px", height: "auto" }
                        }>
                            <div className="container-fluid">
                                <h3 style={{ color: "red", fontSize: "16px", marginTop: "15px" }}>{errorMsg}</h3>
                            </div>
                        </div>
                        </div>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        isGetSiteContentSuccess: state.siteContentReducer.isGetSiteContentSuccess,
        isGetSiteContentError: state.siteContentReducer.isGetSiteContentError,
        siteContent: state.siteContentReducer.siteContent,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        getSiteContent: (data) => dispatch(getSiteContent(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentResponse);