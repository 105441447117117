import { all } from 'redux-saga/effects';
import authSaga from './authSaga';
import userSaga from './userSaga';
import eventSaga from './eventSaga';
import citySaga from './citySaga';
import categorySaga from './categorySaga';
import siteContentSaga from './siteContentSaga';

export default function* rootSaga() {
    yield all([
        authSaga(),
        userSaga(),
        eventSaga(),
        citySaga(),
        categorySaga(),
        siteContentSaga()
    ]);
}