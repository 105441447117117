import { takeLatest, put, call } from "redux-saga/effects";
import { GET_UPCOMING_EVENTS, GET_FILTERED_EVENTS, GET_FAVOURITE_EVENTS, GET_EVENT_DETAILS, GET_TRENDING_EVENTS, ADD_TO_FAVOURITE, REMOVE_FROM_FAVOURITE, CLEAR_BASKET } from "../actions/actionTypes";
import { GET_EVENT_TICKETS, ADD_ITEM_TO_BASKET, GET_BASKET, DO_PAYMENT, SEND_SMS, GET_EVENT_DETAILS_BY_URL } from "../actions/actionTypes";
import { getUpcomingEventsSuccess, getUpcomingEventsError, getFilteredSuccess, getFilteredError, getFavouriteSuccess, getFavouriteError, getEventDetailsSuccess } from "../actions/actions";
import { getEventDetailsError, getTrendingSuccess, getTrendingError, addToFavouriteSuccess, addToFavouriteError, removeFromFavouriteSuccess, removeFromFavouriteError } from "../actions/actions";
import { getEventTicketsSuccess, getEventTicketsError, addToBasketSuccess, addToBasketError, getBasketSuccess, getBasketError, doPaymentSuccess, doPaymentError } from "../actions/actions";
import { clearBasketSuccess, clearBasketError, sendSMSSuccess, sendSMSError, getEventDetailsByUrlSuccess, getEventDetailsByUrlError } from "../actions/actions";
import EventAPI from '../../apis/eventApi';

function* getUpcomingEvents(params) {
    try {
        const response = yield call(EventAPI.GetUpcomingEvents, params.id);

        const data = yield response.data.List;

        //console.log(data);

        if (data.State)
            yield put(getUpcomingEventsSuccess(data));
        else
            yield put(getUpcomingEventsError(data));
    } catch (error) {
        yield put(getUpcomingEventsError(error.message));
    }
}

function* getFilteredEvents(params) {
    try {
        const response = yield call(EventAPI.GetFilteredEvents, params);

        const data = yield response.data.List;

        //console.log(data);
        if (response.data.State)
            yield put(getFilteredSuccess(data));
        else
            yield put(getFilteredError(data));

    } catch (error) {
        yield put(getFilteredError(error.message));
    }
}

function* getFavouriteEvents(id) {
    try {
        const response = yield call(EventAPI.GetFavouriteEvents, id);

        const data = yield response.data.List;

        //console.log(data);
        if (response.data.State)
            yield put(getFavouriteSuccess(data));
        else
            yield put(getFavouriteError(data));
    } catch (error) {
        yield put(getFavouriteError(error.message));
    }
}

function* getEventDetails(params) {
    try {
        const response = yield call(EventAPI.GetEventDetails, params);

        const data = yield response.data;

        //console.log(data);

        if (response.data.State)
            yield put(getEventDetailsSuccess(data));
        else
            yield put(getEventDetailsError(data));
    } catch (error) {
        yield put(getEventDetailsError(error.message));
    }
}

function* getTrendingEvents(id) {
    try {
        const response = yield call(EventAPI.GetTrendingEvents, id);

        const data = yield response.data.List;

        //console.log(data);
        if (response.data.State)
            yield put(getTrendingSuccess(data));
        else
            yield put(getTrendingError(data));
    } catch (error) {
        yield put(getTrendingError(error.message));
    }
}

function* addEventToFavourite(params) {
    try {
        const response = yield call(EventAPI.AddToFavourite, params);

        const data = yield response.data;

        //console.log(data);
        if (response.data.State)
            yield put(addToFavouriteSuccess(data));
        else
            yield put(addToFavouriteError(data));

    } catch (error) {
        yield put(addToFavouriteError(error.message));
    }
}

function* removeEventFromFavourite(params) {
    try {
        const response = yield call(EventAPI.DeleteFavourite, params);

        const data = yield response.data;

        //console.log(data);
        if (response.data.State)
            yield put(removeFromFavouriteSuccess(data));
        else
            yield put(removeFromFavouriteError(data));

    } catch (error) {
        yield put(removeFromFavouriteError(error.message));
    }
}

function* getEventTickets(id) {
    try {
        const response = yield call(EventAPI.GetEventTickets, id);

        const data = yield response.data.List;

        //console.log(data);
        if (response.data.State)
            yield put(getEventTicketsSuccess(data));
        else
            yield put(getEventTicketsError(data));
    } catch (error) {
        yield put(getEventTicketsError(error.message));
    }
}

function* addItemToBasket(id) {
    try {
        const response = yield call(EventAPI.AddItemToBasket, id);

        const data = yield response.data;

        //console.log(data);
        if (response.data.State)
            yield put(addToBasketSuccess(data));
        else
            yield put(addToBasketError(data));
    } catch (error) {
        yield put(addToBasketError(error.message));
    }
}

function* getBasketDetails(id) {
    try {
        const response = yield call(EventAPI.GetBasketDetails, id);

        const data = yield response.data;

        //console.log(data);
        if (response.data.State)
            yield put(getBasketSuccess(data));
        else
            yield put(getBasketError(data));
    } catch (error) {
        yield put(getBasketError(error.message));
    }
}

function* clearItemsFromBasket(id) {
    try {
        const response = yield call(EventAPI.ClearBasket, id);

        const data = yield response.data;

        //console.log(data);
        if (response.data.State)
            yield put(clearBasketSuccess(data));
        else
            yield put(clearBasketError(data));
    } catch (error) {
        yield put(clearBasketError(error.message));
    }
}

function* doPayment(id) {
    try {
        const response = yield call(EventAPI.DoPayment, id);

        const data = yield response.data;

        //console.log(data);
        if (response.data.State)
            yield put(doPaymentSuccess(data));
        else
            yield put(doPaymentError(data));
    } catch (error) {
        yield put(doPaymentError(error.message));
    }
}

function* sendSMS(id) {
    try {
        const response = yield call(EventAPI.SendSMS, id);

        const data = yield response.data;

        //console.log(data);
        if (response.data.State)
            yield put(sendSMSSuccess(data));
        else
            yield put(sendSMSError(data));
    } catch (error) {
        yield put(sendSMSError(error.message));
    }
}

function* getEventDetailsByUrl(params) {
    try {
        const response = yield call(EventAPI.GetDetailsByWebsiteURL, params);

        const data = yield response.data;

        //console.log(data);

        if (response.data.State)
            yield put(getEventDetailsByUrlSuccess(data));
        else
            yield put(getEventDetailsByUrlError(data));
    } catch (error) {
        yield put(getEventDetailsByUrlError(error.message));
    }
}

export default function* () {
    yield takeLatest(GET_UPCOMING_EVENTS, getUpcomingEvents);
    yield takeLatest(GET_FILTERED_EVENTS, getFilteredEvents);
    yield takeLatest(GET_FAVOURITE_EVENTS, getFavouriteEvents);
    yield takeLatest(GET_EVENT_DETAILS, getEventDetails);
    yield takeLatest(GET_TRENDING_EVENTS, getTrendingEvents);
    yield takeLatest(ADD_TO_FAVOURITE, addEventToFavourite);
    yield takeLatest(REMOVE_FROM_FAVOURITE, removeEventFromFavourite);
    yield takeLatest(GET_EVENT_TICKETS, getEventTickets);
    yield takeLatest(ADD_ITEM_TO_BASKET, addItemToBasket);
    yield takeLatest(GET_BASKET, getBasketDetails);
    yield takeLatest(CLEAR_BASKET, clearItemsFromBasket);
    yield takeLatest(DO_PAYMENT, doPayment);
    yield takeLatest(SEND_SMS, sendSMS);
    yield takeLatest(GET_EVENT_DETAILS_BY_URL, getEventDetailsByUrl);
}
