import React, { Component } from "react";
import landingImg from "../../images/landing.png";

class LandingHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: false
        };
    }

    componentDidMount() {
        // console.log(document.getElementById("scrollUp"))
        if (document.getElementById("scrollUp") && document.documentElement.scrollTop <= 150)
            document.getElementById("scrollUp").style.display = "none";
        else if (document.getElementById("scrollUp"))
            document.getElementById("scrollUp").style.display = "";

        window.addEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        if (document.getElementById("scrollUp") && document.documentElement.scrollTop <= 150)
            document.getElementById("scrollUp").style.display = "none";
        else if (document.getElementById("scrollUp"))
            document.getElementById("scrollUp").style.display = "";
    };

    render() {

        return (
            <> </>
        );
    }
}

export default (LandingHeader);
