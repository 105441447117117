import { takeLatest, put, call } from "redux-saga/effects";
import { GET_CATEGORIES } from "../actions/actionTypes";
import { getCategoriesSuccess, getCategoriesError } from "../actions/actions";
import CategoryAPI from '../../apis/categoryApi';

function* getCategories() {
    try {
        const response = yield call(CategoryAPI.GetCategories);

        const data = yield response.data.List;

        //console.log(data);

        if (response.statusText === "OK")
            yield put(getCategoriesSuccess(data));
        else
            yield put(getCategoriesError(data));
    } catch (error) {
        yield put(getCategoriesError(error.message));
    }
}

export default function* () {
    yield takeLatest(GET_CATEGORIES, getCategories);
}