export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_ERROR = 'USER_REGISTER_ERROR';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_ERROR = 'USER_PROFILE_ERROR';

export const USER_TICKET_REQUEST = 'USER_TICKET_REQUEST';
export const USER_TICKET_SUCCESS = 'USER_TICKET_SUCCESS';
export const USER_TICKET_ERROR = 'USER_TICKET_ERROR';

export const GET_UPCOMING_EVENTS = 'GET_UPCOMING_EVENTS';
export const GET_UPCOMING_EVENTS_SUCCESS = 'GET_UPCOMING_EVENTS_SUCCESS';
export const GET_UPCOMING_EVENTS_ERROR = 'GET_UPCOMING_EVENTS_ERROR';

export const GET_FILTERED_EVENTS = 'GET_FILTERED_EVENTS';
export const GET_FILTERED_EVENTS_SUCCESS = 'GET_FILTERED_EVENTS_SUCCESS';
export const GET_FILTERED_EVENTS_ERROR = 'GET_FILTERED_EVENTS_ERROR';

export const GET_FAVOURITE_EVENTS = 'GET_FAVOURITE_EVENTS';
export const GET_FAVOURITE_EVENTS_SUCCESS = 'GET_FAVOURITE_EVENTS_SUCCESS';
export const GET_FAVOURITE_EVENTS_ERROR = 'GET_FAVOURITE_EVENTS_ERROR';

export const GET_TRENDING_EVENTS = 'GET_TRENDING_EVENTS';
export const GET_TRENDING_EVENTS_SUCCESS = 'GET_TRENDING_EVENTS_SUCCESS';
export const GET_TRENDING_EVENTS_ERROR = 'GET_TRENDING_EVENTS_ERROR';

export const GET_EVENT_DETAILS = 'GET_EVENT_DETAILS';
export const GET_EVENT_DETAILS_SUCCESS = 'GET_EVENT_DETAILS_SUCCESS';
export const GET_EVENT_DETAILS_ERROR = 'GET_EVENT_DETAILS_ERROR';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';

export const GET_CITIES = 'GET_CITIES';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_ERROR = 'GET_CITIES_ERROR';

export const ADD_TO_FAVOURITE = 'ADD_TO_FAVOURITE';
export const ADD_TO_FAVOURITE_SUCCESS = 'ADD_TO_FAVOURITE_SUCCESS';
export const ADD_TO_FAVOURITE_ERROR = 'ADD_TO_FAVOURITE_ERROR';

export const REMOVE_FROM_FAVOURITE = 'REMOVE_FROM_FAVOURITE';
export const REMOVE_FROM_FAVOURITE_SUCCESS = 'REMOVE_FROM_FAVOURITE_SUCCESS';
export const REMOVE_FROM_FAVOURITE_ERROR = 'REMOVE_FROM_FAVOURITE_ERROR';

export const GET_EVENT_TICKETS = 'GET_EVENT_TICKETS';
export const GET_EVENT_TICKETS_SUCCESS = 'GET_EVENT_TICKETS_SUCCESS';
export const GET_EVENT_TICKETS_ERROR = 'GET_EVENT_TICKETS_ERROR';

export const ADD_ITEM_TO_BASKET = 'ADD_ITEM_TO_BASKET';
export const ITEM_TO_BASKET = 'ITEM_TO_BASKET';
export const ADD_ITEM_TO_BASKET_SUCCESS = 'ADD_ITEM_TO_BASKET_SUCCESS';
export const ADD_ITEM_TO_BASKET_ERROR = 'ADD_ITEM_TO_BASKET_ERROR';

export const GET_BASKET = 'GET_BASKET';
export const GET_BASKET_SUCCESS = 'GET_BASKET_SUCCESS';
export const GET_BASKET_ERROR = 'GET_BASKET_ERROR';

export const CLEAR_BASKET = 'CLEAR_BASKET';
export const CLEAR_BASKET_SUCCESS = 'CLEAR_BASKET_SUCCESS';
export const CLEAR_BASKET_ERROR = 'CLEAR_BASKET_ERROR';

export const DO_PAYMENT = 'DO_PAYMENT';
export const DO_PAYMENT_SUCCESS = 'DO_PAYMENT_SUCCESS';
export const DO_PAYMENT_ERROR = 'DO_PAYMENT_ERROR';

export const GET_SITE_CONTENT = 'GET_SITE_CONTENT';
export const GET_SITE_CONTENT_SUCCESS = 'GET_SITE_CONTENT_SUCCESS';
export const GET_SITE_CONTENT_ERROR = 'GET_SITE_CONTENT_ERROR';

export const GET_PRESS_FILE = 'GET_PRESS_FILE';
export const GET_PRESS_FILE_SUCCESS = 'GET_PRESS_FILE_SUCCESS';
export const GET_PRESS_FILE_ERROR = 'GET_PRESS_FILE_ERROR';

export const GET_HOME_PAGE_IMG = 'GET_HOME_PAGE_IMG';
export const GET_HOME_PAGE_IMG_SUCCESS = 'GET_HOME_PAGE_IMG_SUCCESS';
export const GET_HOME_PAGE_IMG_ERROR = 'GET_HOME_PAGE_IMG_ERROR';

export const GOOGLE_LOGIN_REQUEST = "GOOGLE_LOGIN_REQUEST";
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS";
export const GOOGLE_LOGIN_ERROR = "GOOGLE_LOGIN_ERROR";

export const FACEBOOK_LOGIN_REQUEST = "FACEBOOK_LOGIN_REQUEST";
export const FACEBOOK_LOGIN_SUCCESS = "FACEBOOK_LOGIN_SUCCESS";
export const FACEBOOK_LOGIN_ERROR = "FACEBOOK_LOGIN_ERROR";

export const SEND_SMS = "SEND_SMS";
export const SEND_SMS_SUCCESS = "SEND_SMS_SUCCESS";
export const SEND_SMS_ERROR = "SEND_SMS_ERROR";

export const RESEND_VERIFICATION_REQUEST = 'RESEND_VERIFICATION_REQUEST';
export const RESEND_VERIFICATION_SUCCESS = 'RESEND_VERIFICATION_SUCCESS';
export const RESEND_VERIFICATION_ERROR = 'RESEND_VERIFICATION_ERROR';

export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_ERROR = 'DELETE_ACCOUNT_ERROR';

export const GET_EVENT_DETAILS_BY_URL = 'GET_EVENT_DETAILS_BY_URL';
export const GET_EVENT_DETAILS_BY_URL_SUCCESS = 'GET_EVENT_DETAILS_BY_URL_SUCCESS';
export const GET_EVENT_DETAILS_BY_URL_ERROR = 'GET_EVENT_DETAILS_BY_URL_ERROR';


export const GET_IS_MODAL_OPEN = 'GET_IS_MODAL_OPEN';