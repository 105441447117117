import { USER_PROFILE_REQUEST, USER_PROFILE_SUCCESS, USER_PROFILE_ERROR } from '../actions/actionTypes';
import { USER_TICKET_REQUEST, USER_TICKET_SUCCESS, USER_TICKET_ERROR } from '../actions/actionTypes';
import { DELETE_ACCOUNT_REQUEST, DELETE_ACCOUNT_SUCCESS, DELETE_ACCOUNT_ERROR } from '../actions/actionTypes';

const initialState = {
    showProgress: false,
    isGetProfileSuccess: false,
    isGetProfileError: false,
    userProfile: {},
    isGetTicketsSuccess: false,
    isGetTicketsError: false,
    userTickets: {},
    isDeleteAccountSuccess: false,
    isDeleteAccountError: false,
    accountDeleted: false
};

export function userReducer(state = initialState, { type, response }) {
    switch (type) {
        case USER_PROFILE_REQUEST:
            return {
                ...state,
                showProgress: true,
                isGetProfileSuccess: false,
                isGetProfileError: false,
                userProfile: {}
            };
        case USER_PROFILE_SUCCESS:
            return {
                ...state,
                showProgress: false,
                isGetProfileSuccess: true,
                isGetProfileError: false,
                userProfile: response
            };
        case USER_PROFILE_ERROR:
            return {
                ...state,
                showProgress: false,
                isGetProfileSuccess: false,
                isGetProfileError: true,
                userProfile: response
            };
        case USER_TICKET_REQUEST:
            return {
                ...state,
                showProgress: true,
                isGetTicketsSuccess: false,
                isGetTicketsError: false,
                userTickets: {}
            };
        case USER_TICKET_SUCCESS:
            return {
                ...state,
                showProgress: false,
                isGetTicketsSuccess: true,
                isGetTicketsError: false,
                userTickets: response
            };
        case USER_TICKET_ERROR:
            return {
                ...state,
                showProgress: false,
                isGetTicketsSuccess: false,
                isGetTicketsError: true,
                userTickets: response.Message[0]
            };
        case DELETE_ACCOUNT_REQUEST:
            return {
                ...state,
                showProgress: true,
                isDeleteAccountSuccess: false,
                isDeleteAccountError: false,
                accountDeleted: false
            };
        case DELETE_ACCOUNT_SUCCESS:
            return {
                ...state,
                showProgress: false,
                isDeleteAccountSuccess: true,
                isDeleteAccountError: false,
                accountDeleted: false
            };
        case DELETE_ACCOUNT_ERROR:
            return {
                ...state,
                showProgress: false,
                isDeleteAccountSuccess: false,
                isDeleteAccountError: true,
                accountDeleted: response.State
            };

        default:
            return state;
    }
}